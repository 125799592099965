/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, Injector, Input } from '@angular/core';
import { rendersSingleStatletForType } from '../single-statlet.decorator';
import { StatletGraphTypes } from '../statlet-graph-types.model';
import { DynamicSingleStatletComponent } from '../dynamic-single-statlet.component';
import { AtmireCuaColorService } from '../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../../app/shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../app/core/shared/generic-constructor';
import {
  AtmireIndividualHrefListableObjectSourceModel,
} from '../../../../../../atmire-object-collection/listable-object-sources';
import { PaginationComponentOptions } from '../../../../../../../app/shared/pagination/pagination-component-options.model';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/core/shared/item.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { StatletDsoDataTypeService } from '../../data-type-services/statlet-dso-data-type.service';
import { followLink } from 'src/app/shared/utils/follow-link-config.model';

@Component({
  selector: 'ds-atmire-cua-single-statlet-search-results',
  templateUrl: './single-statlet-search-results.component.html',
  styleUrls: ['./single-statlet-search-results.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.SEARCH_RESULTS)
/**
 * Component rendering a single statlet of type "searchResults"
 * Transforms the statlet's headers into a list of Hrefs for the related objects
 * The data-service used for each header object is dependent on the header's type and their matching {@link StatletDsoDataTypeService}
 *
 * This component can be used as a standalone component as it's exported in the shared-statlets module
 */
export class SingleStatletSearchResultsComponent extends DynamicSingleStatletComponent {
  searchResultsSourceModel$: Observable<AtmireIndividualHrefListableObjectSourceModel<Item>>;
  pagination = Object.assign(new PaginationComponentOptions(), {
    pageSize: 5,
  });
  public ViewMode = ViewMode;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  /**
   * Read the Statlet's headers and transform them into object Hrefs
   */
  loadData() {
    this.data$ = combineLatest([...this.statlet.rowHeaders.map((header) => {
      const statletDataTypeService = this.getStatletDataTypeServiceForHeader(header);
      if (statletDataTypeService && statletDataTypeService instanceof StatletDsoDataTypeService) {
        return (statletDataTypeService as StatletDsoDataTypeService).dataService.getIDHrefObs(header.headerId);
      } else {
        return of(null);
      }
    })]);
  }

  /**
   * Initialises the {@link AtmireIndividualHrefListableObjectSourceModel} with the hrefs resolved in loadData()
   * This model is passed on to the show-more component
   */
  ngOnInit() {
    super.ngOnInit();

    this.searchResultsSourceModel$ = this.data$.pipe(
      map((data) => new AtmireIndividualHrefListableObjectSourceModel(this.statlet.id, data, {
        linksToFollow: [followLink('thumbnail')],
      })),
    );
  }
}
