<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ngx-charts-chart [view]="[width, height]" [showLegend]="false" [animations]="animations">
  <svg>
    <g [attr.transform]="transform" class="number-card chart" [class.clickable]="clickable">
      <g
        ds-ngx-custom-charts-card-series #number
        [class.invisible]="number.scaling"
        [colors]="colors"
        [cardColor]="cardColor"
        [bandColor]="bandColor"
        [textColor]="textColor"
        [emptyColor]="emptyColor"
        [data]="data"
        [dims]="dims"
        [innerPadding]="innerPadding"
        [valueFormatting]="valueFormatting"
        [labelFormatting]="labelFormatting"
        [animations]="animations"
        [alignment]="alignment"
        (select)="onClick($event)"
      />
    </g>
  </svg>
</ngx-charts-chart>
