<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->


<ng-container *ngIf="buttons.length > 0; then (fieldWrapper && wrapped) || inner">
</ng-container>

<ng-template #wrapped>
  <ds-metadata-field-wrapper [hideIfNoTextContent]="false" [label]="fieldLabel | translate">
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </ds-metadata-field-wrapper>
</ng-template>

<ng-template #inner>
  <div class="sharing-buttons d-flex flex-row flex-wrap" [ngClass]="style">
    <button *ngFor="let button of buttons"
            class="sharing-button button btn"
            [ngStyle]="{ backgroundColor: button.color }"
            [attr.aria-label]="'atmire.social-sharing.buttons.full-label.' + button.platform | translate"
            [attr.data-sharer]="button.platform"
            [attr.data-width]="button.data.width"
            [attr.data-height]="button.data.height"
            [attr.data-link]="button.data.link"
            [attr.data-blank]="button.data.blank"
            [attr.data-title]="button.data.title"
            [attr.data-url]="button.data.url"

            [attr.data-via]="button.data.via"
            [attr.data-hashtags]="button.data.hashtags"
            [attr.data-hashtag]="button.data.hashtag"
            [attr.data-to]="button.data.to"
            [attr.data-subject]="button.data.subject"
            [attr.data-web]="button.data.web"
            [attr.data-description]="button.data.description"
            [attr.data-image]="button.data.image"
            [attr.data-picture]="button.data.picture"
            [attr.data-caption]="button.data.caption"
            [attr.data-tags]="button.data.tags"
            [attr.data-appkey]="button.data.appkey"
    >
      <i [ngClass]="button.icon"></i>
      <span class="sharing-button-label" *ngIf="showLabel">
    {{ 'atmire.social-sharing.buttons.label.' + button.platform | translate }}
  </span>
    </button>
  </div>
</ng-template>
