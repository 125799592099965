/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StaticPageWrapperComponent } from '../static-page-wrapper.component';
import { DSpaceObjectDataService } from '../../../../../../app/core/data/dspace-object-data.service';
import { DSpaceObject } from '../../../../../../app/core/shared/dspace-object.model';
import { hasValue } from '../../../../../../app/shared/empty.util';
import { Community } from '../../../../../../app/core/shared/community.model';
import { Collection } from '../../../../../../app/core/shared/collection.model';
import { Item } from '../../../../../../app/core/shared/item.model';
import { getCommunityModuleRoute } from '../../../../../../app/community-page/community-page-routing-paths';
import { getCollectionModuleRoute } from '../../../../../../app/collection-page/collection-page-routing-paths';
import { getItemModuleRoute } from '../../../../../../app/item-page/item-page-routing-paths';
import { getStaticPageModuleRoute } from '../../../../../static-page/static-page-routing-paths';
import {
  SelectorActionType
} from '../../../../../../app/shared/dso-selector/modal-wrappers/dso-selector-modal-wrapper.component';

/**
 * Component to wrap a button - to create a top level static page -
 * and a list of DSO's - to create a sub level static page
 * inside a modal
 */

@Component({
  selector: 'ds-create-static-page-parent-selector',
  styleUrls: ['./create-static-page-parent-selector.component.scss'],
  templateUrl: './create-static-page-parent-selector.component.html',
})
export class CreateStaticPageParentSelectorComponent extends StaticPageWrapperComponent {

  action = SelectorActionType.CREATE;

  constructor(protected activeModal: NgbActiveModal, protected route: ActivatedRoute, private router: Router,
              protected dsoService: DSpaceObjectDataService) {
    super(activeModal, route, dsoService);
  }

  /**
   * Navigate to static page create page based on the selected Scope DSO id
   */
  navigate(dso: DSpaceObject) {
    if (hasValue(dso)) {
      this.router.navigate([this.getPath(dso), dso.uuid, 'pages', 'add']);
    } else {
      this.router.navigate([getStaticPageModuleRoute(), 'add']);
    }
  }

  /**
   * Get the  path of the scope DSO
   * @param dso
   */
  getPath(dso: DSpaceObject) {
    if ((dso as any).type === Community.type.value) {
      return getCommunityModuleRoute();
    } else if ((dso as any).type === Collection.type.value) {
      return getCollectionModuleRoute();
    } else if ((dso as any).type === Item.type.value) {
      return getItemModuleRoute();
    }
    return null;
  }

  /**
   * Method called when an object has been selected
   * @param dso The selected DSpaceObject
   */
  selectObject(dso: DSpaceObject) {
    this.close();
    this.navigate(dso);
  }
}
