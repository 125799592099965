/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderType } from './order-type.enum';

@Component({
  selector: 'ds-order-switch',
  templateUrl: './order-switch.component.html',
  styleUrls: ['./order-switch.component.scss']
})
export class OrderSwitchComponent {
  @Input() orderManually = false;
  @Output() changeType = new EventEmitter<OrderType>();

  changeOrder() {
    const order = this.orderManually ? OrderType.Manual : OrderType.Title;
    this.changeType.emit(order);
  }

}
