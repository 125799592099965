/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SingleFeatureAuthorizationGuard } from '../../../app/core/data/feature-authorization/feature-authorization-guard/single-feature-authorization.guard';
import { AuthorizationDataService } from '../../../app/core/data/feature-authorization/authorization-data.service';
import { AuthService } from '../../../app/core/auth/auth.service';
import { FeatureID } from '../../../app/core/data/feature-authorization/feature-id';

/**
 * Prevent unauthorized activating and loading of routes when property "atmire.savedItemLists.anonymous" is set to false
 * or the user doesn't have authorization for the CanCreateSavedItemList feature
 */
@Injectable()
export class AtmireSavedItemListFeatureAuthorizationGuard extends SingleFeatureAuthorizationGuard {
  constructor(protected authorizationService: AuthorizationDataService,
              protected router: Router,
              protected authService: AuthService) {
    super(authorizationService, router, authService);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!environment.atmire.savedItemLists.anonymous) {
      return super.canActivate(route, state);
    } else {
      return observableOf(true);
    }
  }

  getFeatureID(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FeatureID> {
    return observableOf(FeatureID.CanCreateSavedItemList);
  }
}
