/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { ItemDataService } from '../../app/core/data/item-data.service';
import { SiteDataService } from '../../app/core/data/site-data.service';
import { Item } from '../../app/core/shared/item.model';
import { map, tap } from 'rxjs/operators';
import { Site } from '../../app/core/shared/site.model';
import { Observable } from 'rxjs/internal/Observable';
import { followLink } from '../../app/shared/utils/follow-link-config.model';
import { RemoteData } from '../../app/core/data/remote-data';
import { isNotEmpty } from '../../app/shared/empty.util';

export const FEATURED_ITEMS_METADATA_FIELD = 'atmire.home.publications';

@Injectable({
  providedIn: 'root'
})
export class FeaturedItemsService {
  constructor(
    private siteService: SiteDataService,
    private itemService: ItemDataService
  ) {
  }

  /**
   * Returns a nested observable instead of a flattened one to ensure they load one at a time and
   * not all appear at once at the end
   */
  getFeaturedItems(): Observable<Observable<RemoteData<Item>>[]> {
    return this.siteService.find()
      .pipe(
        map((site: Site) => {
          const itemIds = site.allMetadataValues(FEATURED_ITEMS_METADATA_FIELD);
          return itemIds.map((id: string) => {
            return this.itemService.findById(id, true, true,
              followLink('thumbnail'),
              followLink('bundles', {},
                followLink('bitstreams', {},
                  followLink('format'))))
              .pipe(
                tap((itemRD: RemoteData<Item>) => {
                  if (itemRD.hasCompleted && itemRD.hasFailed) {
                    console.warn('Item with ID ' + id + ' does not exist');
                  }
                })
              );
          });
        }));
  }

  /**
   * Returns an observable that emits true if there are featured items, false otherwise
   */
  shouldRenderFeaturedItems(): Observable<boolean> {
    return this.getFeaturedItems().pipe(
      map((obs: Observable<RemoteData<Item>>[]) => isNotEmpty(obs))
    );
  }
}
