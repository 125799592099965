/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { LangSwitchComponent as BaseComponent } from '../../../../../app/shared/lang-switch/lang-switch.component';

@Component({
  selector: 'ds-lang-switch',
  // styleUrls: ['./lang-switch.component.scss'],
  // styleUrls: ['../../../../../app/shared/lang-switch/lang-switch.component.scss'],
  styleUrls: ['../../../../../app/shared/lang-switch/lang-switch.component.scss', './lang-switch.component.scss'],
  templateUrl: './lang-switch.component.html',
  // templateUrl: '../../../../../app/shared/lang-switch/lang-switch.component.html',
})
export class LangSwitchComponent extends BaseComponent {

}
