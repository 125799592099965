import { Component } from '@angular/core';
import { ThemedComponent } from '../../theme-support/themed.component';
import { StartsWithDateComponent } from './starts-with-date.component';
import { renderStartsWithFor, StartsWithType } from '../starts-with-decorator';

@renderStartsWithFor(StartsWithType.date)
@Component({
  selector: 'ds-themed-starts-with-date',
  styleUrls: [],
  templateUrl: '../../theme-support/themed.component.html'
})

@renderStartsWithFor(StartsWithType.date)
export class ThemedStartsWithDateComponent extends ThemedComponent<StartsWithDateComponent> {

  protected getComponentName(): string {
    return 'StartsWithDateComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../themes/${themeName}/app/shared/starts-with/date/starts-with-date.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./starts-with-date.component`);
  }
}
