<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(searchResultsSourceModel$ | async) as searchResultsSourceModel">
  <ds-atmire-show-more *ngIf="searchResultsSourceModel"
                       [sourceModel]="searchResultsSourceModel"
                       [paginationOptions]="pagination"
                       [viewMode]="ViewMode.ListElement">
  </ds-atmire-show-more>
</ng-container>
