/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { APP_CONFIG, AppConfig } from '../../../../config/app-config.interface';

@Component({
  selector: 'ds-featured-item-loading-element',
  templateUrl: './featured-item-loading-element.component.html',
  styleUrls: ['./featured-item-loading-element.component.scss', './../featured-item-detailed-list-element/featured-item-detailed-list-element.component.scss']
})
export class FeaturedItemLoadingElementComponent implements OnInit {
  @Input() index: number;
  largeThumbnail: boolean;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
  ) {
  }

  ngOnInit(): void {
    this.largeThumbnail = this.appConfig.atmire.featuredItems.largeThumbnail;
  }
}
