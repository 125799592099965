/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { ObjectUpdatesEffects } from '../../../../app/core/data/object-updates/object-updates.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ObjectUpdatesAction,
  ObjectUpdatesActionTypes,
  RemoveAllObjectUpdatesAction,
} from '../../../../app/core/data/object-updates/object-updates.actions';
import { delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { hasNoValue, hasValue } from '../../../../app/shared/empty.util';
import { EMPTY, Observable, of as observableOf, race as observableRace, Subject } from 'rxjs';
import {
  AtmireDiscardObjectAllUpdatesAction,
  AtmireMultiObjectUpdatesAction,
  AtmireMultiObjectUpdatesActionTypes,
  AtmireObjectUpdatesAction,
  AtmireObjectUpdatesActionTypes,
  AtmireSingleObjectUpdatesAction,
  AtmireSingleObjectUpdatesActionTypes
} from './atmire-object-updates.actions';
import { INotification } from '../../../../app/shared/notifications/models/notification.model';
import { NoOpAction } from '../../../../app/shared/ngrx/no-op.action';
import {
  NotificationsActions,
  NotificationsActionTypes
} from '../../../../app/shared/notifications/notifications.actions';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { Action } from '@ngrx/store';

/**
 * NGRX effects for AtmireObjectUpdatesActions
 */
@Injectable()
export class AtmireObjectUpdatesEffects extends ObjectUpdatesEffects {

  /**
   * Map that keeps track of the latest ObjectUpdatesAction for each page's url
   */
  protected actionMap$: {
    /* Use Subject instead of BehaviorSubject:
      we only want Actions that are fired while we're listening
      actions that were previously fired do not matter anymore
    */
    [url: string]: Subject<any>
  } = {};

  /**
   * Effect that makes sure all last fired AtmireMultiObjectUpdatesAction are stored in the map of this service, with the url as their key
   */
  mapLastMultiActions$ = createEffect(() => this.actions$
    .pipe(
      ofType(...Object.values(AtmireMultiObjectUpdatesActionTypes)),
      map((action: AtmireMultiObjectUpdatesAction) => {
          return action.payload.urls.forEach((url) => this.mapSingleUrl(url, action));
        }
      )
    ), {dispatch: false});

  /**
   * Effect that makes sure all last fired AtmireSingleObjectUpdatesAction are stored in the map of this service, with the url as their key
   */
  mapLastActions$ = createEffect(() => this.actions$
    .pipe(
      ofType(...Object.values(AtmireSingleObjectUpdatesActionTypes)),
      map((action: AtmireSingleObjectUpdatesAction) => {
          if (hasValue((action as any).payload)) {
            return this.mapSingleUrl((action as any).payload.url, action);
          }
        }
      )
    ), {dispatch: false});


  /**
   * Effect that checks whether the removeAction's notification timeout ends before a user triggers another ObjectUpdatesAction
   * When no ObjectUpdatesAction is fired during the timeout, a RemoteObjectUpdatesAction will be returned
   * When a REINSTATE action is fired during the timeout, a NO_ACTION action will be returned
   * When any other ObjectUpdatesAction is fired during the timeout, a RemoteObjectUpdatesAction will be returned
   */
  removeAfterAtmireDiscardAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(AtmireObjectUpdatesActionTypes.ATMIRE_DISCARD_ALL),
      switchMap((action: AtmireDiscardObjectAllUpdatesAction) => {
          const url: string = action.payload.url;
          const notification: INotification = action.payload.notification;
          if (hasValue(notification)) {
          let timeOut: number;
          let notificationFromMap$: Observable<Action>;
          if (hasValue(notification)) {
            timeOut = notification.options.timeOut;
            notificationFromMap$ = this.notificationActionMap$[notification.id].pipe(
              filter((notificationsAction: NotificationsActions) => notificationsAction.type === NotificationsActionTypes.REMOVE_NOTIFICATION),
              map(() => {
                return removeAction;
              })
            );
          } else {
            timeOut = 0;
            notificationFromMap$ = EMPTY;
          }

          const removeAction: Action = new RemoveAllObjectUpdatesAction();

            return observableRace(
              // Either wait for the delay and perform a remove action
              observableOf(removeAction).pipe(delay(timeOut)),
              // Or wait for a a user action
              this.actionMap$[url].pipe(
                take(1),
                tap(() => {
                if (hasValue(notification)) {
                  this.notificationsService.remove(notification);
                }
                }),
                map((updateAction: ObjectUpdatesAction) => {
                  if (updateAction.type === ObjectUpdatesActionTypes.REINSTATE) {
                    // If someone reinstated, do nothing, just let the reinstating happen
                    return new NoOpAction();
                  }
                  // If someone performed another action, assume the user does not want to reinstate and remove all changes
                  return removeAction;
                })
              ),
            notificationFromMap$,
              this.notificationActionMap$[this.allIdentifier].pipe(
                filter((notificationsAction: NotificationsActions) => notificationsAction.type === NotificationsActionTypes.REMOVE_ALL_NOTIFICATIONS),
                map(() => {
                  return removeAction;
                })
              )
            );
          } else {
            return [];
          }
        }
      )
    ));


  /**
   * Add a single action to the map
   */
  private mapSingleUrl(url: string, action: AtmireObjectUpdatesAction) {
    if (hasNoValue(this.actionMap$[url])) {
      this.actionMap$[url] = new Subject<AtmireObjectUpdatesAction>();
    }
    this.actionMap$[url].next(action);
  }

  constructor(
    protected actions$: Actions,
    protected notificationsService: NotificationsService
  ) {
    super(actions$, notificationsService);
  }

}
