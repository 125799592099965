<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div ngbDropdown class="navbar-nav" *ngIf="moreThanOneLanguage" display="dynamic" placement="bottom-left">
  <a href="javascript:void(0);" role="button"
     [attr.aria-label]="'nav.language' |translate"
     [title]="'nav.language' | translate" class="px-1 dropdown-toggle"
     (click)="$event.preventDefault()" data-toggle="dropdown" ngbDropdownToggle
     tabindex="0">
    {{ langLabel(translate.currentLang) }}
  </a>
  <ul ngbDropdownMenu class="dropdown-menu" [attr.aria-label]="'nav.language' |translate">
    <li class="dropdown-item" tabindex="0" #langSelect *ngFor="let lang of translate.getLangs()"
        (keyup.enter)="useLang(lang)"
        (click)="useLang(lang)"
        [class.active]="lang === translate.currentLang">
      {{ langLabel(lang) }}
    </li>
  </ul>
</div>
