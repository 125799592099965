/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DSpaceObjectDataService } from '../../../app/core/data/dspace-object-data.service';
import { MenuService } from '../../../app/shared/menu/menu.service';
import { AuthorizationDataService } from '../../../app/core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DsoVersioningModalService
} from '../../../app/shared/dso-page/dso-versioning-modal-service/dso-versioning-modal.service';
import { AtmireDsoEditMenuResolver } from '../../../app-atmire/shared/dso-page/atmire-dso-edit-menu.resolver';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientDsoEditMenuResolver extends AtmireDsoEditMenuResolver {

  constructor(
    protected dSpaceObjectDataService: DSpaceObjectDataService,
    protected menuService: MenuService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected dsoVersioningModalService: DsoVersioningModalService,
  ) {
    super(dSpaceObjectDataService, menuService, authorizationService, modalService, dsoVersioningModalService);
  }

}
