<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="dso" class="item-preview">
    <div class="row border-bottom text-center">
        <div class="col-12 mb-2">
            <ds-themed-thumbnail [thumbnail]="dso?.thumbnail | async"></ds-themed-thumbnail>
        </div>
        <h5 class="col-12" *ngIf="dso.firstMetadataValue('dc.title')">
            {{dso.firstMetadataValue('dc.title')}}</h5>
    </div>

    <table class="mt-2">
        <tbody>
        <tr *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0">
            <td class="heading">{{'search.suggestions.preview.object.item.authors' | translate}}</td>
            <td>
                <span *ngFor="let author of dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                    <span [innerHTML]="author.value"></span><span *ngIf="!last">; </span>
                </span>
            </td>
        </tr>
        <tr *ngIf="dso.firstMetadataValue('dc.date.issued')">
            <td class="heading">{{'search.suggestions.preview.object.item.date' | translate}}</td>
            <td>{{dso.firstMetadataValue('dc.date.issued')}}</td>
        </tr>
        <tr *ngIf="(getFiles() | async)?.length > 0">
            <td class="heading">{{'search.suggestions.preview.object.item.files' | translate}}</td>
            <td>
                <div class="file-section" *ngFor="let file of (getFiles() | async)">
                  <ds-file-download-link [bitstream]="file" [item]="dso">
                    <span>{{ dsoNameService.getName(file) }}</span>
                  </ds-file-download-link>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>
