/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { ObjectUpdatesService } from '../../../../app/core/data/object-updates/object-updates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { first, switchMap, take, tap, map } from 'rxjs/operators';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteData } from '../../../../app/core/shared/operators';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { RegistryService } from '../../../../app/core/registry/registry.service';
import { MetadataValue, MetadatumViewModel } from '../../../../app/core/shared/metadata.models';
import { AbstractObjectUpdateComponent } from '../abstract-object-update/abstract-object-update.component';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { hasValue, hasNoValue } from '../../../../app/shared/empty.util';
import { MetadataPatchOperationService } from '../../../../app/core/data/object-updates/patch-operation-service/metadata-patch-operation.service';
import { Operation } from 'fast-json-patch';
import { AlertType } from '../../../../app/shared/alert/aletr-type';
import { UpdateDataService } from '../../../../app/core/data/update-data.service';
import { Observable } from 'rxjs';
import { Metadata } from '../../../../app/core/shared/metadata.utils';
import { MetadataField } from '../../../../app/core/metadata/metadata-field.model';
import { DataService } from '../../../../app/core/data/data.service';
import { Identifiable } from '../../../../app/core/data/object-updates/object-updates.reducer';

/**
 * Abstract component for displaying an object's metadata edit page
 */
@Component({
  selector: 'ds-object-metadata',
  template: ''
})
export class ObjectMetadataComponent<T extends DSpaceObject> extends AbstractObjectUpdateComponent<T> {

  /**
   * Observable with a list of strings with all existing metadata field keys
   */
  metadataFields$: Observable<string[]>;

  /**
   * A custom update service to use for adding and committing patches
   * This will default to the components DataService
   */
  @Input() updateService: UpdateDataService<T>;

  constructor(
    public dataService: DataService<T>,
    public objectUpdatesService: ObjectUpdatesService,
    public router: Router,
    public notificationsService: NotificationsService,
    public translateService: TranslateService,
    public route: ActivatedRoute,
    public metadataFieldService: RegistryService,
  ) {
    super(objectUpdatesService, router, notificationsService, translateService, route);
  }

  /**
   * Set up and initialize all fields
   */
  ngOnInit(): void {
    super.ngOnInit();
    if (hasNoValue(this.updateService)) {
      this.updateService = this.dataService;
    }
  }

  /**
   * Initialize the values and updates of the current object's metadata fields
   */
  public initializeUpdates(): void {
    this.updates$ = this.objectUpdatesService.getFieldUpdates(this.url, this.getMetadataAsListExcludingRelationships());
  }

  /**
   * Sends a new add update for a field to the object updates service
   * @param metadata The metadata to add, if no parameter is supplied, create a new Metadatum
   */
  add(metadata: MetadatumViewModel = new MetadatumViewModel()) {
    this.objectUpdatesService.saveAddFieldUpdate(this.url, metadata);
  }

  /**
   * Sends all initial values of this object to the object updates service
   */
  public initializeOriginalFields() {
    this.objectUpdatesService.initialize(this.url, this.object.metadataAsList, (hasValue((this.object as any).lastModified) ? (this.object as any).lastModified : new Date()));
  }

  /**
   * Requests all current metadata for this object and requests the data service to update the object
   * Makes sure the new version of the object is rendered on the page
   */
  public submit() {
    this.isValid().pipe(first()).subscribe((isValid) => {
      if (isValid) {
        const metadata$: Observable<Identifiable[]> = this.objectUpdatesService.getUpdatedFields(this.url, this.getMetadataAsListExcludingRelationships()) as Observable<MetadatumViewModel[]>;
        metadata$.pipe(
          first(),
          switchMap((metadata: MetadatumViewModel[]) => {
            const updatedObject: T = Object.assign(cloneDeep(this.object), { metadata: Metadata.toMetadataMap(metadata) });
            return this.updateService.update(updatedObject);
          }),
          tap(() => this.updateService.commitUpdates()),
          getFirstSucceededRemoteData()
        ).subscribe(
          (rd: RemoteData<T>) => {
            this.object = rd.payload;
            this.checkAndFixMetadataUUIDs();
            this.initializeOriginalFields();
            this.updates$ = this.objectUpdatesService.getFieldUpdates(this.url, this.getMetadataAsListExcludingRelationships());
            this.notificationsService.success(this.getNotificationTitle('saved'), this.getNotificationContent('saved'));
          }
        );
      } else {
        this.notificationsService.error(this.getNotificationTitle('invalid'), this.getNotificationContent('invalid'));
      }
    });
  }

  /**
   * Get the object's metadata as a list and exclude relationship metadata
   */
  getMetadataAsListExcludingRelationships(): MetadatumViewModel[] {
    return this.object.metadataAsList.filter((metadata: MetadatumViewModel) => !metadata.key.startsWith('relation.') && !metadata.key.startsWith('relationship.'));
  }

  /**
   * Check for empty metadata UUIDs and fix them (empty UUIDs would break the object-update service)
   */
  checkAndFixMetadataUUIDs() {
    const metadata = cloneDeep(this.object.metadata);
    Object.keys(this.object.metadata).forEach((key: string) => {
      metadata[key] = this.object.metadata[key].map((value) => hasValue(value.uuid) ? value : Object.assign(new MetadataValue(), value));
    });
    this.object.metadata = metadata;
  }
}
