<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="suggestion" class="item-preview">
    <h4> {{'search.suggestions.preview.search.heading'| translate:{suggestion: suggestion.label} }}</h4>
    <div *ngIf="!(resultsRD$ | async)?.hasSucceeded">
        <ds-loading [showMessage]="false"></ds-loading>
    </div>
    <ng-container *ngIf="(resultsRD$ | async)?.hasSucceeded">
        <ul *ngIf="(resultsRD$ | async)?.payload?.page.length >= 0" class="list-unstyled">
            <li *ngFor="let object of (resultsRD$ | async)?.payload?.page" class="mb-2">
                <ds-listable-object-component-loader [object]="object"></ds-listable-object-component-loader>
            </li>
        </ul>
        <div *ngIf="(resultsRD$ | async)?.payload?.page.length === 0">
            {{'search.suggestions.preview.search.no-results'| translate}}
        </div>
    </ng-container>
</div>
