/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { of as observableOf, race as observableRace, Subject } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { delay, map, switchMap, take } from 'rxjs/operators';
import {
  AddDelayedActionAction,
  DelayedActionAction,
  DelayedActionsActionTypes,
  ExecuteDelayedActionAction,
  RemoveDelayedActionAction
} from './delayed-action.actions';
import { hasNoValue } from '../../../app/shared/empty.util';

@Injectable()
export class DelayedActionEffects {

  private actionMap$: {
    /* Use Subject instead of BehaviorSubject:
      we only want Actions that are fired while we're listening
      actions that were previously fired do not matter anymore
    */
    [actionId: string]: Subject<DelayedActionAction>
  } = {};

  /**
   * Effect that makes sure all last fired ObjectUpdatesActions are stored in the map of this service, with the url as their key
   */
  @Effect({dispatch: false}) mapLastActions$ = this.actions$
    .pipe(
      ofType(...Object.values(DelayedActionsActionTypes)),
      map((action: DelayedActionAction) => {
          const actionId: string = action.payload.actionId;
          if (hasNoValue(this.actionMap$[actionId])) {
            this.actionMap$[actionId] = new Subject<DelayedActionAction>();
          }
          this.actionMap$[actionId].next(action);
        }
      )
    );

  /**
   * Effect that checks whether the removeAction's notification timeout ends before a user triggers another ObjectUpdatesAction
   * When no ObjectUpdatesAction is fired during the timeout, a RemoteObjectUpdatesAction will be returned
   * When a REINSTATE action is fired during the timeout, a NO_ACTION action will be returned
   * When any other ObjectUpdatesAction is fired during the timeout, a RemoteObjectUpdatesAction will be returned
   */
  @Effect() removeAfterExecuteOrCancel = this.actions$
    .pipe(
      ofType(DelayedActionsActionTypes.ADD),
      switchMap((action: AddDelayedActionAction) => {
          const actionId: string = action.payload.actionId;
          const timeOut = action.payload.timeForExecution;
          return observableRace(
            // Either wait for the delay and perform a remove action
            observableOf(new ExecuteDelayedActionAction(action.payload.actionId)).pipe(delay(timeOut)),
            // Or wait for a a user action
            this.actionMap$[actionId].pipe(
              take(1),
              map((updateAction: DelayedActionAction) => {
                if (updateAction.type === DelayedActionsActionTypes.CANCEL) {
                  // If someone reinstated, do nothing, just let the reinstating happen
                  return {type: 'NO_ACTION'};
                }
                if (updateAction.type === DelayedActionsActionTypes.RUSH) {
                  return new ExecuteDelayedActionAction(action.payload.actionId);
                }
              })
            )
          );
        }
      )
    );

  @Effect() removeExecutedAction = this.actions$
    .pipe(
      ofType(DelayedActionsActionTypes.EXECUTE),
      map((action: DelayedActionAction) => new RemoveDelayedActionAction(action.payload.actionId))
    );

  @Effect() removeCancelledAction = this.actions$
    .pipe(
      ofType(DelayedActionsActionTypes.CANCEL),
      map((action: DelayedActionAction) => new RemoveDelayedActionAction(action.payload.actionId))
    );

  constructor(private actions$: Actions) {
  }

}
