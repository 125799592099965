/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DelayedActionService } from '../delayed-action.service';
import { Router } from '@angular/router';
import { DelayedActionEntry } from '../delayed-action.reducer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';

@Component({
  selector: 'ds-delayed-action',
  templateUrl: './delayed-action-button.component.html'
})
export class DelayedActionButtonComponent implements OnInit, OnDestroy {

  @Input() buttonMessage: string;
  @Input() undoMessage: string;

  @Input() timeForExecution = 5000;
  @Input() actionId = '';

  @Input() actionNotificationHead: string;
  @Input() actionNotificationContent: string;

  @Output() onExecute = new EventEmitter();

  public canUndo = false;
  private fullActionId: string;

  constructor(
    private delayedActionService: DelayedActionService,
    private router: Router,
    private notificationService: NotificationsService,
    private translateService: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.fullActionId = this.router.url + this.actionId;
  }

  createDelayedAction() {
    this.canUndo = true;
    const infoMessage = this.notificationService.info(this.translateService.get(this.actionNotificationHead)
      , this.translateService.get(this.actionNotificationContent), {timeOut: this.timeForExecution});
    this.delayedActionService.executeDelayedAction(this.fullActionId, this.timeForExecution).subscribe((delayedAction: DelayedActionEntry) => {
      if (delayedAction.canExecute) {
        this.onExecute.emit();
        this.canUndo = false;
      }
      this.notificationService.remove(infoMessage);
    });
  }

  cancelDelayedAction() {
    this.canUndo = false;
    this.delayedActionService.cancelDelayedAction(this.fullActionId);
  }

  ngOnDestroy(): void {
    this.delayedActionService.rushAction(this.fullActionId);
  }
}
