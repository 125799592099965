/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { StaticPage } from '../../shared/static-page.model';

@Injectable()
export class StaticPageImageFormBuilder {

  buildForm(page: StaticPage, file, dspaceobjectUuid: string): FormData {

    const form: FormData = new FormData();

    const properties = {
      id: page.id,
      name: page.name,
      title: page.title,
      language: page.language,
      type: StaticPage.type.value
    };

    form.set('properties', JSON.stringify(properties));

    form.set('file', file);
    form.set('dspaceobject', dspaceobjectUuid);

    return form;
  }

}
