<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div id="search-navbar-container" [title]="'nav.search' | translate" (dsClickOutside)="collapse()">
  <div class="d-inline-block position-relative">
    <form class="d-none d-sm-block" (ngSubmit)="onSubmit()">
      <ds-search-input-field [showButton]="false" #searchInput [@toggleAnimation]="animationState$ | async" [attr.aria-label]="('nav.search' | translate)"
                             class="d-inline-block bg-white position-absolute dropdown-menu-right"></ds-search-input-field>
      <a (click)="searchExpanded$.value ? onSubmit() : expand();" class="submit-icon">
        <em class="fas fa-search fa-lg fa-fw"></em>
      </a>
    </form>
    <a class="d-sm-none submit-icon" [routerLink]="'/search'">
      <em class="fas fa-search fa-lg fa-fw"></em>
    </a>
  </div>
</div>
