/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ITEM_PAGE_LINKS_TO_FOLLOW } from '../../app/item-page/item.resolver';
import { ItemDataService } from '../../app/core/data/item-data.service';
import { RemoteData } from '../../app/core/data/remote-data';
import { Item } from '../../app/core/shared/item.model';
import { hasValue } from '../../app/shared/empty.util';
import { getItemPageRoute } from '../../app/item-page/item-page-routing-paths';
import { getFirstCompletedRemoteData } from '../../app/core/shared/operators';
import { ResolvedAction } from '../../app/core/resolving/resolver.actions';
import { FollowLinkConfig, followLink } from '../../app/shared/utils/follow-link-config.model';
/**
 * The self links defined in this list are expected to be requested somewhere in the near future
 * Requesting them as embeds will limit the number of requests
 */
export const ATMIRE_ITEM_PAGE_LINKS_TO_FOLLOW: FollowLinkConfig<Item>[] = [
  ...ITEM_PAGE_LINKS_TO_FOLLOW,
  followLink('relateditemlistconfigs')
];
/**
 * This class represents a resolver that requests a specific item before the route is activated and will redirect to the
 * entity page
 */
@Injectable({providedIn: 'root'})
export class AtmireItemPageResolver {
  constructor(
    protected itemService: ItemDataService,
    protected store: Store<any>,
    protected router: Router
  ) {
  }

  /**
   * Method for resolving an item based on the parameters in the current route
   * @param {ActivatedRouteSnapshot} route The current ActivatedRouteSnapshot
   * @param {RouterStateSnapshot} state The current RouterStateSnapshot
   * @returns Observable<<RemoteData<Item>> Emits the found item based on the parameters in the current route,
   * or an error if something went wrong
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RemoteData<Item>> {
    const itemRD$ = this.itemService.findById(route.params.id,
      true,
      false,
      ...ATMIRE_ITEM_PAGE_LINKS_TO_FOLLOW
    ).pipe(
      getFirstCompletedRemoteData(),
    );

    itemRD$.subscribe((itemRD: RemoteData<Item>) => {
      this.store.dispatch(new ResolvedAction(state.url, itemRD.payload));
    });

    return itemRD$.pipe(
      map((rd: RemoteData<Item>) => {
        if (rd.hasSucceeded && hasValue(rd.payload)) {
          const itemRoute = getItemPageRoute(rd.payload);
          const thisRoute = state.url;
          if (!thisRoute.startsWith(itemRoute)) {
            const itemId = rd.payload.uuid;
            const subRoute = thisRoute.substring(thisRoute.indexOf(itemId) + itemId.length, thisRoute.length);
            this.router.navigateByUrl(itemRoute + subRoute);
          }
        }
        return rd;
      })
    );
  }
}
