/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
export const FILE_ICON_MAPPING = {
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/vnd.oasis.opendocument.text': 'word',

  'video/x-msvideo': 'video',
  'video/mp4': 'video',
  'video/mpeg': 'video',
  'video/ogg': 'video',
  'application/ogg': 'video',
  'video/mp2t': 'video',
  'video/webm': 'video',
  'video/3gpp': 'video',
  'video/3gpp2': 'video',

  'application/vnd.oasis.opendocument.presentation': 'powerpoint',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',

  'application/pdf': 'pdf',

  'image/bmp': 'image',
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',
  'image/vnd.microsoft.icon': 'image',
  'image/svg+xml': 'image',
  'image/tiff': 'image',
  'image/webp': 'image',
  'image/avif': 'image',

  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'excel',

  'application/x-csh': 'code',
  'application/xml': 'code',
  'text/xml': 'code',
  'text/html': 'code',
  'application/json': 'code',
  'application/ld+json': 'code',
  'text/javascript': 'code',
  'application/x-httpd-php': 'code',
  'application/x-sh': 'code',
  'application/xhtml+xml': 'code',
  'application/vnd.mozilla.xul+xml': 'code',

  'audio/aac': 'audio',
  'application/x-cdf': 'audio',
  'audio/midi': 'audio',
  'audio/x-midi': 'audio',
  'audio/mpeg': 'audio',
  'audio/ogg': 'audio',
  'audio/opus': 'audio',
  'audio/wav': 'audio',
  'audio/webm': 'audio',
  'audio/3gpp': 'audio',
  'audio/3gpp2': 'audio',

  'application/zip': 'archive',
  'application/x-freearc': 'archive',
  'application/x-bzip': 'archive',
  'application/x-bzip2': 'archive',
  'application/gzip': 'archive',
  'application/java-archive': 'archive',
  'application/vnd.rar': 'archive',
  'application/x-tar': 'archive',
  'application/x-7z-compressed': 'archive',

  'text/plain': 'alt',
  'application/rtf': 'alt',
  'application/octet-stream': 'alt',
  'default': 'alt',

  'text/csv': 'csv',
};
