<ng-container *ngVar="(objects$ | async) as objects">
  <h3 [ngClass]="{'sr-only': parentname }" >{{title | translate}}</h3>
  <ng-container *ngComponentOutlet="getStartsWithComponent(); injector: objectInjector;"></ng-container>
  <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
    <div *ngIf="shouldDisplayResetButton$ |async" class="mb-2 reset">
      <button class="btn btn-secondary" (click)="back()"><i class="fas fa-arrow-left"></i> {{'browse.back.all-results' | translate}}</button>
    </div>
    <ds-viewable-collection
      [config]="paginationConfig"
      [sortConfig]="sortConfig"
      [objects]="objects">
    </ds-viewable-collection>
  </div>
  <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
  <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error>
  <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0">
    <div *ngIf="shouldDisplayResetButton$ |async" class="d-inline-block mb-4 reset">
      <button class="btn btn-secondary" (click)="back()"><i class="fas fa-arrow-left"></i> {{'browse.back.all-results' | translate}}</button>
    </div>
    <div class="alert alert-info w-100" role="alert">
      {{'browse.empty' | translate}}
    </div>
  </div>
</ng-container>
