/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AtmireAbstractListableElementComponent
} from '../../object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { SearchResult } from '../../../../app/shared/search/models/search-result.model';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { TruncatableService } from '../../../../app/shared/truncatable/truncatable.service';
import { hasValue } from '../../../../app/shared/empty.util';
import { MetadataMapInterface } from '../../../../app/core/shared/metadata.models';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';
import { AtmireDSONameService } from '../../../core/breadcrumbs/atmire-dso-name.service';

@Component({
  selector: 'ds-search-result-list-element',
  template: ``,
})
export class AtmireSearchResultListElementComponent<T extends SearchResult<K>, K extends DSpaceObject> extends AtmireAbstractListableElementComponent<T> implements OnInit {
  /**
   * The DSpaceObject of the search result
   */
  dso: K;
  dsoTitle: string;

  public constructor(
    protected truncatableService: TruncatableService,
    protected dsoNameService: AtmireDSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(multilingualMetadataService);
  }

  /**
   * Retrieve the dso from the search result
   */
  ngOnInit(): void {
    if (hasValue(this.object)) {
      this.dso = this.object.indexableObject;
      this.dsoTitle = this.dsoNameService.getName(this.dso);
    }
  }

  /**
   * Emits if the list element is currently collapsed or not
   */
  isCollapsed(): Observable<boolean> {
    return this.truncatableService.isCollapsed(this.dso.id);
  }

  protected get metadata(): MetadataMapInterface[] {
    return [this.object.hitHighlights, this.dso.metadata];
  }
}
