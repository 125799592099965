/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingComponent as BaseComponent} from '../../../../../app/shared/loading/loading.component';

@Component({
  selector: 'ds-loading',
  // styleUrls: ['../../../../../app/shared/loading/loading.component.scss'],
  styleUrls: ['./loading.component.scss'],
  templateUrl: '../../../../../app/shared/loading/loading.component.html'
  // templateUrl: './loading.component.html'
})
export class LoadingComponent extends BaseComponent {


}
