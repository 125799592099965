<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="dso" class="item-preview">
    <h4>{{'search.suggestions.preview.object.collection.heading'| translate:{collection: dso.name} }}</h4>
    <div *ngIf="!(itemsRD$ | async)?.hasSucceeded">
        <ds-loading [showMessage]="false"></ds-loading>
    </div>
    <ng-container *ngIf="(itemsRD$ | async)?.hasSucceeded">
        <ul *ngIf="(itemsRD$ | async)?.payload?.page.length >= 0" class="list-unstyled">
            <li *ngFor="let object of (itemsRD$ | async)?.payload?.page" class="mb-2">
                <ds-listable-object-component-loader [object]="object"></ds-listable-object-component-loader>
            </li>
        </ul>
        <div *ngIf="(itemsRD$ | async)?.payload?.page.length === 0">
            {{'search.suggestions.preview.object.collection.no-results'| translate}}
        </div>
    </ng-container>
</div>
