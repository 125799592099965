<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div>
  <!-- For statlet containing views/downloads info, named PublicationItemSearchCounts, hardcoded order VIEWS-DOWNLOADS -->
  <ng-container *ngIf="statlet.id.includes('PublicationItemSearchCounts')">
    <ng-container *ngIf="!containsPointByLabel('ITEM')">
      <span>{{'statlets-list-view-statistics.multiple.ITEM' | translate:{count: 0 | dsDecimal:'1.0-2'} }}</span>
      <span [innerHTML]="separator"></span>
    </ng-container>
    <ng-container *ngIf="containsPointByLabel('ITEM')">
      <ng-container *ngVar="getPointByLabel('ITEM') as point">
      <span *ngIf="point.values['any'] === 1">
            {{'statlets-list-view-statistics.single.' + point.label | translate:{count: point.values['any'] | dsDecimal:'1.0-2'} }}
          </span>
        <span *ngIf="point.values['any'] > 1">
            {{'statlets-list-view-statistics.multiple.' + point.label | translate:{count: point.values['any'] | dsDecimal:'1.0-2'} }}
          </span>
      </ng-container>
      <span [innerHTML]="separator"></span>
    </ng-container>
    <ng-container *ngIf="containsPointByLabel('BITSTREAM')">
      <ng-container *ngVar="getPointByLabel('BITSTREAM') as point">
      <span *ngIf="point.values['any'] === 1">
            {{'statlets-list-view-statistics.single.' + point.label | translate:{count: point.values['any'] | dsDecimal:'1.0-2'} }}
          </span>
        <span *ngIf="point.values['any'] > 1">
            {{'statlets-list-view-statistics.multiple.' + point.label | translate:{count: point.values['any'] | dsDecimal:'1.0-2'} }}
          </span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!containsPointByLabel('BITSTREAM')">
      <span>{{'statlets-list-view-statistics.multiple.BITSTREAM' | translate:{count: 0 | dsDecimal:'1.0-2'} }}</span>
    </ng-container>
  </ng-container>

  <!-- For other statlets returned in search with position=searchResults -->
  <ng-container *ngIf="!statlet.id.includes('PublicationItemSearchCounts')">
    <ng-container *ngFor="let point of statlet.points; let last=last;">
      <ng-container *ngFor="let value of (point.values | keyvalue)">
        <span *ngIf="value.key !== 'any'">
          {{'statlets-list-view-statistics.' + point.label | translate:{
          count: value.value | dsDecimal:'1.0-2', label: value.key
        } }}
        </span>
        <ng-container *ngIf="value.key === 'any'">
          <ng-container *ngIf="point.label !== 'BITSTREAM' || point.label !== 'ITEM'">
            <span *ngIf="value.value === 1">
              {{'statlets-list-view-statistics.single.' + point.label | translate:{count: value.value | dsDecimal:'1.0-2'} }}
            </span>
            <span *ngIf="value.value > 1">
              {{'statlets-list-view-statistics.multiple.' + point.label | translate:{count: value.value | dsDecimal:'1.0-2'} }}
            </span>
          </ng-container>
        </ng-container>
        <span *ngIf="!last" [innerHTML]="separator"></span>
      </ng-container>
    </ng-container>
  </ng-container>

</div>
