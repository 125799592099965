/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * The metadata registry state.
 * @interface MetadataRegistryState
 */
import {
  AddDelayedActionAction,
  CancelDelayedActionAction,
  DelayedActionAction,
  DelayedActionsActionTypes,
  ExecuteDelayedActionAction,
  RemoveDelayedActionAction
} from './delayed-action.actions';
import { hasValue } from '../../../app/shared/empty.util';

export interface DelayedActionState {
  [actionId: string]: DelayedActionEntry;
}

export interface DelayedActionEntry {
  timeForExecution: number;
  canExecute: boolean;
  cancelled: boolean;
}

/**
 * The initial state.
 */
const initialState: DelayedActionState = {};

/**
 * Reducer that handles MetadataRegistryActions to modify metadata schema and/or field states
 * @param state   The current MetadataRegistryState
 * @param action  The MetadataRegistryAction to perform on the state
 */
export function delayedActionReducer(state = initialState, action: DelayedActionAction): DelayedActionState {

  switch (action.type) {

    case DelayedActionsActionTypes.ADD: {
      if (hasValue(action.payload)) {
        return initializeDelayedAction(state, action as AddDelayedActionAction);
      }
      return initialState;
    }

    case DelayedActionsActionTypes.EXECUTE: {
      if (hasValue(action.payload)) {
        return executeDelayedAction(state, action);
      }
      return initialState;
    }

    case DelayedActionsActionTypes.CANCEL: {
      if (hasValue(action.payload)) {
        return cancelDelayedAction(state, action);

      }
      return initialState;
    }

    case DelayedActionsActionTypes.RUSH: {
      return initialState;
    }

    case DelayedActionsActionTypes.REMOVE: {
      if (hasValue(action.payload)) {
        return removeDelayedAction(state, action);
      }
      return initialState;
    }

    default:
      return state;
  }
}

/**
 * Initialize the state for a specific url and store all its fields in the store
 * @param state The current state
 * @param action The action to perform on the current state
 */
function initializeDelayedAction(state: DelayedActionState, action: AddDelayedActionAction) {
  const actionId: string = action.payload.actionId;
  const timeForExecution: number = action.payload.timeForExecution;
  const newDelayedActionEntry = Object.assign(
    {},
    state[actionId],
    {timeForExecution: timeForExecution},
  );
  return Object.assign({}, state, {[actionId]: newDelayedActionEntry});
}

/**
 * Initialize the state for a specific url and store all its fields in the store
 * @param state The current state
 * @param action The action to perform on the current state
 */
function executeDelayedAction(state: DelayedActionState, action: ExecuteDelayedActionAction) {
  const actionId: string = action.payload.actionId;
  const delayedActionEntry: DelayedActionEntry = state[actionId];
  const newDelayedActionEntry: DelayedActionEntry = Object.assign(
    {}, delayedActionEntry,
    {canExecute: true},
  );
  return Object.assign({}, state, {[actionId]: newDelayedActionEntry});
}

function cancelDelayedAction(state: DelayedActionState, action: CancelDelayedActionAction) {
  const actionId: string = action.payload.actionId;
  const delayedActionEntry: DelayedActionEntry = state[actionId];
  const newDelayedActionEntry: DelayedActionEntry = Object.assign(
    {}, delayedActionEntry,
    {cancelled: true},
  );
  return Object.assign({}, state, {[actionId]: newDelayedActionEntry});
}

/**
 * Initialize the state for a specific url and store all its fields in the store
 * @param state The current state
 * @param action The action to perform on the current state
 */
function removeDelayedAction(state: DelayedActionState, action: RemoveDelayedActionAction) {
  const actionId: string = action.payload.actionId;
  const newState = Object.assign({}, state);
  delete newState[actionId];
  return newState;
}
