/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import localeCode from 'iso-639-1';
import { of as observableOf, timer as observableTimer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

/**
 * Returns a validator function to check if the control's value is conform to the iso-639-1 standard
 */
export function languageValidator(): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return observableTimer(500).pipe(
      switchMap(() => {
        if (!control.value) {
          return observableOf(null);
        }
        const valid = localeCode.validate(control.value);

        return valid ? observableOf(null) : observableOf({invalidLanguage: {value: control.value}});
      }),
      take(1));
  };
}
