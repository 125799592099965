/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ViewEncapsulation } from '@angular/core';
import { HomeNewsComponent as BaseComponent } from '../../../../../app/home-page/home-news/home-news.component';
import { Observable } from 'rxjs';
import { Site } from '../../../../../app/core/shared/site.model';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { StaticPageContentObject } from '../../../../../app-atmire/static-page/static-page-content-object.model';
import { ActivatedRoute } from '@angular/router';
import { StaticPageDataService } from '../../../../../app-atmire/core/data/static-page-data.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { hasValue } from '../../../../../app/shared/empty.util';
import { StaticPageSearchOptions } from '../../../../../app-atmire/static-page/static-page-search-options.model';

@Component({
  selector: 'ds-home-news',
  styleUrls: ['./home-news.component.scss'],
  // styleUrls: ['../../../../../app/home-page/home-news/home-news.component.scss'],
  templateUrl: './home-news.component.html',
  // templateUrl: '../../../../../app/home-page/home-news/home-news.component.html'
  encapsulation: ViewEncapsulation.None, // In order to style innerHTML
})

/**
 * Component to render the news section on the home page
 */
export class HomeNewsComponent extends BaseComponent {
  site$: Observable<Site>;
  homepageNewsRD$: Observable<RemoteData<StaticPageContentObject>>;
  homePageBgImage$: Observable<string>;
  constructor(
    private route: ActivatedRoute,
    private staticPageDataService: StaticPageDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.site$ = this.route.data.pipe(
      map((data) => data.site as Site),
    );
    this.initNews();
  }

  initNews(): void {

    const staticPageObs = this.site$.pipe(
      filter((site) => hasValue(site)),
      map((site) => new StaticPageSearchOptions({
        uuid: site.uuid,
        name: 'news'
      })),
      mergeMap((staticPageSearchOptions: StaticPageSearchOptions) => this.staticPageDataService.searchStaticPages(staticPageSearchOptions)),
    );
    this.homePageBgImage$ = this.site$.pipe(map((site: Site) => site.firstMetadataValue('atmire.home.newsimage')));
    this.homepageNewsRD$ = this.staticPageDataService.retrieveContentFromPageListObs(staticPageObs);
  }
}
