/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { createSelector, MemoizedSelector, select, Store } from '@ngrx/store';
import { AddDelayedActionAction, CancelDelayedActionAction, RushDelayedActionAction } from './delayed-action.actions';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DelayedActionEntry, DelayedActionState } from './delayed-action.reducer';
import { take, filter } from 'rxjs/operators';
import { hasValue } from '../../../app/shared/empty.util';
import { coreSelector } from '../../../app/core/core.selectors';
import { CoreState } from '../../../app/core/core.reducers';

function actionStateSelector(): MemoizedSelector<CoreState, DelayedActionState> {
  return createSelector(coreSelector, (state: CoreState) => state['cache/delayed-actions']);
}

function filterByActionIdStateSelector(actionId: string): MemoizedSelector<CoreState, DelayedActionEntry> {
  return createSelector(actionStateSelector(), (state: DelayedActionState) => state[actionId]);
}

@Injectable()
export class DelayedActionService {

  constructor(private store: Store<CoreState>) {

  }

  addDelayedAction(actionId: string, timeForExecution: number) {
    this.store.dispatch(new AddDelayedActionAction(actionId, timeForExecution));
  }

  cancelDelayedAction(actionId: string) {
    this.store.dispatch(new CancelDelayedActionAction(actionId));
  }

  rushAction(actionId: string) {
    this.store.dispatch(new RushDelayedActionAction(actionId));
  }

  executeDelayedAction(actionId: string, timeForExecution: number): Observable<DelayedActionEntry> {
    this.addDelayedAction(actionId, timeForExecution);

    return this.store.pipe(
      select(filterByActionIdStateSelector(actionId)),
      filter((delayedActionEntry: DelayedActionEntry) => {
        if (hasValue(delayedActionEntry)) {
          return delayedActionEntry.canExecute || delayedActionEntry.cancelled;
        }
        return false;
      }),
      take(1)
    );
  }

}
