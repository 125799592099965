<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="d-flex pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-add-uuid-button class="mr-1" [uuid]="object.uuid" [msgPrefix]="'item.page.'">
    </ds-dso-add-uuid-button>
    <ds-dso-edit-menu></ds-dso-edit-menu>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"
                           [defaultImage]="'assets/images/orgunit-placeholder.svg'"
                           [alt]="'thumbnail.orgunit.alt'"
                           [placeholder]="'thumbnail.orgunit.placeholder'"
      >
      </ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['organization.foundingDate']"
                                       [label]="'orgunit.page.dateestablished'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['organization.address.addressLocality']"
                                       [label]="'orgunit.page.city'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['organization.adress.addressCountry']"
                                       [label]="'orgunit.page.country'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.identifier']"
                                       [label]="'orgunit.page.id'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPublicationOfOrgUnit'"
      [label]="'relationships.isPublicationOf' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.description']"
                                       [label]="'orgunit.page.description'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isOrgUnitOfPerson',
                                          filter: 'isOrgUnitOfPerson',
                                          configuration: 'person'
                                        },
                                        {
                                          label: 'isOrgUnitOfProject',
                                          filter: 'isOrgUnitOfProject',
                                          configuration: 'project'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
