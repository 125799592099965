/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { SearchModule } from '../../app/shared/search/search.module';
import { FormsModule } from '@angular/forms';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { FooterComponent } from './app/footer/footer.component';
import { HeaderComponent } from './app/header/header.component';
import { HomeNewsComponent } from './app/home-page/home-news/home-news.component';
import { HomePageComponent } from './app/home-page/home-page.component';
import { NavbarComponent } from './app/navbar/navbar.component';
import { ExpandableNavbarSectionComponent } from './app/navbar/expandable-navbar-section/expandable-navbar-section.component';
import { ItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { HomePageModule } from '../../app/home-page/home-page.module';
import { StatisticsModule } from '../../app/statistics/statistics.module';
import { ClientSharedModule } from 'src/app-client/shared/client-shared.module';
import { AtmireObjectCollectionModule } from '../../app-atmire/atmire-object-collection/atmire-object-collection.module';
import { AtmireSelectiveExportSharedModule } from 'src/app-atmire/atmire-selective-export/atmire-selective-export-shared/atmire-selective-export-shared.module';
import { AtmireAppModule } from '../../app-atmire/atmire-app.module';
import { ItemSharedModule } from '../../app/item-page/item-shared.module';
import { SocialSharingModule } from '../../app-atmire/social-sharing/social-sharing.module';
import {
  AtmireRelatedItemsModule
} from '../../app-atmire/atmire-related-items/atmire-related-items.module';
import { SharedStatletsModule } from '../../app-atmire/atmire-cua/statlets/shared/shared-statlets.module';
import { AtmireSharedModule } from '../../app-atmire/shared/atmire-shared.module';
import { BrowseByDatePageComponent } from './app/browse-by/browse-by-date-page/browse-by-date-page.component';
import { BrowseByMetadataPageComponent } from './app/browse-by/browse-by-metadata-page/browse-by-metadata-page.component';
import { BrowseByTitlePageComponent } from './app/browse-by/browse-by-title-page/browse-by-title-page.component';
import { ClientAppModule } from '../../app-client/client-app.module';
import { ComcolModule } from '../../app/shared/comcol/comcol.module';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { AtmireFeaturedItemsSharedModule } from '../../app-atmire/atmire-featured-items/atmire-featured-items-shared.module';
import { LangSwitchComponent } from './app/shared/lang-switch/lang-switch.component';
import {AuthNavMenuComponent} from './app/shared/auth-nav-menu/auth-nav-menu.component';
import {ThumbnailComponent} from './app/thumbnail/thumbnail.component';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  ExpandableNavbarSectionComponent,
  ItemSearchResultListElementComponent,
  PublicationComponent,
  UntypedItemComponent,
  BrowseByDatePageComponent,
  BrowseByMetadataPageComponent,
  BrowseByTitlePageComponent,
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  FooterComponent,
  HeaderComponent,
  HomeNewsComponent,
  HomePageComponent,
  NavbarComponent,
  LangSwitchComponent,
  AuthNavMenuComponent,
  ThumbnailComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SearchModule,
    FormsModule,
    RootModule,
    NavbarModule,
    ItemPageModule,
    AtmireFeaturedItemsSharedModule,
    HomePageModule,
    StatisticsModule,
    ClientSharedModule,
    AtmireObjectCollectionModule,
    AtmireSelectiveExportSharedModule,
    AtmireAppModule,
    ItemSharedModule,
    SocialSharingModule,
    AtmireRelatedItemsModule,
    SharedStatletsModule,
    AtmireSharedModule,
    ClientAppModule,
    ComcolModule,
    ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})
/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}
