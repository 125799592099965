/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { AtmireMenuResolver } from '../app-atmire/atmire-menu.resolver';
import { MenuService } from '../app/shared/menu/menu.service';
import { BrowseService } from '../app/core/browse/browse.service';
import { AuthorizationDataService } from '../app/core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ScriptDataService } from '../app/core/data/processes/script-data.service';
import { CmsMenuDataService } from '../app-atmire/core/data/cms-menu-data.service';
import { AuthService } from '../app/core/auth/auth.service';
import { StatisticsMenuService } from '../app-atmire/shared/menu/statistics-menu/statistics-menu.service';

/**
 * Menu entries specific to app-client should be defined here.
 * For this resolver to be used, it must be provided as {@link MenuResolver} in {@link AppModule}.
 */
@Injectable()
export class ClientMenuResolver extends AtmireMenuResolver {
  constructor(
    protected authService: AuthService,
    protected menuService: MenuService,
    protected browseService: BrowseService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected scriptDataService: ScriptDataService,
    protected cmsMenuService: CmsMenuDataService,
    protected statisticsMenuService: StatisticsMenuService,
  ) {
    super(
      authService,
      menuService,
      browseService,
      authorizationService,
      modalService,
      scriptDataService,
      cmsMenuService,
      statisticsMenuService,
    );
  }

  createPublicMenu$(): Observable<boolean> {
    return super.createPublicMenu$();
  }

  createMainMenuSections(): void {
    super.createMainMenuSections();
  }

  createExportMenuSections(): void {
    super.createExportMenuSections();
  }

  createImportMenuSections(): void {
    super.createImportMenuSections();
  }

  createSiteAdministratorMenuSections(): void {
    super.createSiteAdministratorMenuSections();
  }

  createAccessControlMenuSections(): void {
    super.createAccessControlMenuSections();
  }
}
