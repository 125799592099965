/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PaginationComponentOptions } from '../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../../app/core/cache/models/sort-options.model';
import { combineLatest, Observable, of } from 'rxjs';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { RESTURLCombiner } from '../../../app/core/url-combiner/rest-url-combiner';
import { HrefOnlyDataService } from '../../../app/core/data/href-only-data.service';
import { RemoteData } from '../../../app/core/data/remote-data';
import { AtmireIndividualHrefListableObjectSourceModel } from '../listable-object-sources';
import { AbstractListableObjectSource } from './abstract-listable-object-source';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ListableNotificationObject } from '../../../app/shared/object-list/listable-notification-object/listable-notification-object.model';
import { getAllCompletedRemoteData } from '../../../app/core/shared/operators';
import { RequestEntryState } from '../../../app/core/data/request.reducer';
import { PageInfo } from '../../../app/core/shared/page-info.model';

/**
 * Retrieves individual objects from a list of Hrefs via {@link HrefOnlyDataService} and simulates pagination between them
 */
export class AtmireIndividualHrefListableObjectSource<T extends DSpaceObject> extends AbstractListableObjectSource<T> {
  /**
   * The source where the hrefs were retrieved from
   * Used for error messages to give feedback to the user where the error originated from
   */
  private readonly sourceHref: string;

  /**
   * List of Hrefs to retrieve objects for
   * @private
   */
  private readonly hrefs: string[];

  constructor(
    model: AtmireIndividualHrefListableObjectSourceModel<T>,
    private hrefOnlyDataService: HrefOnlyDataService,
  ) {
    super(model);
    this.sourceHref = model.sourceHref;
    this.hrefs = model.hrefs;
  }

  public getList(
    paginationOptions: Partial<PaginationComponentOptions>, sort: Partial<SortOptions>
  ): Observable<RemoteData<PaginatedList<ListableObject>>> {
    const startIndex = (paginationOptions.currentPage - 1) * paginationOptions.pageSize;
    const endIndex = startIndex + paginationOptions.pageSize;
    return combineLatest([
      ...this.hrefs.slice(startIndex, endIndex).map((href) =>
        href ? this.hrefOnlyDataService.findByHref<T>(
          this.getNormalizedHref(href),
          this.useCachedVersionIfAvailable,
          this.reRequestOnStale,
          ...this.linksToFollow
        ).pipe(getAllCompletedRemoteData()) : of(null))
    ]).pipe(
      map((rds: RemoteData<T>[]) => {
        const page = rds.map((rd) => {
          if (rd && rd.hasSucceeded) {
            return rd.payload;
          } else {
            return new ListableNotificationObject();
          }
        });
        return new RemoteData<PaginatedList<ListableObject>>(
          null,
          null,
          null,
          RequestEntryState.Success,
          undefined,
          Object.assign(new PaginatedList<ListableObject>(), {
            page,
            pageInfo: new PageInfo({
              elementsPerPage: paginationOptions.pageSize,
              totalElements: this.hrefs.length,
              totalPages: Math.ceil(this.hrefs.length / paginationOptions.pageSize),
              currentPage: paginationOptions.currentPage - 1,
            })
          }),
        );
      }),
    );
  }

  private getNormalizedHref(href: string): string {
    if (href.includes(environment.rest.baseUrl)) {
      return href;
    } else {
      return new RESTURLCombiner(...href.split('/')).toString();
    }
  }

  public get params(): object {
    return { href: this.sourceHref };
  }
}
