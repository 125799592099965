<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<h3>{{ (msgPrefix + 'title') | translate }}</h3>
<p [ngClass]="{'orderable': orderable}">
  <ng-container *ngIf="(objectsRD$ | async)?.isSuccess">
    <ds-alert *ngIf="!(objectsRD$ | async)?.payload || (objectsRD$ | async)?.payload?.page.length === 0"
              [type]="AlertTypeEnum.Info">
      {{ (msgPrefix + 'list.empty') | translate }}
    </ds-alert>
    <ds-pagination *ngIf="parent && (objectsRD$ | async)?.payload"
                   [hideGear]="true"
                   [hidePaginationDetail]="true"
                   [hidePagerWhenSinglePage]="true"
                   [paginationOptions]="options"
                   [pageInfoState]="pageInfo"
                   [collectionSize]="(pageInfo | async)?.totalElements"
                   [retainScrollPosition]="true">
      <div [id]="parent.id" class="featured-publications-list"
           [ngClass]="{'mb-4': (objectsRD$ | async)?.payload?.totalElements > pageSize}"
           *ngVar="((updates$ | async) | dsObjectValues) as updateValues" cdkDropList
           (cdkDropListDropped)="drop($event)">
        <div class="d-flex my-3" *ngFor="let updateValue of updateValues; index as i">
          <div class="d-flex flex-grow-1" cdkDrag cdkDragHandle [cdkDragDisabled]="!orderable"
               [id]="updateValue.field.uuid">
            <div [ngClass]="{'d-none': !orderable, 'd-flex': orderable}"
                 class="align-items-center drag-icon"
                 slot="drag-handle">
              <div class="drag-handle text-muted mr-3">
                <i class="fas fa-grip-vertical fa-fw"
                   [title]="(msgPrefix + 'list.drag') | translate"></i>
              </div>
            </div>
            <ds-listable-object-component-loader class="flex-grow-1" [linkType]="linkTypes.Link" [context]="context"
                                                 [viewMode]="viewMode"
                                                 [object]="updateValue.field"></ds-listable-object-component-loader>
          </div>
          <button *ngIf="deleteEnabled" [ngbTooltip]="(msgPrefix + 'list.delete') | translate" container="body"
                  class="btn btn-danger ml-2 my-auto" (click)="showDeleteModal(updateValue.field)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </ds-pagination>
  </ng-container>
  <ng-container *ngIf="!(objectsRD$ | async) || (objectsRD$ | async)?.isLoading">
    <ds-loading></ds-loading>
  </ng-container>
</p>
