/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CmsMenuDataService } from '../core/data/cms-menu-data.service';
import { filter, map } from 'rxjs/operators';
import { hasValue } from '../../app/shared/empty.util';

/**
 * A guard to prevent access to the CMS menu page when disabled
 */
@Injectable()
export class CmsMenuGuard implements CanActivate {

  constructor(protected cmsMenuService: CmsMenuDataService,
              protected router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.cmsMenuService.isEnabled().pipe(
      filter((isEnabled) => hasValue(isEnabled)),
      map((isEnabled) => {
        if (isEnabled) {
          return true;
        } else {
          return this.router.createUrlTree(['404']);
        }
      })
    );
  }


}
