<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<p class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="orderTypeToggle" [(ngModel)]="orderManually" (change)="changeOrder()">
  <label class="custom-control-label" for="orderTypeToggle">Order manually</label>
</p>
