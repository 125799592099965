/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MenuResolver } from '../app/menu.resolver';
import { MenuService } from '../app/shared/menu/menu.service';
import { BrowseService } from '../app/core/browse/browse.service';
import { AuthorizationDataService } from '../app/core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ScriptDataService } from '../app/core/data/processes/script-data.service';
import { Injectable } from '@angular/core';
import { MenuID, MenuItemType } from '../app/shared/menu/initial-menus-state';
import { LinkMenuItemModel } from '../app/shared/menu/menu-item/models/link.model';
import { filter, map, switchMap } from 'rxjs/operators';
import { getAllSucceededRemoteDataPayload } from '../app/core/shared/operators';
import { PaginatedList } from '../app/core/data/paginated-list.model';
import { CmsMenu } from './shared/cms-menu.model';
import { hasValue, isNotEmpty } from '../app/shared/empty.util';
import { AuthService } from '../app/core/auth/auth.service';
import { TextMenuItemModel } from '../app/shared/menu/menu-item/models/text.model';
import { MenuSection } from '../app/shared/menu/menu.reducer';
import { CmsMenuDataService } from './core/data/cms-menu-data.service';
import {
  CreateStaticPageParentSelectorComponent
} from './shared/dso-selector/modal-wrappers/static-page-wrapper/create-static-page-parent-selector/create-static-page-parent-selector.component';
import { OnClickMenuItemModel } from '../app/shared/menu/menu-item/models/onclick.model';
import { EditStaticPageWrapperComponent } from './shared/dso-selector/modal-wrappers/static-page-wrapper/edit-static-page-wrapper/edit-static-page-wrapper.component';
import { FeatureID } from '../app/core/data/feature-authorization/feature-id';
import { environment } from '../environments/environment';
import { StatisticsMenuService } from './shared/menu/statistics-menu/statistics-menu.service';

/**
 * Menu entries specific to app-atmire are defined here.
 */
@Injectable()
export class AtmireMenuResolver extends MenuResolver {

  cmsSearchSubscription: Subscription;
  cmsMenusSubscription: Subscription;

  constructor(
    protected authService: AuthService,
    protected menuService: MenuService,
    protected browseService: BrowseService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected scriptDataService: ScriptDataService,
    protected cmsMenuService: CmsMenuDataService,
    protected statisticsMenuService: StatisticsMenuService,
  ) {
    super(menuService, browseService, authorizationService, modalService, scriptDataService);
  }


  public createPublicMenu$(): Observable<boolean> {
    this.retrieveCurrentCmsMenu();
    this.createStatisticsMenu();

    this.authorizationService.isAuthorized(FeatureID.CanCreateSavedItemList).subscribe((authorized) => {
      if (environment.atmire.savedItemLists.anonymous || authorized) {
        this.menuService.addSection(MenuID.PUBLIC, {
          id: 'my_lists',
          active: false,
          visible: true,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.my_lists',
            link: `/my-lists`
          } as LinkMenuItemModel,
          index: 2,
          shouldPersistOnRouteChange: true,
        });
      }
    });

    return super.createPublicMenu$();
  }

  retrieveCurrentCmsMenu() {
    if (hasValue(this.cmsSearchSubscription)) {
      this.cmsSearchSubscription.unsubscribe();
    }
    if (hasValue(this.cmsMenusSubscription)) {
      this.cmsMenusSubscription.unsubscribe();
    }
    const currentMenus = new BehaviorSubject<MenuSection[]>([]);
    this.cmsSearchSubscription = this.cmsMenuService.isEnabled().pipe(
      filter((isEnabled) => isEnabled),
      switchMap(() => this.cmsMenuService.searchMenuByLanguage('')),
      getAllSucceededRemoteDataPayload(),
      map((cmsMenuPage: PaginatedList<CmsMenu>) => cmsMenuPage.page),
    ).subscribe((cmsMenuList: CmsMenu[]) => {
      currentMenus.getValue().forEach((menuSection) => this.menuService.removeSection(MenuID.PUBLIC, menuSection.id));
      if (isNotEmpty(cmsMenuList)) {
        const cmsMenu = cmsMenuList[0];
        const menuList = [];
        let count = 0;
        cmsMenu.links.forEach((link) => {
          menuList.push({
            id: `cmsmenus_${count}`,
            parentID: 'cmsmenus',
            active: false,
            visible: true,
            model: {
              type: MenuItemType.LINK,
              text: link.label,
              link: link.url
            } as LinkMenuItemModel
          });
          count = count + 1;
        });
        menuList.push(
          {
            id: 'cmsmenus',
            active: false,
            visible: true,
            model: {
              type: MenuItemType.TEXT,
              text: cmsMenu.title
            } as TextMenuItemModel,
            index: 3
          });
        currentMenus.next(menuList);
      } else {
        currentMenus.next([]);
      }
    });

    this.cmsMenusSubscription = currentMenus.subscribe((menuList) => {
      menuList.forEach((menuSection) => this.menuService.addSection(MenuID.PUBLIC, Object.assign(new MenuSection(), menuSection, {
        shouldPersistOnRouteChange: true
      })));
    });
  }

  createMainMenuSections(): void {
    super.createMainMenuSections();
    this.createCuaMenuSections();

    // we're adding entries to sections added in the base meethod call above
    // visibility for the current user is handed via these sections, so we don't have to re-check authorizations here
    const menuList = [
      {
        id: 'static-page',
        parentID: 'new',
        active: false,
        visible: true,
        index: 10,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.new_static_page',
          function: () => {
            this.modalService.open(CreateStaticPageParentSelectorComponent);
          }
        } as OnClickMenuItemModel,
      },
      {
        id: 'edit_static_page',
        parentID: 'edit',
        active: false,
        visible: true,
        index: 10,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.edit_static_page',
          function: () => {
            this.modalService.open(EditStaticPageWrapperComponent);
          }
        } as OnClickMenuItemModel,
      },
    ];

    menuList.forEach((menuSection) => this.menuService.addSection(MenuID.ADMIN, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));

    this.authorizationService.isAuthorized(FeatureID.AdministratorOf).subscribe((isSiteAdmin: boolean) => {
      const featuredMenuList = [
        {
          id: 'edit_site',
          parentID: 'edit',
          index: 0,
          active: false,
          visible: true,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.edit_site',
            link: '/site/edit'
          } as LinkMenuItemModel
        },
        {
          id: 'manage_featured_publications',
          active: false,
          visible: isSiteAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.manage_featured_publications',
            link: '/featured-publications/manage'
          } as LinkMenuItemModel,
          icon: 'list-alt',
          index: 12
        },
      ];
      featuredMenuList.forEach((menuSection) => this.menuService.addSection(MenuID.ADMIN, Object.assign(menuSection, {
        shouldPersistOnRouteChange: true
      })));
    });
  }

  createExportMenuSections(): void {
    super.createExportMenuSections();
  }

  createImportMenuSections(): void {
    super.createImportMenuSections();
  }

  createSiteAdministratorMenuSections(): void {
    super.createSiteAdministratorMenuSections();
    combineLatest([
      this.authorizationService.isAuthorized(FeatureID.AdministratorOf),
      this.cmsMenuService.isEnabled()
    ]).subscribe(([authorized, cmsEnabled]) => {

      const menuList = [
        {
          id: 'cms_menu',
          active: false,
          visible: authorized && cmsEnabled,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.cms_menu',
            link: 'menus'
          } as LinkMenuItemModel,
          icon: 'bars',
          index: 10
        },
      ];

      menuList.forEach((menuSection) => this.menuService.addSection(MenuID.ADMIN, Object.assign(menuSection, {
        shouldPersistOnRouteChange: true
      })));
    });
  }

  createAccessControlMenuSections(): void {
    super.createAccessControlMenuSections();
  }

  createStatisticsMenu() {
    this.statisticsMenuService.addMenuOptionsForType('Site', true);
  }

  /**
   * Create menu sections dependent on whether or not the current user is a CUA administrator
   */
  createCuaMenuSections() {
    this.authorizationService.isAuthorized(FeatureID.CUAAdministratorOf).subscribe((isCUAAdmin: boolean) => {
      const menuList = [
        /* CUA Statistics */
        {
          id: 'atmire-cua_storage',
          parentID: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.atmire-cua_storage',
            link: 'atmire-cua/storage'
          } as LinkMenuItemModel
        },
        {
          id: 'atmire-cua_search',
          parentID: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.atmire-cua_search',
            link: 'atmire-cua/search'
          } as LinkMenuItemModel
        },
        {
          id: 'atmire-cua_usage',
          parentID: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.atmire-cua_usage',
            link: 'atmire-cua/usage'
          } as LinkMenuItemModel
        },
        {
          id: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.TEXT,
            text: 'menu.section.atmire-cua'
          } as TextMenuItemModel,
          icon: 'chart-bar',
          index: 12
        }
      ];

      menuList.forEach((menuSection) => this.menuService.addSection(MenuID.ADMIN, Object.assign(menuSection, {
        shouldPersistOnRouteChange: true
      })));
    });
  }
}
