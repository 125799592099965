import { Component, Input } from '@angular/core';
import { ThemedComponent } from '../../theme-support/themed.component';
import { MyDSpaceViewButtonComponent } from './my-dspace-view-button.component';

@Component({
  selector: 'ds-themed-my-dspace-view-button',
  styleUrls: [],
  templateUrl: '../../theme-support/themed.component.html',
})
export class ThemedMyDSpaceViewButtonComponent extends ThemedComponent<MyDSpaceViewButtonComponent> {

  @Input() marginClasses: string;

  @Input() viewLink: string;

  protected inAndOutputNames: (keyof MyDSpaceViewButtonComponent & keyof this)[] = ['viewLink', 'marginClasses'];

  protected getComponentName(): string {
    return 'MyDSpaceViewButtonComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../themes/${themeName}/app/shared/mydspace-actions/mydspace-view-button/my-dspace-view-button.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`src/app/shared/mydspace-actions/mydspace-view-button/my-dspace-view-button.component`);
  }


}
