/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { CacheableObject } from '../../../app/core/cache/object-cache.reducer';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { OrderType } from '../../shared/pagination-drag-and-drop/order-switch/order-type.enum';
import { MoveOperation, ReplaceOperation } from 'fast-json-patch/commonjs/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComColOrderService<T extends | CacheableObject> {
  moveChildren(object: T, childLink: keyof T['_links'], moveOperations: MoveOperation[], dataService: DataService<T>): Observable<RemoteData<T>> {
    const operations = moveOperations.map((moveOperation) =>
      Object.assign({}, moveOperation, {
          from: `/_links/${childLink}${moveOperation.from}/href`,
          path: `/_links/${childLink}${moveOperation.path}/href`
        }
      )
    );
    return dataService.patch(object, operations);
  }

  setOrderType(dso: T, orderType: OrderType, dataService: DataService<T>): Observable<RemoteData<T>> {
    const operation = {op: 'replace', path: '/order', value: orderType.toString()} as ReplaceOperation<string>;
    return dataService.patch(dso, [operation]);
  }
}
