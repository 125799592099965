/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { MetadataService } from '../../../app/core/metadata/metadata.service';
import { Item } from '../../../app/core/shared/item.model';
import { getFirstSucceededRemoteDataPayload } from '../../../app/core/shared/operators';
import { Bitstream } from '../../../app/core/shared/bitstream.model';

@Injectable()
export class AtmireMetadataService extends MetadataService {

  protected setDSOMetaTags(): void {
    super.setDSOMetaTags();

    this.setCoverUrlTag();
    this.setDCIdentifierTag();
  }

  /**
   * Add <meta name="cover_url" ... >  to the <head>
   */
  private setCoverUrlTag(): void {
    (this.currentObject.value as Item).thumbnail.pipe(
      getFirstSucceededRemoteDataPayload(),
    ).subscribe((bitstream: Bitstream) => {
      if (bitstream) {
        this.addMetaTag('cover_url', bitstream._links.content.href);
      }
    });
  }
  private setDCIdentifierTag(): void {
    const value = this.getFirstMetaTagValue(['fao.identifier.doi']);
    this.addMetaTag('DC.Identifier', value);
  }

}
