<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-themed-badges *ngIf="showLabel" [object]="dso"></ds-themed-badges>

<div class="d-flex align-items-center">
  <div class="flex-grow-1 overflow-hidden">
    <ds-truncatable [id]="dso.id">
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
         [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
         [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
         [innerHTML]="dsoTitle"></a>
      <span *ngIf="linkType == linkTypes.None"
            class="lead item-list-title dont-break-out"
            [innerHTML]="dsoTitle"></span>
      <!--<span class="text-muted">-->
      <!--<ds-truncatable-part [id]="dso.id" [minLines]="1">-->
      <!--<span *ngIf="dso.allMetadata(['project.identifier.status']).length > 0"-->
      <!--class="item-list-status">-->
      <!--<span *ngFor="let value of allMetadataValues(['project.identifier.status']); let last=last;">-->
      <!--<span [innerHTML]="value"><span [innerHTML]="value"></span></span>-->
      <!--</span>-->
      <!--</span>-->
      <!--</ds-truncatable-part>-->
      <!--</span>-->
    </ds-truncatable>
  </div>
  <div class="ml-2">
    <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="dso" [context]="context"></ds-atmire-saved-item-list-control>
  </div>
</div>
