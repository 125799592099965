/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/* tslint:disable:max-classes-per-file */

import { DataService } from '../../app/core/data/data.service';
import { RequestService } from '../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../app/core/core.reducers';
import { ObjectCacheService } from '../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../app/core/data/default-change-analyzer.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FindListOptions } from '../../app/core/data/request.models';
import { FollowLinkConfig } from '../../app/shared/utils/follow-link-config.model';
import { RemoteData } from '../../app/core/data/remote-data';
import { PaginatedList } from '../../app/core/data/paginated-list.model';
import { RelatedItemListConfig } from './related-item-list-config.model';
import { RELATED_ITEM_LIST_CONFIG } from './related-item-list-config.resource-type';
import { dataService } from 'src/app/core/cache/builders/build-decorators';

class DataServiceImpl extends DataService<RelatedItemListConfig> {
  protected linkPath = 'item/relateditemlistconfigs';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<RelatedItemListConfig>) {
    super();
  }
}

/**
 * A DataService with only findByHref methods. Its purpose is to be used for resources that don't
 * need to be retrieved by ID, or have any way to update them, but require a DataService in order
 * for their links to be resolved by the LinkService.
 *
 * an @dataService annotation can be added for any number of these resource types
 */
@Injectable({ providedIn: 'root' })
@dataService(RELATED_ITEM_LIST_CONFIG)
export class RelatedItemsDataService {
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<RelatedItemListConfig>) {
    this.dataService = new DataServiceImpl(requestService, rdbService, store, objectCache, halService, notificationsService, http, comparator);
  }

  /**
   * Returns a list of observables of {@link RemoteData} of objects, based on an href, with a list of {@link FollowLinkConfig},
   * to automatically resolve {@link HALLink}s of the object
   * @param href                        The url of object we want to retrieve
   * @param findListOptions             Find list options object
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findAllByHref(href: string | Observable<string>, findListOptions: FindListOptions = {}, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<RelatedItemListConfig>[]): Observable<RemoteData<PaginatedList<RelatedItemListConfig>>> {
    return this.dataService.findAllByHref(href, findListOptions, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }
}
