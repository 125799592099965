/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnChanges, SimpleChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationDataService } from '../../../app/core/data/feature-authorization/authorization-data.service';
import { Observable } from 'rxjs/internal/Observable';
import { FeatureID } from '../../../app/core/data/feature-authorization/feature-id';
import { hasNoValue, hasValue } from '../../../app/shared/empty.util';
import { MetadataValue } from '../../../app/core/shared/metadata.models';
import { Operation } from 'fast-json-patch';
import { getFirstCompletedRemoteData } from '../../../app/core/shared/operators';
import {
  MetadataPatchRemoveOperation
} from '../../../app/core/data/object-updates/patch-operation-service/operations/metadata/metadata-patch-remove-operation.model';
import {
  MetadataPatchAddOperation
} from '../../../app/core/data/object-updates/patch-operation-service/operations/metadata/metadata-patch-add-operation.model';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Site } from '../../../app/core/shared/site.model';
import { SiteDataService } from '../../../app/core/data/site-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-dso-add-uuid-button',
  templateUrl: './dso-add-uuid-button.component.html',
  styleUrls: ['./dso-add-uuid-button.component.scss']
})
/**
 * Display a button to add the UUID for a DSO to a metadata field of another
 */
export class DsoAddUuidButtonComponent implements OnChanges, OnDestroy, OnInit {
  /**
   * The UUID of the DspaceObject
   */
  @Input() uuid: string;

  /**
   * I18n key prefix for messages
   */
  @Input() msgPrefix: string;

  /**
   * The site object to add the uuid to
   */
  site: Site;

  /**
   * The metadata field to add the UUID to
   */
  metadataField = 'atmire.home.publications';

  /**
   * Whether or not the current user is authorized to edit the DSpaceObject
   */
  isAuthorized$: Observable<boolean>;

  /**
   * The metadata value containing addFromDso's UUID, stored in addToDso
   * Null if the UUID isn't present in the object's metadata yet
   */
  metadataValue$: BehaviorSubject<MetadataValue> = new BehaviorSubject<MetadataValue>(null);

  /**
   * Whether or not a request is currently loading
   * This allows us to disable the button to avoid double requests and display a loading indicator
   */
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscription: Subscription;

  constructor(protected authorizationService: AuthorizationDataService,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected siteService: SiteDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMetadataValue();
    if (hasValue(this.site) && hasNoValue(this.isAuthorized$)) {
      this.isAuthorized$ = this.authorizationService.isAuthorized(FeatureID.CanEditMetadata, this.site.self);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.siteService.find().subscribe((site: Site) => {
      this.site = site;
      if (hasNoValue(this.isAuthorized$)) {
        this.isAuthorized$ = this.authorizationService.isAuthorized(FeatureID.CanEditMetadata, this.site.self);
      }
      this.updateMetadataValue();
    });
  }

  updateMetadataValue() {
    if (hasValue(this.site)) {
      this.metadataValue$.next(this.site.allMetadata(this.metadataField).find((metadata) => metadata.value === this.uuid));
    } else {
      this.metadataValue$.next(null);
    }
  }

  toggle() {
    let addOrRemove: string;
    let operation: Operation;
    if (hasValue(this.metadataValue$.value)) {
      operation = new MetadataPatchRemoveOperation(this.metadataField, this.metadataValue$.value.place).toOperation();
      addOrRemove = 'remove';
    } else {
      operation = new MetadataPatchAddOperation(this.metadataField, { value: this.uuid }).toOperation();
      addOrRemove = 'add';
    }
    this.loading$.next(true);
    this.siteService.patch(this.site, [operation]).pipe(
      getFirstCompletedRemoteData()
    ).subscribe((rd) => {
      if (rd.hasSucceeded) {
        this.notificationsService.success(this.translate.instant(`${this.msgPrefix}featured.${addOrRemove}.success.title`), this.translate.instant(`${this.msgPrefix}featured.${addOrRemove}.success.content`));
        this.site = rd.payload;
        this.updateMetadataValue();
      } else if (rd.hasFailed) {
        this.notificationsService.error(this.translate.instant(`${this.msgPrefix}featured.${addOrRemove}.error.title`), rd.errorMessage);
      }
      this.loading$.next(false);
    });
  }

}
