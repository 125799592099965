<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-truncatable-part *ngIf="title" [background]="isCurrent() ? 'primary' : 'default'" [maxLines]="1">
  <div [innerHTML]="title"
       [ngClass]="isCurrent() ? 'text-light' : 'text-primary'"
       class="font-weight-bold"></div>
</ds-truncatable-part>
<ds-truncatable-part *ngIf="description" [maxLines]="1" [background]="isCurrent() ? 'primary' : 'default'">
  <div [innerHTML]="description"
       [ngClass]="isCurrent() ? 'text-light' : 'text-secondary'"
       class="text-secondary"></div>
</ds-truncatable-part>
