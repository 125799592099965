/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { hasValue, hasNoValue, isNotEmpty } from '../../app/shared/empty.util';
import { DSpaceObject } from '../../app/core/shared/dspace-object.model';
import { getDSORoute } from '../../app/app-routing-paths';
import { NamedThemeConfig } from '../../config/theme.model';

// tslint:disable:max-classes-per-file
export interface AtmireNamedThemeConfig extends NamedThemeConfig {

  isRTL?: boolean;
}

export interface AtmireRegExThemeConfig extends AtmireNamedThemeConfig {
  regex: string;
}

export interface AtmireHandleThemeConfig extends AtmireNamedThemeConfig {
  handle: string;
}

export interface AtmireUUIDThemeConfig extends AtmireNamedThemeConfig {
  uuid: string;
}

export class AtmireTheme {
  constructor(public config: AtmireNamedThemeConfig) {
  }

  matches(url: string, dso: DSpaceObject, isCurrentLangRTL = false): boolean {
    const configIsRTL = hasValue(this.config.isRTL) ? this.config.isRTL : false;
    return configIsRTL === isCurrentLangRTL;
  }
}

export class AtmireRegExTheme extends AtmireTheme {
  regex: RegExp;

  constructor(public config: AtmireRegExThemeConfig) {
    super(config);
    this.regex = new RegExp(this.config.regex);
  }

  matches(url: string, dso: DSpaceObject, isCurrentLangRTL = false): boolean {
    if (!super.matches(url, dso, isCurrentLangRTL)) {
      return false;
    }
    let match;
    const route = getDSORoute(dso);

    if (isNotEmpty(route)) {
      match = route.match(this.regex);
    }

    if (hasNoValue(match)) {
      match = url.match(this.regex);
    }

    return hasValue(match);
  }
}

export class AtmireHandleTheme extends AtmireTheme {
  constructor(public config: AtmireHandleThemeConfig) {
    super(config);
  }

  matches(url: string, dso: any, isCurrentLangRTL = false): boolean {
    if (!super.matches(url, dso, isCurrentLangRTL)) {
      return false;
    }
    return hasValue(dso) && hasValue(dso.handle) && dso.handle.includes(this.config.handle);
  }
}

export class AtmireUUIDTheme extends AtmireTheme {
  constructor(public config: AtmireUUIDThemeConfig) {
    super(config);
  }

  matches(url: string, dso: DSpaceObject, isCurrentLangRTL = false): boolean {
    if (!super.matches(url, dso, isCurrentLangRTL)) {
      return false;
    }
    return hasValue(dso) && dso.uuid === this.config.uuid;
  }
}

export const atmireThemeFactory = (config: AtmireThemeConfig): AtmireTheme => {
  if (hasValue((config as AtmireRegExThemeConfig).regex)) {
    return new AtmireRegExTheme(config as AtmireRegExThemeConfig);
  } else if (hasValue((config as AtmireHandleThemeConfig).handle)) {
    return new AtmireHandleTheme(config as AtmireHandleThemeConfig);
  } else if (hasValue((config as AtmireUUIDThemeConfig).uuid)) {
    return new AtmireUUIDTheme(config as AtmireUUIDThemeConfig);
  } else {
    return new AtmireTheme(config as NamedThemeConfig);
  }
};

export type AtmireThemeConfig
  = AtmireNamedThemeConfig
  | AtmireRegExThemeConfig
  | AtmireHandleThemeConfig
  | AtmireUUIDThemeConfig;
