/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, ObservableInput, throwError } from 'rxjs';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { CoreState } from '../../../app/core/core.reducers';

import { DataService } from '../../../app/core/data/data.service';
import { RequestService } from '../../../app/core/data/request.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { FindListOptions } from '../../../app/core/data/request.models';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { StaticPage } from '../../shared/static-page.model';
import { distinctUntilChanged, filter, map, mergeMap, take, catchError } from 'rxjs/operators';
import {
  getAllSucceededRemoteData,
  getFirstSucceededRemoteData,
  sendRequest, getAllCompletedRemoteData,
} from '../../../app/core/shared/operators';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { StaticPageContentObject } from '../../static-page/static-page-content-object.model';
import { StaticPageDataFormBuilder } from './static-page-data-form-builder.service';
import { StaticPageSearchOptions } from '../../static-page/static-page-search-options.model';
import { hasValue, isEmpty, isNotEmpty } from '../../../app/shared/empty.util';
import { RemoteData } from '../../../app/core/data/remote-data';
import { TranslateService } from '@ngx-translate/core';
import { HostWindowService } from '../../../app/shared/host-window.service';
import { StaticPageImageFormBuilder } from './static-page-image-form-builder.service';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { STATIC_PAGE } from '../../shared/static-page.resource-type';
import { followLink } from '../../../app/shared/utils/follow-link-config.model';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { RestRequestMethod } from '../../../app/core/data/rest-request-method';
import { AtmireMultipartPostRequest, AtmireMultiPartRequest } from './atmire-request.models';
import { Router } from '@angular/router';
import { getStaticPageModuleRoute } from '../../static-page/static-page-routing-paths';
import { Site } from 'src/app/core/shared/site.model';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { Community } from 'src/app/core/shared/community.model';
import { getCommunityModuleRoute } from '../../../app/community-page/community-page-routing-paths';
import { Collection } from '../../../app/core/shared/collection.model';
import { getCollectionModuleRoute } from '../../../app/collection-page/collection-page-routing-paths';
import { Item } from '../../../app/core/shared/item.model';
import { getItemModuleRoute } from '../../../app/item-page/item-page-routing-paths';

/**
 * Service responsible for handling requests related to the Static Pages.
 */
@Injectable({
  providedIn: 'root'
})@dataService(STATIC_PAGE)
export class StaticPageDataService extends DataService<StaticPage> {
  protected linkPath = 'pages';
  protected searchLinkPath = 'pages/search/dso';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<StaticPage>,
    protected translateService: TranslateService,
    protected hostWindowService: HostWindowService,
    protected router: Router
  ) {
    super();
  }

  /**
   * Get the endpoint for browsing static pages
   * @param {FindAllOptions} options
   * @param {Observable<string>} linkPath
   */
  getBrowseEndpoint(options: FindListOptions = {}, linkPath: string = this.linkPath): Observable<string> {
    return this.halService.getEndpoint(linkPath);
  }

  /**
   * Get the endpoint to create a new static page
   */
  public getPageCreateEndpoint(): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }

  /**
   * Get the endpoint to update an existing static page
   * @param pageId
   */
  public getPageUpdateEndpoint(pageId: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath).pipe(
      map((endpoint: string) => this.getIDHref(endpoint, pageId))
    );
  }

  /**
   * Update an existing static page
   * @param pageId
   * @param staticPageContentObject
   */
  public updatePage(pageId: string, staticPageContentObject: StaticPageContentObject): Observable<RemoteData<StaticPage>> {

    const requestId = this.requestService.generateRequestId();

    this.getPageUpdateEndpoint(pageId).pipe(
      distinctUntilChanged(),
      map((endpointURL: string) =>
        new AtmireMultiPartRequest(requestId, endpointURL, RestRequestMethod.PUT, new StaticPageDataFormBuilder().buildForm(staticPageContentObject), {})),
      sendRequest(this.requestService)).subscribe();

    return this.rdbService.buildFromRequestUUID(requestId);
  }

  /**
   * Create a new Static Page
   * @param staticPageContentObject
   */
  public createPage(staticPageContentObject: StaticPageContentObject): Observable<RemoteData<StaticPage>> {
    const requestId = this.requestService.generateRequestId();

    this.getPageCreateEndpoint().pipe(
      map((endpointURL: string) => {
        return new AtmireMultiPartRequest(requestId, endpointURL, RestRequestMethod.POST, new StaticPageDataFormBuilder().buildForm(staticPageContentObject));
      }),
      sendRequest(this.requestService)
    ).subscribe();

    return this.rdbService.buildFromRequestUUID(requestId);
  }

  /**
   * Create a new Image Static Page
   * @param dsoUuid
   * @param file
   */
  public createImagePage(dsoUuid, file): Observable<RemoteData<StaticPage>> {

    const staticPage = new StaticPage();
    staticPage.language = 'n/a';
    staticPage.title = file.name;
    staticPage.name = `${new Date().getTime()}`;

    const requestId = this.requestService.generateRequestId();

    this.getPageCreateEndpoint().pipe(
      map((endpointURL: string) => {
        return new AtmireMultipartPostRequest(requestId, endpointURL, new StaticPageImageFormBuilder().buildForm(staticPage, file, dsoUuid));
      }),
      sendRequest(this.requestService)
    ).subscribe();

    return this.rdbService.buildFromRequestUUID(requestId);
  }

  /**
   * Retrieve the content for a Static Page
   * In case the Static Page is an image, the image will be obtained in <img> html tags
   * @param staticPage
   */
  public retrieveContent(staticPage: StaticPage): Observable<string> {
    return staticPage.format.pipe(
      getAllCompletedRemoteData(),
      filter((formatRd) => hasValue(formatRd.payload)),
      mergeMap((formatRD) => {
        if (formatRD.payload.mimetype.startsWith('image/')) {
          return observableOf(`<img src="${staticPage._links.content.href}"/>`);
        } else {
          return this.http.get(staticPage._links.content.href, {responseType: 'text'}).pipe(
            catchError((err: HttpErrorResponse) => {
                return observableOf(undefined);
            })
          );
        }
      })
    );
  }

  /**
   * Determines whether the entered name already exists
   * @param name
   */
  public isUniqueName(name: string, language: string, scopeUuid: string, pageUuid: string): Observable<boolean> {
    return this.searchStaticPages(new StaticPageSearchOptions({uuid: scopeUuid, name: name, language: language})).pipe(
      getFirstSucceededRemoteData(),
      map((remoteData: RemoteData<PaginatedList<StaticPage>>) => remoteData.payload.page),
      map((page) => {
        if (isEmpty(page)) {
          return true;
        } else {
          if (page.find((value: StaticPage) => value.uuid === pageUuid)) {
            return true;
          }
          return false;
        }
      })
    );
  }

  /**
   * Use the search endpoint for static pages to search for a list of static pages based on provided options
   * @param staticPageSearchOptions
   * @param requestOptions
   */
  public searchStaticPages(staticPageSearchOptions: StaticPageSearchOptions, useCache = true): Observable<RemoteData<PaginatedList<StaticPage>>> {
    return this.searchBy('dso', staticPageSearchOptions.toFindListOptions(), useCache, true, followLink('format'));
  }


  /**
   * Remove the search url from the cache
   */
  public removeSearchCache(): void {

    const hrefObs = this.getSearchByHref('dso');

    hrefObs.pipe(
      filter((url) => hasValue(url)),
      take(1)).subscribe((url) => {
      return this.requestService.setStaleByHrefSubstring(url);
    });
  }

  public removePageFromCache(staticPage: StaticPageContentObject): Observable<boolean> {
    return this.getBrowseEndpoint().pipe(
      map((endpoint: string) => this.getIDHref(endpoint, staticPage.uuid)),
      take(1),
      mergeMap((url: string) => {
        return this.requestService.setStaleByHrefSubstring(url);
      }),
    );
  }

  /**
   * Retrieves the static page content object based on the first static page in a RemoteData<PaginatedList<StaticPage>>
   * @param staticPageObs  - Observable of RemoteData<PaginatedList<StaticPage>>
   * @return an observable of a remoteData object with the static page content object
   */
  public retrieveContentFromPageListObs(staticPageObs: Observable<RemoteData<PaginatedList<StaticPage>>>): Observable<RemoteData<StaticPageContentObject>> {
    return staticPageObs.pipe(
      getFirstSucceededRemoteData(),
      mergeMap((remoteData: RemoteData<PaginatedList<StaticPage>>) => {
        if (remoteData.payload.totalElements === 0) {
          return observableOf(new RemoteData(remoteData.timeCompleted, remoteData.msToLive, remoteData.lastUpdated, remoteData.state, remoteData.errorMessage, undefined, remoteData.statusCode));
        } else {
          const staticPage = remoteData.payload.page[0];
          return this.retrieveContent(staticPage).pipe(
            catchError((err ) => {
              console.error(err);
              return observableOf(new RemoteData(remoteData.timeCompleted, remoteData.msToLive, remoteData.lastUpdated, remoteData.state, err.errorMessage, undefined, err.status));
            }),
            take(1),
            map((contentString: string) => {
                const staticPageTracker = new StaticPageContentObject();
                staticPageTracker.initFromStaticPage(staticPage);
                staticPageTracker.contentString = contentString;
                return new RemoteData(remoteData.timeCompleted, remoteData.msToLive, remoteData.lastUpdated, remoteData.state, remoteData.errorMessage, staticPageTracker, remoteData.statusCode);
              }
            )
          );
        }
      }));
  }

  createOrEditNewsPage(dso) {
    const staticPageSearchOptions = new StaticPageSearchOptions({
      uuid: dso.uuid,
      name: 'news'
    });
    this.searchStaticPages(staticPageSearchOptions).pipe(
      getFirstSucceededRemoteData(),
      map((rd) => {
        if (hasValue(rd.payload) && isNotEmpty(rd.payload.page)) {
          return rd.payload.page[0];
        }
        return null;
      })
    ).subscribe((staticPage: StaticPage) => {
      const urlParts = this.getNavigationUrlParts(staticPage, dso);
      if (hasValue(staticPage)) {
        urlParts.push('edit');
        this.router.navigate(urlParts);
      } else {
        urlParts.push('add');
        this.router.navigate(urlParts, {queryParams: {name: 'news', title: 'News'}});
      }
    });
  }

  private getNavigationUrlParts(staticPage: StaticPage, dso: DSpaceObject) {
    let urlArray;
    if (hasValue(dso) && (dso as any).type !== Site.type.value) {
      urlArray = [this.getPath(dso), dso.uuid, 'pages'];
    } else {
      urlArray = [getStaticPageModuleRoute()];
    }
    if (hasValue(staticPage)) {
      urlArray.push(staticPage.name);
      urlArray.push(staticPage.language);
    }
    return urlArray;
  }

  /**
   * Get the  path of the scope DSO
   * @param dso
   */
  private getPath(dso: DSpaceObject) {
    if ((dso as any).type === Community.type.value) {
      return getCommunityModuleRoute();
    } else if ((dso as any).type === Collection.type.value) {
      return getCollectionModuleRoute();
    } else if ((dso as any).type === Item.type.value) {
      return getItemModuleRoute();
    }
    return null;
  }
}
