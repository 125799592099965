/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * Serializer to create unique fake UUID's from id's that might otherwise be the same across multiple object types
 */

export class UrlToUuidSerializer {

  /**
   * Method to serialize a UUID
   * @param {string} uuid
   * @returns {any} undefined Fake UUID's should not be sent back to the server, but only be used in the UI
   */
  Serialize(uuid: string): any {
    return undefined;
  }

  /**
   * Method to deserialize a UUID
   * @param {string} id Identifier to transform in to a UUID
   * @returns {string} UUID based on the prefix and the given id
   */
  Deserialize(url: string): string {
    const pattern = /.*?([0-9a-fxA-FX]{8}-[0-9a-fxA-FX]{4}-[0-9a-fxA-FX]{4}-[0-9a-fxA-FX]{4}-[0-9a-fxA-FX]{12})/;
    const matchList = pattern.exec(url);

    return matchList[1];

  }
}
