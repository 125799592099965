/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { StaticPage } from '../../../static-page.model';
import { StaticPageDataService } from '../../../../core/data/static-page-data.service';
import { StaticPageSearchOptions } from '../../../../static-page/static-page-search-options.model';
import { StaticPageContentObject } from '../../../../static-page/static-page-content-object.model';
import { hasValue } from '../../../../../app/shared/empty.util';
import { PaginatedList } from '../../../../../app/core/data/paginated-list.model';
import { getAllCompletedRemoteData } from '../../../../../app/core/shared/operators';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'ds-static-page-selector',
  templateUrl: './static-page-selector.component.html'
})

/**
 * Component to render a list of Static Pages of which one can be selected
 * The user can search the list by using the input field
 */
export class StaticPageSelectorComponent implements OnInit {

  /**
   * The UUID of the static page's scope object
   */
  @Input() scopeDsoUuid: string;

  /**
   * The  StaticPageContentObject corresponding to initially selected static page
   */
  @Input() currentStaticPage: StaticPageContentObject;

  /**
   * Emits the selected StaticPage when a user selects it in the list
   */
  @Output() onSelect: EventEmitter<StaticPage> = new EventEmitter();

  /**
   * Input form control to query the list
   */
  public input: FormControl = new FormControl();

  /**
   * List with search results of StaticPage objects for the current query
   */
  listEntries$: Observable<RemoteData<PaginatedList<StaticPage>>>;

  /**
   * List of element references to all elements
   */
  @ViewChildren('listEntryElement') listElements: QueryList<ElementRef>;

  /**
   * Time to wait before sending a search request to the server when a user types something
   */
  debounceTime = 500;

  constructor(private staticPageDataService: StaticPageDataService) {
  }

  /**
   * Fills the listEntries$ variable with search results based on the input field's current value
   * The search will use the currentStaticPage if present, otherwise it will use scopeDsoUuid
   */
  ngOnInit(): void {
    if (hasValue(this.currentStaticPage)) {
      this.input.setValue(this.currentStaticPage.name);
      this.scopeDsoUuid = this.currentStaticPage.dspaceobjectUuid;
      this.listEntries$ = this.input.valueChanges
        .pipe(
          debounceTime(this.debounceTime),
          startWith(this.currentStaticPage.name),
          switchMap((name) => {
              return this.staticPageDataService.searchStaticPages(
                new StaticPageSearchOptions({
                  uuid: this.scopeDsoUuid,
                  name: name,
                  format: ['text/html']
                })
              );
            }
          ),
          getAllCompletedRemoteData()
        );
    } else {
      this.listEntries$ = this.input.valueChanges
        .pipe(
          debounceTime(this.debounceTime),
          startWith(''),
          switchMap((name) => {
              return this.staticPageDataService.searchStaticPages(
                new StaticPageSearchOptions({
                  uuid: this.scopeDsoUuid,
                  name: name,
                  language: '*',
                  format: ['text/html']
                })
              );
            }
          ),
          getAllCompletedRemoteData()
        );
    }
  }

  /**
   * Set focus on the first list element when there is only one result
   */
  selectSingleResult(): void {
    if (this.listElements.length > 0) {
      this.listElements.first.nativeElement.click();
    }
  }
}
