<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="shouldRenderFeaturedItems$">
    <div class="row">
        <div class="container h-100 d-flex align-items-center justify-content-center position-relative">
            <button class="featured-prev mr-3" [hidden]="!(loaded$ | async)">
                <i class="fa fa-chevron-left"></i>
            </button>
            <ds-loading [showMessage]="false" *ngIf="!(loaded$ | async)">
            </ds-loading>
            <swiper *ngIf="(loaded$ | async)"
                    [breakpoints]="breakpoints"
                    [navigation]="navigation"
                    [autoplay]="autoplay"
                    [loop]="true"
            >
                <ng-template  *ngFor="let item of (clientFeaturedItems$ | async)"
                              swiperSlide>

                    <a class="slide text-reset text-decoration-none" [routerLink]="getItemRoute(item)">
                        <ds-truncatable [id]="item.id">
                            <div class="w-100">
                                <ds-thumbnail class="pb-2 w-100"
                                              [limitWidth]="false"
                                              [thumbnail]="item.thumbnail | async">
                                </ds-thumbnail>
                            </div>
                            <div>
              <span class="slide-date">
                {{ getDateIssued(item) }}
              </span>
                            </div>
                            <div>
                                <ds-truncatable-part [id]="item.id"
                                                     [minLines]="titleLines"
                                                     [maxLines]="titleLines"
                                                     [background]="'gray-100'"
                                                     type="h4 slide-title">
                                    {{ getTitle(item) }}
                                </ds-truncatable-part>
                            </div>
                        </ds-truncatable>
                    </a>
                </ng-template>
            </swiper>
            <button class="featured-next ml-3" [hidden]="!(loaded$ | async)">
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>
    </div>
</div>
