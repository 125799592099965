/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LinkService } from '../../../../app/core/cache/builders/link.service';
import { AtmireObjectUpdatesService } from '../../../core/data/object-updates/atmire-object-updates.service';
import { Observable } from 'rxjs/internal/Observable';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { hasValue, isNotEmpty, isNotEmptyOperator } from '../../../../app/shared/empty.util';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { RequestService } from '../../../../app/core/data/request.service';
import { ObjectValuesPipe } from '../../../../app/shared/utils/object-values-pipe';
import { getFirstCompletedRemoteData } from '../../../../app/core/shared/operators';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';
import { PaginationService } from '../../../../app/core/pagination/pagination.service';
import {
  AbstractPaginatedDragAndDropDsoListComponent
} from '../paginated-drag-and-drop-dso-list/abstract-paginated-drag-and-drop-dso-list.component';
import { Item } from '../../../../app/core/shared/item.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { combineLatest as observableCombineLatest, zip as observableZip } from 'rxjs';
import { ItemDataService } from '../../../../app/core/data/item-data.service';
import {
  createSuccessfulRemoteDataObject,
  createSuccessfulRemoteDataObject$
} from '../../../../app/shared/remote-data.utils';
import { PageInfo } from '../../../../app/core/shared/page-info.model';
import { Context } from '../../../../app/core/shared/context.model';
import { ViewMode } from '../../../../app/core/shared/view-mode.model';
import { ConfirmationModalComponent } from '../../../../app/shared/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../../app/core/data/data.service';
import { AlertType } from '../../../../app/shared/alert/aletr-type';
import { followLink } from '../../../../app/shared/utils/follow-link-config.model';
import { NotificationType } from 'src/app/shared/notifications/models/notification-type';
import { Identifiable } from '../../../../app/core/data/object-updates/object-updates.reducer';
import {
  IdentifiableListableNotificationObject
} from '../../object-list/identifiable-listable-notification-object/identifiable-listable-notification-object.model';
import {
  LISTABLE_NOTIFICATION_OBJECT
} from 'src/app/shared/object-list/listable-notification-object/listable-notification-object.resource-type';
import { MetadataValue } from 'src/app/core/shared/metadata.models';
import { MetadataPatchRemoveOperation } from 'src/app/core/data/object-updates/patch-operation-service/operations/metadata/metadata-patch-remove-operation.model';

@Component({
  selector: 'ds-paginated-drag-and-drop-item-parent-metadata-list',
  templateUrl: './paginated-drag-and-drop-item-parent-metadata-list.component.html',
  styleUrls: ['./paginated-drag-and-drop-item-parent-metadata-list.component.scss']
})
/**
 * Component displaying a paginated list of items retrieved from a parent object's metadata
 * Ability to drag and drop items within the list, storing the order in the rxjs store
 * Ability to remove items from the list
 * The parent's metadata is expected to contain UUIDs of the items to display
 */
export class PaginatedDragAndDropItemParentMetadataListComponent extends AbstractPaginatedDragAndDropDsoListComponent<Item> {
  /**
   * Parent object to retrieve metadata from
   */
  @Input() parent: DSpaceObject;

  /**
   * DataService to use for updating the parent object
   */
  @Input() dataService: DataService<DSpaceObject>;

  /**
   * Metadata field storing item UUIDs in the parent object
   */
  @Input() metadataField: string;

  /**
   * Whether or not a delete button should be present for each item in the list
   */
  @Input() deleteEnabled = false;

  /**
   * The Context we're in
   * Used for determining the display of the items
   */
  @Input() context: Context;

  /**
   * The unique URL used to store our drag-and-drop changes in the rxjs store
   */
  @Input() listUrl: string;

  /**
   * Prefix of i18n keys to use
   */
  @Input() msgPrefix: string;

  /**
   * The ViewMode of the items in the list
   */
  viewMode = ViewMode.ListElement;

  /**
   * Observable list of item UUIDs
   */
  uuids$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  /**
   * The AlertType enumeration
   * @type {AlertType}
   */
  AlertTypeEnum = AlertType;

  constructor(protected objectUpdatesService: AtmireObjectUpdatesService,
              protected elRef: ElementRef,
              protected route: ActivatedRoute,
              protected linkService: LinkService,
              protected translateService: TranslateService,
              protected notificationService: NotificationsService,
              protected requestService: RequestService,
              protected objectValuesPipe: ObjectValuesPipe,
              protected paginationService: PaginationService,
              protected itemService: ItemDataService,
              protected modalService: NgbModal) {
    super(objectUpdatesService, elRef, route, linkService, translateService, notificationService, requestService, objectValuesPipe, paginationService);
  }

  /**
   * If the parent changes, update our UUIDs observable
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (hasValue(changes.parent) && hasValue(this.parent)) {
      this.updateUuids();
    }
  }

  /**
   * Update the UUIDs observable by retrieving the parent's metadata values
   */
  updateUuids() {
    this.uuids$.next(this.parent.allMetadata(this.metadataField).map((mdValue) => mdValue.value));
  }

  /**
   * Get a paginated list of items based on the UUIDs found in the parent's metadata
   * Will dynamically create a PaginatedList object containing the current page, size, total pages and total elements
   */
  getObjectListRD$(): Observable<RemoteData<PaginatedList<Item>>> {
    return observableCombineLatest([this.currentPage$, this.uuids$]).pipe(
      switchMap(([page, uuids]) => {
        const pageInfo = new PageInfo({
          elementsPerPage: page.pageSize,
          totalElements: uuids.length,
          totalPages: Math.ceil(uuids.length / page.pageSize),
          currentPage: page.currentPage,
        });

        const end = page.currentPage * page.pageSize;
        const start = end - page.pageSize;
        const uuidsPage = uuids.slice(start, end);
        if (isNotEmpty(uuidsPage)) {
          return observableZip(...uuidsPage.map((uuid) => {
            return this.itemService.findById(uuid, true, true, followLink('thumbnail')).pipe(
              getFirstCompletedRemoteData(),
              map((rd: RemoteData<Item>) => ({ uuid, rd })),
            );
          })).pipe(
            map((items: { uuid: string, rd: RemoteData<Item> }[]) => {
              return createSuccessfulRemoteDataObject(
                Object.assign(new PaginatedList(), {
                  page:
                    (items || []).map((dto: { uuid: string, rd: RemoteData<Item> }) => {
                      if (hasValue(dto.rd.payload)) {
                        return dto.rd.payload;
                      } else {
                        return new IdentifiableListableNotificationObject(NotificationType.Error, this.translateService.instant('item-list.error.item-not-found', { uuid: dto.uuid }), dto.uuid, LISTABLE_NOTIFICATION_OBJECT.value);
                      }
                    }),
                  pageInfo
                })
              );
            }),
          );
        } else {
          return createSuccessfulRemoteDataObject$(
            Object.assign(new PaginatedList(), {
                page: [],
                pageInfo
              }
            ));
        }
      })
    );
  }

  /**
   * Initialize the URL used for the field-update store with a unique url
   */
  initializeURL(): void {
    this.url = this.listUrl;
  }

  /**
   * Submit the current drag-and-drop changes by sending a patch request to the REST API
   * Afterwards, display notifications and reload the list on success
   */
  submit() {
    this.objectUpdatesService.getMoveOperations(this.listUrl).pipe(
      take(1),
      isNotEmptyOperator(),
      switchMap((operations) => {
        return this.dataService.patch(this.parent, operations.map((operation) => Object.assign({}, operation, {
          from: `/metadata/${this.metadataField}${operation.from}`,
          path: `/metadata/${this.metadataField}${operation.path}`,
        })));
      }),
      getFirstCompletedRemoteData()
    ).subscribe((rd) => {
      if (rd.hasSucceeded) {
        this.notificationService.success(this.translateService.instant(`${this.msgPrefix}list.move.success.title`), this.translateService.instant(`${this.msgPrefix}list.move.success.content`));
        this.reloadList(rd.payload);
      } else if (rd.hasFailed) {
        this.notificationService.success(this.translateService.instant(`${this.msgPrefix}list.move.error.title`), rd.errorMessage);
      }
    });
  }

  /**
   * Remove an item's UUID from the parent's metadata
   * Afterwards, display notifications and reload the list on success
   * @param item The item to remove
   */
  showDeleteModal(item: Identifiable) {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.dso = item;
    modalRef.componentInstance.headerLabel = `${this.msgPrefix}list.delete.modal.header`;
    modalRef.componentInstance.infoLabel = item instanceof IdentifiableListableNotificationObject ? this.translateService.instant('already-removed-item.delete.modal.info', { uuid: item.uuid }) : `${this.msgPrefix}list.delete.modal.info`;
    modalRef.componentInstance.cancelLabel = `${this.msgPrefix}list.delete.modal.cancel`;
    modalRef.componentInstance.confirmLabel = `${this.msgPrefix}list.delete.modal.confirm`;
    modalRef.componentInstance.brandColor = 'danger';
    modalRef.componentInstance.confirmIcon = 'fas fa-trash';
    modalRef.componentInstance.response.pipe(take(1)).subscribe((confirm: boolean) => {
      if (confirm) {
        this.delete(item.uuid).subscribe((rd: RemoteData<Item>) => {
          if (rd.hasSucceeded) {
            this.notificationService.success(this.translateService.instant(`${this.msgPrefix}list.delete.success.title`), this.translateService.instant(`${this.msgPrefix}list.delete.success.content`));
            this.reloadList(rd.payload);
          } else if (rd.hasFailed) {
            this.notificationService.error(this.translateService.instant(`${this.msgPrefix}list.delete.error.title`), rd.errorMessage);
          }
        });
      }
    });
  }

  /**
   * Removes the Item form the metadata list with the given {@link UUID}.
   * @param uuid The {@link UUID} of the item to delete
   */
  delete(uuid: string): Observable<RemoteData<DSpaceObject>> {
    const sortedValues = this.parent.allMetadata(this.metadataField)
      .sort((a: MetadataValue, b: MetadataValue) => a.place - b.place);
    const i = sortedValues.findIndex((metadata: MetadataValue) => metadata.value === uuid);
    if (i > -1) {
      return this.dataService.patch(this.parent, [new MetadataPatchRemoveOperation(this.metadataField, i).toOperation()]).pipe(
        getFirstCompletedRemoteData()
      );
    } else {
      return createSuccessfulRemoteDataObject$(this.parent);
    }
  }

  /**
   * Reload the list of items by:
   * - Removing the parent's request from cache
   * - Updating the parent object within this component
   * - Resetting the list updates in the store
   * - Resetting the initialized pages
   * - Updating the UUID observable
   * @param newParent
   */
  reloadList(newParent: DSpaceObject) {
    this.requestService.setStaleByHrefSubstring(this.parent.self);
    this.parent = newParent;
    this.objectUpdatesService.discardAllFieldUpdates(this.url, undefined);
    this.initializedPages = [];
    this.updateUuids();
  }
}
