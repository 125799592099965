/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { SearchModule } from '../../app/shared/search/search.module';
import { FormsModule } from '@angular/forms';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { RootComponent } from './app/root/root.component';
import { AdminSidebarComponent } from './app/admin/admin-sidebar/admin-sidebar.component';
import { AtmireAuthNavMenuComponent } from './app/shared/auth-nav-menu/atmire-auth-nav-menu.component';
import { HeaderComponent } from './app/header/header.component';
import { ExpandableAdminSidebarSectionComponent } from './app/admin/admin-sidebar/expandable-admin-sidebar-section/expandable-admin-sidebar-section.component';
import { LoadingComponent } from './app/shared/loading/loading.component';
import { AtmireLangSwitchComponent } from './app/shared/lang-switch/atmire-lang-switch-component';
import { AtmireAppModule } from '../../app-atmire/atmire-app.module';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  ExpandableAdminSidebarSectionComponent,
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  RootComponent,
  AdminSidebarComponent,
  AtmireAuthNavMenuComponent,
  HeaderComponent,
  LoadingComponent,
  AtmireLangSwitchComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SearchModule,
    FormsModule,
    RootModule,
    NavbarModule,
    ItemPageModule,
    AtmireAppModule,
  ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})
/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}
