<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngIf="isAuthorized$ && isAuthorized$ | async">
  <button *ngVar="(metadataValue$ | async) as metadataValue" [disabled]="loading$ | async"
          class="btn btn-sm" [ngClass]="{'btn-light': !metadataValue, 'btn-dark': metadataValue}"
          [ngbTooltip]="(msgPrefix + 'featured.' + (metadataValue ? 'remove' : 'add')) | translate" container="body"
          (click)="toggle()">
    <i *ngVar="(loading$ | async) as loading" [ngClass]="loading ? 'fas fa-spinner fa-spin' : 'far fa-list-alt'"></i>
  </button>
</ng-container>
