/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { URLCombiner } from '../../app/core/url-combiner/url-combiner';

export const STATIC_PAGES_PATH = 'pages';
export const CMS_MENUS_PATH = 'menus';
export const MENU_ADD_PATH = 'add';
export const MENU_EDIT_PATH = ':id/edit';

export function getStaticPageModuleRoute() {
  return `/${STATIC_PAGES_PATH}`;
}

export function getCmsModuleRoute() {
  return `/${CMS_MENUS_PATH}`;
}


export function getCmsMenuAddPath() {
  return new URLCombiner(getCmsModuleRoute(), MENU_ADD_PATH).toString();
}

export function getCmsMenuEditPath(id: string) {
  return new URLCombiner(getCmsModuleRoute(), MENU_EDIT_PATH.replace(/:id/, id)).toString();
}


