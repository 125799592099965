/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject } from '@angular/core';
import { Item } from '../../../../../../../../../../app/core/shared/item.model';
import { rendersItemSuggestionPreview } from '../../../../../suggestion.decorator';
import { DSOSuggestionPreviewComponent } from '../../../dso-suggestion-preview.component';
import { BitstreamDataService } from '../../../../../../../../../../app/core/data/bitstream-data.service';
import { GET_DSO_PAGE_ROUTE_OPERATOR } from '../../../../../../../../utils/dspace-object.utils';
import { DSpaceObject } from '../../../../../../../../../../app/core/shared/dspace-object.model';

/**
 * This component renders a preview for a item object suggestion
 * It shows a thumbnail, some metadata and bitstreams
 */

@Component({
  selector: 'ds-journal-suggestion-preview',
  styleUrls: ['./journal-suggestion-preview.component.scss'],
  templateUrl: './journal-suggestion-preview.component.html'
})
@rendersItemSuggestionPreview('journal')
export class JournalSuggestionPreviewComponent extends DSOSuggestionPreviewComponent<Item> {
  constructor(protected bitstreamDataService: BitstreamDataService,
              @Inject(GET_DSO_PAGE_ROUTE_OPERATOR) public getDSOPageRoute: (dso: DSpaceObject) => string,
  ) {
    super();
  }
}

