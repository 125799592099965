/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Item } from '../../../app/core/shared/item.model';
import { FeaturedItemsService } from '../../../app-atmire/atmire-featured-items/featured-items.service';
import { FeaturedItemsComponent } from '../../../app-atmire/atmire-featured-items/view/featured-items.component';
import { Metadata } from '../../../app/core/shared/metadata.utils';
import SwiperCore, { A11y, Autoplay, Navigation } from 'swiper/core';
import { AutoplayOptions, NavigationOptions } from 'swiper/types';
import { map, switchMap } from 'rxjs/operators';
import { getItemPageRoute } from '../../../app/item-page/item-page-routing-paths';
import { getFirstCompletedRemoteData } from '../../../app/core/shared/operators';
import { combineLatest } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { hasValue } from '../../../app/shared/empty.util';


SwiperCore.use([Navigation, Autoplay, A11y]);

@Component({
  selector: 'ds-client-featured-items',
  templateUrl: './client-featured-items.component.html',
  styleUrls: ['./client-featured-items.component.scss'],
})
export class ClientFeaturedItemsComponent extends FeaturedItemsComponent {

  /**
   * Fixed number of lines to use for titles.
   * Ensures that slides have the same height and long titles are truncated.
   */
  @Input() titleLines: number;

  loaded$: Observable<boolean>;

  clientFeaturedItems$: Observable<Item[]>;

  navigation = {
    nextEl: '.featured-next',
    prevEl: '.featured-prev',
  } as NavigationOptions;

  autoplay = {
    delay: 8000,
    disableOnInteraction: false,
  } as AutoplayOptions;


  constructor(protected featuredItemsService: FeaturedItemsService
  ) {
    super(featuredItemsService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Convert the featured items observable structure into an observable of an array of items
    this.clientFeaturedItems$ = this.featuredItems$.pipe(
      switchMap((featuredItems: Observable<RemoteData<Item>>[]) => {
        return combineLatest(
          featuredItems.map(itemRD$ => itemRD$.pipe(getFirstCompletedRemoteData())
          ));
      }),
      map((featuredItems: RemoteData<Item>[]) => {
        return featuredItems.filter((rd) => rd.isSuccess && hasValue(rd.payload))
          .map((rd) => rd.payload);
      })
    );
    this.loaded$ = this.clientFeaturedItems$.pipe(
      map(() => true),
    );
  }

  getTitle(item: Item): string {
    // todo: integrate MultilingualMetadataService
    return Metadata.firstValue(item.metadata, 'dc.title');
  }

  getDateIssued(item: Item): string {
    return Metadata.firstValue(item.metadata, 'dc.date.issued');
  }

  getItemRoute(item: Item): string {
    return getItemPageRoute(item);
  }

  /**
   * Swiper breakpoints, see https://swiperjs.com/swiper-api#parameters
   */
  get breakpoints() {
    const buttonAllowance = 120; // leave some space for the navigation buttons

    return {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      [576 + buttonAllowance]: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      [768 + buttonAllowance]: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      [992 + buttonAllowance]: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    };
  }
}

