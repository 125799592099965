/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Directive, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Observable } from 'rxjs';
import { languageValidator } from './validator.functions';

/**
 * Directive for validating if a ngModel value is conform to the iso-639-1 standard
 */
@Directive({
  selector: '[ngModel][dsLanguageValidator]',
  // We add our directive to the list of existing validators
  providers: [
    {provide: NG_VALIDATORS, useExisting: LanguageValidator, multi: true}
  ]
})
@Injectable({providedIn: 'root'})
export class LanguageValidator implements AsyncValidator {

  /**
   * The function that checks if the form control's value is currently valid
   * @param control The FormControl
   */
  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return languageValidator()(control);
  }
}
