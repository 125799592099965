/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CkeditorService } from './ckeditor.service';
import ClassicEditorPlus from '@ckeditor/ckeditor5-atmire-cms-build';

export class CkeditorBrowserService implements CkeditorService {

  getEditor() {
    return ClassicEditorPlus;
  }

  canRenderEditor() {
    return true;
  }

}
