/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { AuthNavMenuComponent } from '../../../../../app/shared/auth-nav-menu/auth-nav-menu.component';

@Component({
  selector: 'ds-atmire-auth-nav-menu',
  styleUrls: ['../../../../../app/shared/auth-nav-menu/auth-nav-menu.component.scss'],
  templateUrl: './atmire-auth-nav-menu.component.html',
})
export class AtmireAuthNavMenuComponent extends AuthNavMenuComponent {

}
