/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuResolver } from '../app/menu.resolver';
import { SAVED_ITEM_LISTS_MODULE_PATH } from './atmire-app-routing-paths';
import { ITEM_MODULE_PATH } from '../app/item-page/item-page-routing-paths';
import { AuthBlockingGuard } from '../app/core/auth/auth-blocking.guard';
import { ServerCheckGuard } from '../app/core/server-check/server-check.guard';
import { AtmireSavedItemListFeatureAuthorizationGuard } from './atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-feature-authorization.guard';
import { CMS_MENUS_PATH, STATIC_PAGES_PATH } from './static-page/static-page-routing-paths';
import { EndUserAgreementCurrentUserGuard } from '../app/core/end-user-agreement/end-user-agreement-current-user.guard';
import { ATMIRE_CUA_MODULE_PATH } from './atmire-app-routing-paths';
import { CmsMenuGuard } from './cms-menu/cms-menu-guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthBlockingGuard],
    canActivateChild: [ServerCheckGuard],
    resolve: [MenuResolver],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      // add custom routes here
      {
        path: 'password-login',
        loadChildren: () => import('../app/login-page/login-page.module').then(m => m.LoginPageModule)
      },
      {
        path: SAVED_ITEM_LISTS_MODULE_PATH,
        loadChildren: () => import('./atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-page.module').then((m) => m.AtmireSavedItemListPageModule),
        canActivate: [AtmireSavedItemListFeatureAuthorizationGuard],
      },
      {
        path: ATMIRE_CUA_MODULE_PATH,
        loadChildren: () => import('./atmire-cua/atmire-cua.module')
          .then((m) => m.AtmireCuaModule),
        canActivate: [EndUserAgreementCurrentUserGuard]
      },
      {
        path: 'exports',
        loadChildren: () => import('./atmire-selective-export/atmire-selective-export-page/atmire-selective-export-page.module').then((m) => m.AtmireSelectiveExportPageModule)
      },
      {
        path: STATIC_PAGES_PATH,
        loadChildren: () => import('./static-page/static-page.module')
          .then((m) => m.StaticPageModule)
      },
      {
        path: CMS_MENUS_PATH,
        loadChildren: () => import('./cms-menu/cms-menu.module')
          .then((m) => m.CmsMenuModule),
        canActivate:[CmsMenuGuard]
      },
      {
        path: 'site/edit',
        loadChildren: () => import('./site-page/edit-site-page/edit-site-page.module')
          .then((m) => m.EditSitePageModule),
        canActivate: [ EndUserAgreementCurrentUserGuard ]
      },
      {
        path: 'featured-publications',
        loadChildren: () => import('./atmire-featured-items/atmire-featured-items.module')
          .then((m) => m.AtmireFeaturedItemsModule),
      },
      {
        path: ITEM_MODULE_PATH,
        loadChildren: () => import('./item-page/atmire-item-page.module')
          .then((m) => m.AtmireItemPageModule),
        canActivate: [EndUserAgreementCurrentUserGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtmireAppRoutingModule {
}
