<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="container">
  <ds-paginated-drag-and-drop-item-parent-metadata-list *ngIf="site$ | async"
                                                        [parent]="site$ | async"
                                                        [dataService]="siteService"
                                                        [metadataField]="featuredPublicationsMetadataField"
                                                        [orderable]="true"
                                                        [deleteEnabled]="true"
                                                        [listUrl]="listUrl"
                                                        [msgPrefix]="'featured-publications.manage.'"
                                                        [context]="context">
  </ds-paginated-drag-and-drop-item-parent-metadata-list>
  <button class="mt-2 btn btn-primary" (click)="submit()">{{ 'featured-publications.manage.save' | translate }}</button>
</div>
