/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Context } from 'src/app/core/shared/context.model';
import { ViewMode } from 'src/app/core/shared/view-mode.model';
import { CommunitySearchResult } from 'src/app/shared/object-collection/shared/community-search-result.model';
import {
  listableObjectComponent
} from 'src/app/shared/object-collection/shared/listable-object/listable-object.decorator';
import {
  CommunitySidebarSearchListElementComponent as BaseComponent
} from '../../../../../themes/atmire/app/shared/object-list/sidebar-search-list-element/community/community-sidebar-search-list-element.component';

@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, Context.ScopeSelectorModal)
@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, Context.ScopeSelectorModalCurrent)
@Component({
  selector: 'ds-community-scope-search-list-element',
  templateUrl: '../sidebar-search-list-element.component.html',
})
export class CommunityScopeSearchListElementComponent extends BaseComponent {
}
