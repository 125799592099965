<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="card shadow rounded-0 border-left-0 border-bottom-0 border-right-0 featured-items-border featured-items-border-{{index%3}}">
    <a [routerLink]="[itemPageRoute]" class="text-decoration-none">
        <div *ngIf="largeThumbnail" class="card-img-top full-width">
            <div>
                <ds-thumbnail [thumbnail]="thumbnail$ | async" [limitWidth]="false">
                </ds-thumbnail>
            </div>
        </div>
        <div class="card-body text-dark">
            <div class="row">
                <ng-container *ngVar="(thumbnail$ | async) as thumbnail">
                    <div *ngIf="!largeThumbnail && thumbnail" class="col-auto thumbnail pr-0">
                        <ds-thumbnail [thumbnail]="thumbnail"></ds-thumbnail>
                    </div>
                    <ng-container *ngIf="largeThumbnail || !thumbnail">
                        <ng-container *ngVar="(primaryBitstream$ | async) as bitstream">
                            <div *ngIf="bitstream" class="col-auto thumbnail pr-0">
                                <ds-file-icon-thumbnail
                                        [bitstreamFormat]="(bitstream?.format | async)?.payload"></ds-file-icon-thumbnail>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="col title-card-section mb-1">
                    <p class="card-clamp title-clamp-3 featured-items-titles font-weight-bold text-primary m-0"
                       [innerHTML]="object.firstMetadataValue('dc.title')"></p>

                </div>
            </div>
            <div class="card-section author-card-section mb-1">
                <p *ngIf="object.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*'])"
                   class="font-italic card-clamp author-clamp-2 featured-items-authors m-0">
                    <ng-container
                            *ngFor="let author of object.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last = last">
                        <span [innerHTML]="author"></span><span *ngIf="!last">; </span>
                    </ng-container>
                </p>
            </div>

            <div class="card-text abstract-card-section mb-1">
                <p class="card-clamp abstract-clamp-4 m-0"
                   [innerHTML]="object.allMetadataValues(['dc.description.abstract'])"></p>
            </div>

            <div class="card-text date-card-section text-right">
                <p class="card-clamp m-0"
                   [innerHTML]="object.allMetadataValues(['dc.date.issued'])"></p>
            </div>
        </div>
    </a>
</div>
