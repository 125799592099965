<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-metadata-field-wrapper *ngIf="itemSourceModel" [hideIfNoTextContent]="false" [label]="'language-availability.label' | translate">
  <ds-atmire-show-more [context]="context"
                       [paginationOptions]="paginationOptions"
                       [sourceModel]="itemSourceModel"
                       (amountOfResults)="hideComponent = $event === 0"
                       [renderAsBadge]="renderAsBadge">
  </ds-atmire-show-more>
</ds-metadata-field-wrapper>
<hr>
