<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngIf="relatedItemListConfigs$ && isNotEmpty(relatedItemListConfigs$ | async)">
  <div *ngFor="let listConfig of (relatedItemListConfigs$ | async)" class="mt-3">
    <h3>{{'atmire.statistically-related-items.' + listConfig.name + '.title' | translate }}</h3>
    <p>{{'atmire.statistically-related-items.' + listConfig.name + '.intro' | translate }}</p>
    <ds-atmire-show-more [sourceModel]="configSourceModelMap.get(listConfig.name)"
                         [paginationOptions]="defaultPagination"
                         [sortOptions]="sortOptions"
                         [viewMode]="viewMode"
    ></ds-atmire-show-more>
  </div>
</ng-container>


