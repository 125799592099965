/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { LinkMenuItemComponent } from '../../../../app/shared/menu/menu-item/link-menu-item.component';
import { hasValue } from '../../../../app/shared/empty.util';
import { MenuItemType } from '../../../../app/shared/menu/initial-menus-state';
import { rendersMenuItemForType } from '../../../../app/shared/menu/menu-item.decorator';

/**
 * Component that renders a menu section of type LINK
 */
@Component({
  selector: 'ds-atmire-link-menu-item',
  templateUrl: './atmire-link-menu-item.component.html'
})
@rendersMenuItemForType(MenuItemType.LINK)
export class AtmireLinkMenuItemComponent extends LinkMenuItemComponent {

  isAbsoluteLink: boolean;



  ngOnInit(): void {
    super.ngOnInit();
    if (this.hasLink) {
      const r = new RegExp('^(?:[a-z]+:)?//', 'i');
      this.isAbsoluteLink = r.test(this.item.link);
    }
  }

  navigateToAbsoluteUrl() {
    if (hasValue(window)) {
      (window as any).open(this.item.link, '_blank');
    }
  }
}
