/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { BrowseByDataType, rendersBrowseBy } from '../../../../../app/browse-by/browse-by-switcher/browse-by-decorator';
import {
  BrowseByMetadataPageComponent as AtmireBaseComponent
} from '../../../../atmire/app/browse-by/browse-by-metadata-page/browse-by-metadata-page.component';

/**
 * Component for browsing (items) by metadata definition
 * A metadata definition (a.k.a. browse id) is a short term used to describe one or multiple metadata fields.
 * An example would be 'author' for 'dc.contributor.*'
 */
@Component({
  selector: 'ds-browse-by-metadata-page',
  styleUrls: ['./browse-by-metadata-page.component.scss'],
  templateUrl: './browse-by-metadata-page.component.html'
})
@rendersBrowseBy(BrowseByDataType.Metadata, 'client')
export class BrowseByMetadataPageComponent extends AtmireBaseComponent {

  showRss = false;
}
