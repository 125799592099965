/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ItemDataService } from '../../../app/core/data/item-data.service';
import { Injectable } from '@angular/core';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { ITEM } from '../../../app/core/shared/item.resource-type';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { BrowseService } from '../../../app/core/browse/browse.service';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DSOChangeAnalyzer } from '../../../app/core/data/dso-change-analyzer.service';
import { Item } from '../../../app/core/shared/item.model';
import { BundleDataService } from '../../../app/core/data/bundle-data.service';
import { AtmireSavedItemListStoreService } from '../../atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { FindListOptions } from '../../../app/core/data/request.models';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import {
  createSavedItemListsProjectionArgs,
  hrefContainsSavedItemListsProjection
} from '../../atmire-saved-item-list/atmire-saved-item-list.util';


@Injectable()
@dataService(ITEM)
export class AtmireItemDataService extends ItemDataService {
  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected bs: BrowseService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<Item>,
    protected bundleService: BundleDataService,
    protected savedItemListStoreService: AtmireSavedItemListStoreService,
  ) {
    super(requestService, rdbService, store, bs, objectCache, halService, notificationsService, http, comparator, bundleService);
  }

  /**
   * Overwritten buildHrefFromFindOptions method, inserting SavedItemLists projection arguments if a list is available
   */
  public buildHrefFromFindOptions(href: string, options: FindListOptions, extraArgs: string[] = [], ...linksToFollow: FollowLinkConfig<Item>[]): string {
    return super.buildHrefFromFindOptions(href, options, [
      ...extraArgs,
      ...(hrefContainsSavedItemListsProjection(href) ? [] : createSavedItemListsProjectionArgs(...this.savedItemListStoreService.getStoredListIds()))
    ], ...linksToFollow);
  }
}
