import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DSONameService } from '../../../../core/breadcrumbs/dso-name.service';
import { Bitstream } from '../../../../core/shared/bitstream.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'ds-item-edit-bitstream-name',
  styleUrls: ['../item-bitstreams.component.scss'],
  templateUrl: './item-edit-bitstream-name.component.html',
})
/**
 * Component displaying a drag handle for the item-edit-bitstream page
 * Creates an embedded view of the contents
 * (which means it'll be added to the parents html without a wrapping ds-item-edit-bitstream-drag-handle element)
 */
export class ItemEditBitstreamNameComponent implements OnInit {

  /**
   * The bitstream of this field
   */
  @Input() bitstream: Bitstream;

  /**
   * The bitstream's name
   */
  bitstreamName: string;

  constructor(
    protected dsoNameService: DSONameService,
  ) {
  }

  ngOnInit(): void {
    this.bitstreamName = this.dsoNameService.getName(this.bitstream);
  }
}
