/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */

import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { SharingButtonConfig } from '../../config/sharing-button.config';
import { APP_CONFIG, AppConfig } from '../../../config/app-config.interface';
import { SharingButtonsConfig, SharingButtonStyle } from '../../config/sharing-buttons-config.interface';
import { NativeWindowRef, NativeWindowService } from '../../../app/core/services/window.service';
import { hasValue } from '../../../app/shared/empty.util';


interface SharingButton extends SharingButtonConfig {
  data: { [key: string]: any };
}

/**
 * Show a set of configurable sharing buttons
 * - Uses https://ellisonleao.github.io/sharer.js/ to handle URL logic and pop-ups/links
 * - Configured via {@link SharingButtonsConfig}
 */
@Component({
  selector: 'ds-sharing-buttons',
  templateUrl: './sharing-buttons.component.html',
  styleUrls: ['./sharing-buttons.component.scss']
})
export class SharingButtonsComponent implements OnInit, AfterViewInit {
  /**
   * The URL of the document to share (e.g. the permanent URI of an Item)
   */
  @Input() shareURL: string;

  /**
   * The title of the document to share
   */
  @Input() shareTitle: string;

  /**
   * The subjects of the document to share
   */
  @Input() shareDescription: string;

  /**
   * The thumbnail of the document to share (used as hashtags)
   */
  @Input() shareSubjects: string[] = [];

  /**
   * The thumbnail of the document to share
   */
  @Input() shareThumbnailURL: string;

  /**
   * Wrap the sharing buttons as an Item page field (defaults to true)
   */
  @Input() fieldWrapper = true;

  /**
   * The label to use if wrapped as an Item page field (optional)
   */
  @Input() fieldLabel: string | undefined;

  /**
   * The buttons to show, filtered on {@link SharingButtonConfig.enabled}
   */
  buttons: SharingButton[];

  private config: SharingButtonsConfig;

  style: SharingButtonStyle;

  showLabel: boolean;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    @Inject(NativeWindowService) protected window: NativeWindowRef,
  ) {}

  ngOnInit(): void {
    if (hasValue(this.appConfig.atmire?.socialSharing?.buttons)) {
      this.config = this.appConfig.atmire.socialSharing.buttons;
      this.buttons = this.config.buttons.filter(button => button.enabled)
                         .map(button => ({
                           ...button,
                           data: this.getDataAttributes(button),
                         }));
      this.style = this.config.style;

      this.showLabel = this.style === SharingButtonStyle.rectWithName;
    } else {
      console.warn(`Sharing buttons are disabled (missing from configuration: atmire.socialSharing.buttons)`);
      this.buttons = [];
    }
  }

  public ngAfterViewInit(): void {
    // sharer.js will only load client-side
    this.window.nativeWindow?.Sharer?.init();
  }

  /**
   * The data attributes to add to a specific button
   *   - To configure sharer.js itself (e.g. dimensions of popup page)
   *   - To describe the page we're sharing
   *
   * Should match the attributes used by sharer.js
   * Should be extended to cover additional platforms as necessary.
   * Custom templates for shared text can be implemented in cases for specific platforms.
   */
  protected getDataAttributes(config: SharingButtonConfig): { [key: string]: any } {
    const common = {
      // Common attributes
      width: this.config.popupWidth,
      height: this.config.popupHeight,
      link: this.config.asLink,
      blank: this.config.inNewTab,
      title: this.shareTitle,
      url: this.shareURL,

      // Platform-specific attributes

      // Twitter hashtags: comma separated subjects
      hashtags: this.shareSubjects?.join(', '),

      // Facebook hashtag: supports only one
      hashtag: this.shareSubjects?.[0],

      // Images are only supported by a few of the platforms: pinterest, vk, weibo, buffer
      image: this.shareThumbnailURL,
      picture: this.shareThumbnailURL,

      // Descriptions are only supported by a few of the platforms: whatsapp, pinterest, instapaper, blogger, trello
      description: this.shareDescription,
    };

    switch (config.platform) {
      case 'email':
        return {
          ...common,

          // sharer.js just puts the title & url in the email body.
          // to use a custom body, we use _that_ as the "title" and keep the URL empty.
          title: this.shareDescription
            ? `${this.shareTitle}\n${this.shareURL}\n\n${this.shareDescription}`
            : `${this.shareTitle}\n${this.shareURL}\n\n`,
          url: undefined,

          // always render as just a mailto: link
          link: true,
        };
      default:
        // default sharer.js behaviour
        return common;
    }
  }
}
