/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject } from '@angular/core';
import { listableObjectComponent } from '../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../app/core/shared/view-mode.model';
import { Item } from '../../../../app/core/shared/item.model';
import { AbstractListableElementComponent } from '../../../../app/shared/object-collection/shared/object-collection-element/abstract-listable-element.component';
import { Context } from '../../../../app/core/shared/context.model';
import { getItemPageRoute } from '../../../../app/item-page/item-page-routing-paths';
import { Bitstream } from '../../../../app/core/shared/bitstream.model';
import { Observable } from 'rxjs/internal/Observable';
import { getFirstSucceededRemoteDataPayload } from '../../../../app/core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { Bundle } from '../../../../app/core/shared/bundle.model';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';
import { hasValue } from '../../../../app/shared/empty.util';
import { environment } from '../../../../environments/environment';
import { APP_CONFIG, AppConfig } from '../../../../config/app-config.interface';

@listableObjectComponent('Publication', ViewMode.DetailedListElement, Context.HomePageFeatured)
@listableObjectComponent(Item, ViewMode.DetailedListElement, Context.HomePageFeatured)
@Component({
  selector: 'ds-featured-item-detailed-list-element',
  templateUrl: './featured-item-detailed-list-element.component.html',
  styleUrls: ['./featured-item-detailed-list-element.component.scss']
})
export class FeaturedItemDetailedListElementComponent extends AbstractListableElementComponent<Item> {
  itemPageRoute: string;
  thumbnail$: Observable<Bitstream>;
  primaryBitstream$: Observable<Bitstream>;
  largeThumbnail: boolean;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
  ) {
    super();
  }

  ngOnInit(): void {
    this.largeThumbnail = this.appConfig.atmire.featuredItems.largeThumbnail;
    this.itemPageRoute = getItemPageRoute(this.object);
    this.thumbnail$ = this.object.thumbnail.pipe(getFirstSucceededRemoteDataPayload());
    this.primaryBitstream$ = this.object.bundles
      .pipe(
        getFirstSucceededRemoteDataPayload(),
        map((bundles: PaginatedList<Bundle>) => bundles.page.find((bundle: Bundle) => bundle.name === 'ORIGINAL')),
        switchMap((bundle: Bundle) => {
            if (hasValue(bundle)) {
              return bundle.bitstreams.pipe(
                getFirstSucceededRemoteDataPayload(),
                //  currently primary bitstream can't be set, so take the first bitstream in the ORIGINAL bundle
                map((paginatedList: PaginatedList<Bitstream>) => paginatedList.totalElements > 0 ? paginatedList.page[0] : undefined));
            } else {
              return [undefined];
            }
          }
        )
      );
  }

}
