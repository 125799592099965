/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ObjectUpdatesAction } from '../../../../app/core/data/object-updates/object-updates.actions';
import {
  FieldState,
  OBJECT_UPDATES_TRASH_PATH,
  objectUpdatesReducer,
  ObjectUpdatesState,
  FieldStates,
  FieldUpdates,
  VirtualMetadataSources,
  Identifiable
} from '../../../../app/core/data/object-updates/object-updates.reducer';
import {
  AtmireDiscardObjectAllUpdatesAction,
  AtmireDiscardListObjectUpdatesAction,
  AtmireObjectUpdatesAction,
  AtmireObjectUpdatesActionTypes,
  AtmireAddPageToCustomOrderAction,
  AtmireInitializeFieldsAction,
  AtmireMoveFieldUpdateAction,
} from './atmire-object-updates.actions';
import { hasNoValue, hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PatchOperationService } from '../../../../app/core/data/object-updates/patch-operation-service/patch-operation.service';
import { GenericConstructor } from '../../../../app/core/shared/generic-constructor';

/**
 * A custom order given to the list of objects
 */
export interface CustomOrder {
  initialOrderPages: OrderPage[];
  newOrderPages: OrderPage[];
  pageSize: number;
  changed: boolean;
}

export interface OrderPage {
  order: string[];
}

export interface ObjectUpdatesEntry {
  fieldStates: FieldStates;
  fieldUpdates: FieldUpdates;
  virtualMetadataSources: VirtualMetadataSources;
  lastModified: Date;
  customOrder: CustomOrder;
  patchOperationService?: GenericConstructor<PatchOperationService>;
}

// Object.create(null) ensures the object has no default js properties (e.g. `__proto__`)
const initialState = Object.create(null);
const initialFieldState = { editable: false, isNew: false, isValid: true };

/**
 * Reducer method to calculate the next ObjectUpdates state, based on the current state and the ObjectUpdatesAction
 * @param state The current state
 * @param action The action to perform on the current state
 */
export function atmireObjectUpdatesReducer(state = initialState, action: AtmireObjectUpdatesAction): ObjectUpdatesState {
  switch (action.type) {
    case AtmireObjectUpdatesActionTypes.ATMIRE_DISCARD: {
      return atmireDiscardObjectUpdates(state, action as AtmireDiscardListObjectUpdatesAction);
    }
    case AtmireObjectUpdatesActionTypes.ATMIRE_DISCARD_ALL: {
      return atmireDiscardAllObjectUpdates(state, action as AtmireDiscardObjectAllUpdatesAction);
    }
    case AtmireObjectUpdatesActionTypes.ATMIRE_INITIALIZE_FIELDS: {
      return initializeFieldsUpdate(state, action as AtmireInitializeFieldsAction);
    }
    case AtmireObjectUpdatesActionTypes.ATMIRE_ADD_PAGE_TO_CUSTOM_ORDER: {
      return addPageToCustomOrder(state, action as AtmireAddPageToCustomOrderAction);
    }
    case AtmireObjectUpdatesActionTypes.ATMIRE_MOVE: {
      return moveFieldUpdate(state, action as AtmireMoveFieldUpdateAction);
    }
    default: {
      return objectUpdatesReducer(state, action as ObjectUpdatesAction);
    }
  }
}


/**
 * Discard all updates for a specific action's url in the store
 * @param state The current state
 * @param action The action to perform on the current state
 */
function atmireDiscardObjectUpdates(state: any, action: AtmireDiscardListObjectUpdatesAction) {
  action.payload.urls.forEach((url: string) => {
    const pageState: ObjectUpdatesEntry = state[url];
    const newFieldStates = {};
    Object.keys(pageState.fieldStates).forEach((uuid: string) => {
      const fieldState: FieldState = pageState.fieldStates[uuid];
      if (!fieldState.isNew) {
        /* After discarding we don't want the reset fields to stay editable or invalid */
        newFieldStates[uuid] = Object.assign({}, fieldState, {editable: false, isValid: true});
      }
    });

    const discardedPageState = Object.assign({}, pageState, {
      fieldUpdates: {},
      fieldStates: newFieldStates
    });
    state = Object.assign({}, state, {[url]: discardedPageState}, {[url + OBJECT_UPDATES_TRASH_PATH]: pageState});
  });
  return state;
}


/**
 * Discard ALL updates for a specific action's url in the store
 * @param state The current state
 * @param action The action to perform on the current state
 */
function atmireDiscardAllObjectUpdates(state: any, action: AtmireDiscardObjectAllUpdatesAction) {
  let newState = Object.assign({}, state);
  Object.keys(state).filter((path: string) => !path.endsWith(OBJECT_UPDATES_TRASH_PATH)).forEach((path: string) => {
    newState = discardObjectUpdatesFor(path, newState);
  });
  return newState;
}


/**
 * Discard all updates for a specific action's url in the store
 * @param url   The action's url
 * @param state The current state
 */
function discardObjectUpdatesFor(url: string, state: any) {
  const pageState: ObjectUpdatesEntry = state[url];
  const newFieldStates = {};
  Object.keys(pageState.fieldStates).forEach((uuid: string) => {
    const fieldState: FieldState = pageState.fieldStates[uuid];
    if (!fieldState.isNew) {
      /* After discarding we don't want the reset fields to stay editable or invalid */
      newFieldStates[uuid] = Object.assign({}, fieldState, {editable: false, isValid: true});
    }
  });

  const discardedPageState = Object.assign({}, pageState, {
    fieldUpdates: {},
    fieldStates: newFieldStates
  });
  return Object.assign({}, state, {[url]: discardedPageState}, {[url + OBJECT_UPDATES_TRASH_PATH]: pageState});
}

function createInitialFieldStates(fields: Identifiable[]) {
  const uuids = fields.map((field: Identifiable) => field.uuid);
  const fieldStates = {};
  uuids.forEach((uuid: string) => fieldStates[uuid] = initialFieldState);
  return fieldStates;
}

function initializeFieldsUpdate(state: any, action: AtmireInitializeFieldsAction) {
  const url: string = action.payload.url;
  const fields: Identifiable[] = action.payload.fields;
  const lastModifiedServer: Date = action.payload.lastModified;
  const order = action.payload.order;
  const pageSize = action.payload.pageSize;
  const page = action.payload.page;
  const patchOperationService: GenericConstructor<PatchOperationService> = action.payload.patchOperationService;
  const fieldStates = createInitialFieldStates(fields);
  const initialOrderPages = addOrderToPages([], order, pageSize, page);
  const newPageState = Object.assign(
    {},
    state[url],
    { fieldStates: fieldStates },
    { fieldUpdates: {} },
    { virtualMetadataSources: {} },
    { lastModified: lastModifiedServer },
    {
      customOrder: {
        initialOrderPages: initialOrderPages,
        newOrderPages: initialOrderPages,
        pageSize: pageSize,
        changed: false
      }
    },
    { patchOperationService }
  );
  return Object.assign({}, state, {[url]: newPageState});
}

/**
 * Add a page of objects to the state of a specific url and update a specific page of the custom order
 * @param state The current state
 * @param action The action to perform on the current state
 */
function addPageToCustomOrder(state: any, action: AtmireAddPageToCustomOrderAction) {
  const url: string = action.payload.url;
  const fields: Identifiable[] = action.payload.fields;
  const fieldStates = createInitialFieldStates(fields);
  const order = action.payload.order;
  const page = action.payload.page;
  const pageState: ObjectUpdatesEntry = state[url] || {};
  const newPageState = Object.assign({}, pageState, {
    fieldStates: Object.assign({}, pageState.fieldStates, fieldStates),
    customOrder: Object.assign({}, pageState.customOrder, {
      newOrderPages: addOrderToPages(pageState.customOrder.newOrderPages, order, pageState.customOrder.pageSize, page),
      initialOrderPages: addOrderToPages(pageState.customOrder.initialOrderPages, order, pageState.customOrder.pageSize, page)
    })
  });
  return Object.assign({}, state, {[url]: newPageState});
}

/**
 * Move an object within the custom order of a page state
 * @param state   The current state
 * @param action  The move action to perform
 */
function moveFieldUpdate(state: any, action: AtmireMoveFieldUpdateAction) {
  const url = action.payload.url;
  const fromIndex = action.payload.from;
  const toIndex = action.payload.to;
  const fromPage = action.payload.fromPage;
  const toPage = action.payload.toPage;
  const field = action.payload.field;

  const pageState: ObjectUpdatesEntry = state[url];
  const initialOrderPages = pageState.customOrder.initialOrderPages;
  const customOrderPages = [...pageState.customOrder.newOrderPages];

  // Create a copy of the custom orders for the from- and to-pages
  const fromPageOrder = [...customOrderPages[fromPage].order];
  const toPageOrder = [...customOrderPages[toPage].order];
  if (fromPage === toPage) {
    if (isNotEmpty(customOrderPages[fromPage]) && isNotEmpty(customOrderPages[fromPage].order[fromIndex]) && isNotEmpty(customOrderPages[fromPage].order[toIndex])) {
      // Move an item from one index to another within the same page
      moveItemInArray(fromPageOrder, fromIndex, toIndex);
      // Update the custom order for this page
      customOrderPages[fromPage] = {order: fromPageOrder};
    }
  } else {
    if (isNotEmpty(customOrderPages[fromPage]) && hasValue(customOrderPages[toPage]) && isNotEmpty(customOrderPages[fromPage].order[fromIndex])) {
      // Move an item from one index of one page to an index in another page
      transferArrayItem(fromPageOrder, toPageOrder, fromIndex, toIndex);
      // Update the custom order for both pages
      customOrderPages[fromPage] = {order: fromPageOrder};
      customOrderPages[toPage] = {order: toPageOrder};
    }
  }

  // Create a field update if it doesn't exist for this field yet
  let fieldUpdate = {};
  if (hasValue(field)) {
    fieldUpdate = pageState.fieldUpdates[field.uuid];
    if (hasNoValue(fieldUpdate)) {
      fieldUpdate = {field: field, changeType: undefined};
    }
  }

  // Update the store's state with new values and return
  return Object.assign({}, state, {
    [url]: Object.assign({}, pageState, {
      fieldUpdates: Object.assign({}, pageState.fieldUpdates, hasValue(field) ? {[field.uuid]: fieldUpdate} : {}),
      customOrder: Object.assign({}, pageState.customOrder, {
        newOrderPages: customOrderPages,
        changed: checkForOrderChanges(initialOrderPages, customOrderPages)
      })
    })
  });
}

/**
 * Compare two lists of OrderPage objects and return whether there's at least one change in the order of objects within
 * @param initialOrderPages The initial list of OrderPages
 * @param customOrderPages  The changed list of OrderPages
 */
function checkForOrderChanges(initialOrderPages: OrderPage[], customOrderPages: OrderPage[]) {
  let changed = false;
  initialOrderPages.forEach((orderPage: OrderPage, page: number) => {
    if (isNotEmpty(orderPage) && isNotEmpty(orderPage.order) && isNotEmpty(customOrderPages[page]) && isNotEmpty(customOrderPages[page].order)) {
      orderPage.order.forEach((id: string, index: number) => {
        if (id !== customOrderPages[page].order[index]) {
          changed = true;
          return;
        }
      });
      if (changed) {
        return;
      }
    }
  });
  return changed;
}

/**
 * Initialize a custom order page by providing the list of all pages, a list of UUIDs, pageSize and the page to populate
 * @param initialPages  The initial list of OrderPage objects
 * @param order         The list of UUIDs to create a page for
 * @param pageSize      The pageSize used to populate empty spacer pages
 * @param page          The index of the page to add
 */
function addOrderToPages(initialPages: OrderPage[], order: string[], pageSize: number, page: number): OrderPage[] {
  const result = [...initialPages];
  const orderPage: OrderPage = {order: order};
  if (page < result.length) {
    // The page we're trying to add already exists in the list. Overwrite it.
    result[page] = orderPage;
  } else if (page === result.length) {
    // The page we're trying to add is the next page in the list, add it.
    result.push(orderPage);
  } else {
    // The page we're trying to add is at least one page ahead of the list, fill the list with empty pages before adding the page.
    const emptyOrder = [];
    for (let i = 0; i < pageSize; i++) {
      emptyOrder.push(undefined);
    }
    const emptyOrderPage: OrderPage = {order: emptyOrder};
    for (let i = result.length; i < page; i++) {
      result.push(emptyOrderPage);
    }
    result.push(orderPage);
  }
  return result;
}
