/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject } from '@angular/core';
import { HomePageComponent as BaseComponent } from '../../../../app/home-page/home-page.component';
import { ActivatedRoute } from '@angular/router';
import { SiteDataService } from '../../../../app/core/data/site-data.service';
import { Site } from '../../../../app/core/shared/site.model';
import { Observable } from 'rxjs/internal/Observable';
import { SortOptions, SortDirection } from '../../../../app/core/cache/models/sort-options.model';
import { ViewMode } from '../../../../app/core/shared/view-mode.model';
import {
  AtmireSearchListableObjectSourceModel
} from '../../../../app-atmire/atmire-object-collection/listable-object-sources';
import { PaginationComponentOptions } from '../../../../app/shared/pagination/pagination-component-options.model';
import { SearchOptions } from '../../../../app/shared/search/models/search-options.model';
import { DSpaceObjectType } from 'src/app/core/shared/dspace-object-type.model';
import { StatletDataService } from '../../../../app-atmire/atmire-cua/statlets/shared/data/services/statlet-data.service';
import { StatletConfig } from '../../../../app-atmire/atmire-cua/statlets/shared/data/models/statlet-config.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { Statlet } from '../../../../app-atmire/atmire-cua/statlets/shared/data/models/statlet.model';
import { take } from 'rxjs/operators';
import { followLink } from '../../../../app/shared/utils/follow-link-config.model';
import { APP_CONFIG, AppConfig } from '../../../../config/app-config.interface';

@Component({
  selector: 'ds-home-page',
  styleUrls: ['./home-page.component.scss'],
  // styleUrls: ['../../../../app/home-page/home-page.component.scss'],
  templateUrl: './home-page.component.html'
  // templateUrl: '../../../../app/home-page/home-page.component.html'
})
export class HomePageComponent extends BaseComponent {

  siteObject$: Observable<Site> = new Observable<Site>({} as any);
  publicationsSourceModel = new AtmireSearchListableObjectSourceModel(
    new SearchOptions({
      dsoTypes: [DSpaceObjectType.ITEM],
      configuration: 'site',
      scope: this.appConfig.client.homePageRecentlyAddedScope
    }),
    true,
    { linksToFollow: [followLink('thumbnail')] },
  );
  publicationsPagination = Object.assign(new PaginationComponentOptions(), {
    pageSize: 5,
    maxSize: 3,
  });
  sortOptions = new SortOptions('fao.date.available', SortDirection.DESC);
  public ViewMode = ViewMode;

  /**
   * Statlet containing trending statistics
   */
  trendingStatletRD$: Observable<RemoteData<Statlet>>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private siteDataService: SiteDataService,
    private statletDataService: StatletDataService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    super(activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    this.siteObject$ = this.siteDataService.find();

    this.siteDataService.find().pipe(take(1)).subscribe((site) => {
      this.trendingStatletRD$ = this.statletDataService.findReport(Object.assign(new StatletConfig(), {
        dso: site.id,
        shortName: 'SiteTrendingItems7Days',
      }));      }
    );
  }
}
