/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import {
  PublicationComponent as BaseComponent
} from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';
import { Item } from 'src/app/core/shared/item.model';
import { isNotEmpty } from '../../../../../../../app/shared/empty.util';
import { addOperatorToFilterValue } from '../../../../../../../app/shared/search/search.utils';
import { FacetValue } from '../../../../../../../app/shared/search/models/facet-value.model';

/**
 * Component that represents a publication and untyped Item page
 */
@listableObjectComponent('Publication', ViewMode.StandalonePage, undefined, 'client')
@listableObjectComponent(Item, ViewMode.StandalonePage, undefined, 'client')
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './publication.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends BaseComponent {

  showAltmetricBadge: boolean;
  dcIdentifierUri: string;
  agrovocValues: { value: string, filter: string}[] = new Array();

  socialShareUrl: string;

  ngOnInit(): void {
    super.ngOnInit();
    document.querySelector('ds-item-page > div').classList.remove('container');
    this.showAltmetricBadge = this.object.firstMetadataValue('fao.altmetricbadge')?.toLowerCase() === 'yes';
    this.dcIdentifierUri = this.findUri();
    this.socialShareUrl = this.getSocialShareUrl();
    for (const mdValue of this.object.allMetadata('fao.subject.agrovoc')) {
      this.agrovocValues.push({value: mdValue.value, filter: addOperatorToFilterValue(mdValue.authority, 'authority', { value: mdValue.value } as FacetValue)});
    }
  }

  /**
   * Find a URI containing handle
   * If none is found, return the first URI in the list
   */
  findUri(): string {
    const uris = this.object.allMetadataValues('dc.identifier.uri');
    if (isNotEmpty(uris)) {
      const handleUri = uris.find((uri) => uri.indexOf('/handle/') > 0);
      if (isNotEmpty(handleUri)) {
        return handleUri;
      } else {
        return uris[0];
      }
    } else {
      return undefined;
    }
  }

  parseYear(date: string): number {
    return new Date(Date.parse(date)).getUTCFullYear();
  }

  parseLineBreaks(s: string): string {
    return s?.replace(new RegExp('\n', 'g'), '<br>');
  }

  private getSocialShareUrl() {
    const urls = this.object.allMetadataValues(['dc.identifier.uri']);
    return urls.find(str => str.includes('handle'));
  }
}
