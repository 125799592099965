/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { BrowseByDataType, rendersBrowseBy } from '../../../../../app/browse-by/browse-by-switcher/browse-by-decorator';
import { BrowseByDatePageComponent as BaseComponent } from '../../../../../app/browse-by/browse-by-date-page/browse-by-date-page.component';

@Component({
  selector: 'ds-browse-by-date-page',
  styleUrls: ['../../../../../app/browse-by/browse-by-metadata-page/browse-by-metadata-page.component.scss'],
  templateUrl: '../browse-by-metadata-page/browse-by-metadata-page.component.html'
})
@rendersBrowseBy(BrowseByDataType.Date, 'client')
/**
 * The contents of this themed-component are equal to that of themed {@link BrowseByMetadataPageComponent}
 * This is because this component needs access to both the modifications of its own BaseComponent, as well as
 * modifications of the themed version of its parent component.
 * Since we can't extend two classes, it's inevitable we need to copy modifications of one of the two.
 */
export class BrowseByDatePageComponent extends BaseComponent {

  showRss = true;
}
