/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  listableObjectComponent
} from '../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../app/core/shared/view-mode.model';
import { Item } from '../../../../app/core/shared/item.model';
import { Component, OnInit } from '@angular/core';
import { Context } from '../../../../app/core/shared/context.model';
import { Observable } from 'rxjs/internal/Observable';
import { Bitstream } from '../../../../app/core/shared/bitstream.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { ItemSearchResultListElementComponent } from '../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { ItemSearchResult } from '../../../../app/shared/object-collection/shared/item-search-result.model';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, Context.FeaturedPublications)
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, Context.FeaturedPublications)
@listableObjectComponent('Publication', ViewMode.ListElement, Context.FeaturedPublications)
@listableObjectComponent(Item, ViewMode.ListElement, Context.FeaturedPublications)
@Component({
  selector: 'ds-manage-featured-publications-list-element',
  styleUrls: ['./manage-featured-publications-list-element.component.scss'],
  templateUrl: './manage-featured-publications-list-element.component.html'
})
/**
 * A list element displaying a single featured publication
 * Displays a thumbnail
 */
export class ManageFeaturedPublicationsListElementComponent extends ItemSearchResultListElementComponent implements OnInit {
  /**
   * The thumbnail of the item
   */
  thumbnailRD$: Observable<RemoteData<Bitstream>>;

  ngOnInit(): void {
    super.ngOnInit();
    this.thumbnailRD$ = this.dso.thumbnail;
  }
}
