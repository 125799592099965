/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { fadeIn, fadeInOut } from '../../../app/shared/animations/fade';
import { BrowseByComponent } from '../../../app/shared/browse-by/browse-by.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'ds-client-browse-by',
  styleUrls: ['./client-browse-by.component.scss'],
  templateUrl: './client-browse-by.component.html',
  animations: [
    fadeIn,
    fadeInOut
  ]
})
/**
 * Component to display a browse-by page for any ListableObject
 */
export class ClientBrowseByComponent extends BrowseByComponent {

  /**
   * Whether or not the RSS button should be shown
   */
  @Input() showRss = false;

  getTranslation(key: string): Observable<string> {
    return this.translate.instant(key);
  }

}
