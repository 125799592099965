/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ThemedRootComponent } from '../../app/root/themed-root.component';

@Component({
  selector: 'ds-atmire-themed-root',
  styleUrls: [],
  templateUrl: '../../app/shared/theme-support/themed.component.html',
})
export class AtmireThemedRootComponent extends ThemedRootComponent {


}
