/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { ViewMode } from '../../../../../../app/core/shared/view-mode.model';
import { CommunitySearchResult } from '../../../../../../app/shared/object-collection/shared/community-search-result.model';
import {
  listableObjectComponent
} from '../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import {
  CommunitySearchResultListElementComponent as BaseComponent
} from '../../../../../../app/shared/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component';
import { Context } from '../../../../../../app/core/shared/context.model';
import { TruncatableService } from '../../../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../../../app/core/breadcrumbs/dso-name.service';
import { MultilingualMetadataService } from '../../../../../../app-atmire/core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-community-search-result-list-element',
  styleUrls: [
    '../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss',
    '../../../../../../app/shared/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component.scss',
    'community-search-result-list-element.component.scss',
  ],
  templateUrl: 'community-search-result-list-element.component.html',
})
@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, Context.Any, 'atmire')
@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, Context.DsoSelector, 'atmire')
export class CommunitySearchResultListElementComponent extends BaseComponent implements OnInit {

  description: string;

  constructor(
    protected truncatableService: TruncatableService,
    protected dsoNameService: DSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(
      truncatableService,
      dsoNameService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.description = this.multilingualMetadataService.firstValueCurrentLang(this.dso.metadata, 'dc.description.abstract');
  }

}
