/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  ExpandableNavbarSectionComponent as BaseComponent
} from '../../../../../app/navbar/expandable-navbar-section/expandable-navbar-section.component';
import { slide } from '../../../../../app/shared/animations/slide';
import { rendersSectionForMenu } from '../../../../../app/shared/menu/menu-section.decorator';
import { MenuID } from '../../../../../app/shared/menu/initial-menus-state';

/**
 * Represents an expandable section in the navbar
 */
@Component({
  /* tslint:disable:component-selector */
  selector: 'li[ds-expandable-navbar-section]',
  templateUrl: './expandable-navbar-section.component.html',
  // templateUrl: '../../../../../app/navbar/expandable-navbar-section/expandable-navbar-section.component.html',
  styleUrls: ['./expandable-navbar-section.component.scss'],
  // styleUrls: ['../../../../../app/navbar/expandable-navbar-section/expandable-navbar-section.component.scss'],
  animations: [slide]
})
@rendersSectionForMenu(MenuID.PUBLIC, true)
export class ExpandableNavbarSectionComponent extends BaseComponent {
}
