/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { ViewMode } from '../../../../../app/core/shared/view-mode.model';
import { SearchService } from '../../../../../app/core/shared/search/search.service';
import { PaginatedList } from '../../../../../app/core/data/paginated-list.model';
import { getAllCompletedRemoteData } from '../../../../../app/core/shared/operators';
import { PaginatedSearchOptions } from '../../../../../app/shared/search/models/paginated-search-options.model';
import { SearchResult } from '../../../../../app/shared/search/models/search-result.model';
import { Context } from '../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-scope-selector',
  styleUrls: ['./scope-selector.component.scss'],
  templateUrl: './scope-selector.component.html'
})

/**
 * Component to render a list of DSO's, that will represent the scope object of a static page,
 * of which one can be selected
 * The user can search the list by using the input field
 */
export class ScopeSelectorComponent implements OnInit {

  /**
   * The initially selected DSO's uuid
   */
  @Input() currentDSOId: string;

  /**
   * Emits the selected Object when a user selects it in the list
   */
  @Output() onSelect: EventEmitter<DSpaceObject> = new EventEmitter();

  /**
   * Input form control to query the list
   */
  public input: FormControl = new FormControl();

  /**
   * Default pagination for this feature
   */
  private defaultPagination = {id: 'scope-selector', currentPage: 1, pageSize: 5} as any;

  /**
   * List with search results of DSpace objects for the current query
   */
  listEntries$: Observable<RemoteData<PaginatedList<SearchResult<DSpaceObject>>>>;
  /**
   * The view mode of the listed objects
   */
  viewMode = ViewMode.ListElement;

  /**
   * List of element references to all elements
   */
  @ViewChildren('listEntryElement') listElements: QueryList<ElementRef>;

  /**
   * Time to wait before sending a search request to the server when a user types something
   */
  debounceTime = 500;

  context = Context.SideBarSearchModal;

  constructor(private searchService: SearchService) {
  }

  /**
   * Fills the listEntries$ variable with search results based on the input field's current value
   * The search will always start with the initial currentDSOId value
   */
  ngOnInit(): void {
    this.input.setValue(this.currentDSOId);
    this.listEntries$ = this.input.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        startWith(this.currentDSOId),
        switchMap((query) => {
            return this.searchService.search(
              new PaginatedSearchOptions({
                query: query,
                pagination: this.defaultPagination
              })
            );
          }
        ),
        getAllCompletedRemoteData()
      );
  }

  /**
   * Set focus on the first list element when there is only one result
   */
  selectSingleResult(): void {
    if (this.listElements.length > 0) {
      this.listElements.first.nativeElement.click();
    }
  }
}
