/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { PaginatedList } from '../../../../../app/core/data/paginated-list.model';
import { Statlet } from '../data/models/statlet.model';
import { StatletDataService } from '../data/services/statlet-data.service';
import { SearchResult } from '../../../../../app/shared/search/models/search-result.model';
import { StatletPosition } from '../data/models/statlet-position.model';
import { getFirstSucceededRemoteDataPayload } from '../../../../../app/core/shared/operators';
import { map } from 'rxjs/operators';
import { ensureArrayHasValue, hasValue } from '../../../../../app/shared/empty.util';
import { hasStatletData } from '../data/models/statlet.utils';
import { ListableObject } from '../../../../../app/shared/object-collection/shared/listable-object.model';
import { ItemSearchResult } from '../../../../../app/shared/object-collection/shared/item-search-result.model';
import { AtmireSearchConfigurationService as SearchConfigurationService } from '../../../../core/shared/search/atmire-search-configuration.service';

@Component({
  selector: 'ds-search-result-statlet',
  templateUrl: './search-result-statlet.component.html'
})
/**
 * Component for the statistics statlet(s) in the list view components
 */
export class SearchResultStatletComponent<T extends ListableObject> implements OnInit, OnDestroy {

  /**
   * The object of the list element
   */
  @Input() object: T;

  /**
   * Separator string between multiple points of the statlet
   * @type {string}
   */
  @Input() separator: string;

  /**
   * The statlets to display for the object in the list view
   */
  statisticsStatletRD$: Observable<RemoteData<PaginatedList<Statlet>>>;

  /**
   * Does any of the statlets have any data?
   */
  hasData$: Observable<boolean>;

  /**
   * Is show statistics setting selected in search?
   */
  shouldShowStatistics$: Observable<boolean>;

  /**
   * The list of Subscription
   * @type {Array}
   */
  protected subs: Subscription[] = [];

  constructor(protected statletService: StatletDataService,
              protected searchConfigurationService: SearchConfigurationService) {
  }

  ngOnInit(): void {
    this.shouldShowStatistics$ = this.searchConfigurationService.shouldShowStatistics();
    this.subs.push(
      this.shouldShowStatistics$.subscribe((shouldShowStatistics: boolean) => {
        if (shouldShowStatistics) {
          if (this.object instanceof SearchResult || this.object instanceof ItemSearchResult) {
            const selfLink = this.object._links.indexableObject.href;
            this.statisticsStatletRD$ = this.statletService.searchByObject(selfLink, StatletPosition.SearchResults);
            this.hasData$ = this.getHasData(this.statisticsStatletRD$);
          }
        }
      })
    );
  }

  private getHasData(rd: Observable<RemoteData<PaginatedList<Statlet>>>): Observable<boolean> {
    return rd.pipe(
      getFirstSucceededRemoteDataPayload(),
      map((statlets) => statlets.page),
      ensureArrayHasValue(),
      map((statlets) => statlets.some(hasStatletData))
    );
  }

  /**
   * Unsubscribe from all open subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
