/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { HrefOnlyDataService } from '../../../app/core/data/href-only-data.service';
import { SearchService } from '../../../app/core/shared/search/search.service';
import { ListableObjectSource, ListableObjectSourceModel, ListableObjectSourceType } from '../listable-object-sources';
import { AtmireHrefListableObjectSource } from './atmire-href-listable-object-source';
import { AtmireSearchListableObjectSource } from './atmire-search-listable-object-source';
import { AtmireIndividualHrefListableObjectSource } from './atmire-individual-href-listable-object-source';

@Injectable()
/**
 * Resolves {@link ListableObjectSourceModel} objects into instances
 * of the corresponding {@link ListableObjectSource} implementation.
 */
export class ListableObjectSourceFactoryService {
  constructor(
    private hrefOnlyDataService: HrefOnlyDataService,
    private searchService: SearchService,
  ) {
  }

  /**
   * Get a {@link ListableObjectSource} instance based on a {@link ListableObjectSourceModel}
   * @param model
   */
  getSource(model: ListableObjectSourceModel): ListableObjectSource {
    switch (model.source) {
      case ListableObjectSourceType.HREF: {
        return new AtmireHrefListableObjectSource(model, this.hrefOnlyDataService);
      }
      case ListableObjectSourceType.SEARCH: {
        return new AtmireSearchListableObjectSource(model, this.searchService);
      }
      case ListableObjectSourceType.INDIVIDUAL_HREF: {
        return new AtmireIndividualHrefListableObjectSource(model, this.hrefOnlyDataService);
      }
    }
  }
}
