/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { HomeNewsComponent as BaseComponent } from '../../../../../app/home-page/home-news/home-news.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Site } from '../../../../../app/core/shared/site.model';
import { StaticPageContentObject } from '../../../../../app-atmire/static-page/static-page-content-object.model';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { StaticPageDataService } from '../../../../../app-atmire/core/data/static-page-data.service';
import { StaticPageSearchOptions } from '../../../../../app-atmire/static-page/static-page-search-options.model';
import { hasValue } from '../../../../../app/shared/empty.util';

@Component({
  selector: 'ds-home-news',
  // styleUrls: ['./home-news.component.scss'],
  styleUrls: ['../../../../../app/home-page/home-news/home-news.component.scss'],
  templateUrl: './home-news.component.html'
  // templateUrl: '../../../../../app/home-page/home-news/home-news.component.html'
})
export class HomeNewsComponent extends BaseComponent {

  site$: Observable<Site>;
  homepageNewsRD$: Observable<RemoteData<StaticPageContentObject>>;

  constructor(
    private route: ActivatedRoute,
    private staticPageDataService: StaticPageDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.site$ = this.route.data.pipe(
      map((data) => data.site as Site),
    );
    this.homepageNewsRD$ = this.route.data.pipe(
      map((data) => data.news as RemoteData<StaticPageContentObject>),
    );
  }

}
