/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import {
  SingleFeatureAuthorizationGuard
} from '../app/core/data/feature-authorization/feature-authorization-guard/single-feature-authorization.guard';
import { AuthorizationDataService } from '../app/core/data/feature-authorization/authorization-data.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../app/core/auth/auth.service';
import { Observable, of as observableOf } from 'rxjs';
import { FeatureID } from '../app/core/data/feature-authorization/feature-id';
import { map } from 'rxjs/operators';
import { HALEndpointService } from '../app/core/shared/hal-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class ViewDsoPageGuard extends SingleFeatureAuthorizationGuard {

  itemsEndpoint = 'items';

  constructor(protected halEndpointService: HALEndpointService,
              protected authorizationService: AuthorizationDataService,
              protected router: Router,
              protected authService: AuthService) {
    super(authorizationService, router, authService);
  }


  getFeatureID(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FeatureID> {
    return observableOf(FeatureID.CanViewDSOPage);
  }


  getObjectUrl(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    return this.halEndpointService.getEndpoint(this.itemsEndpoint).pipe(
      map(itemsUrl => `${itemsUrl}/${route?.params?.id}`)
    );
  }
}
