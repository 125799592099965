/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Config } from '../../config/config.interface';
import { SharingButtonConfig } from './sharing-button.config';


export enum SharingButtonStyle {
  squareIconOnly = 'squareIconOnly',
  rectWithName = 'rectWithName',
}

/**
 * Configuration for sharing buttons ~ https://ellisonleao.github.io/sharer.js/
 */
export interface SharingButtonsConfig extends Config {
  /**
   * The style of the social sharing buttons
   */
  style: SharingButtonStyle;

  /**
   * The buttons to show
   */
  buttons: SharingButtonConfig[];

  /**
   * The width of the popup (only used when {@link asLink} is set to false)
   */
  popupWidth: number;

  /**
   * The width of the popup (only used when {@link asLink} is set to false)
   */
  popupHeight: number;

  /**
   * Whether to handle buttons as links (no href) instead of opening a popup window
   */
  asLink: boolean;

  /**
   * Whether to open links in a new tab (only used when {@link asLink} is set to true)
   */
  inNewTab: boolean;
}
