<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(extensionBitstreamPairs$ | async) as extensionBitstreamPairs">
  <ds-file-download-link *ngFor="let extensionAndFile of extensionBitstreamPairs; let last=last;"
                         [bitstream]="extensionAndFile[1]" [isBlank]="true" [item]="item">
    <button [ngbTooltip]="extensionAndFile[1]?.name" class="btn btn-primary border-0 text-left py-2 mb-2 w-100">
      <i [class]="icon"></i>
      <span>{{ getTranslation(text, extensionAndFile[0]) }} </span>
    </button>
  </ds-file-download-link>
</ng-container>
