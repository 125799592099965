/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StaticPageWrapperComponent } from '../static-page-wrapper.component';
import { DSpaceObject } from '../../../../../../app/core/shared/dspace-object.model';
import { StaticPageContentObject } from '../../../../../static-page/static-page-content-object.model';
import { DSpaceObjectDataService } from '../../../../../../app/core/data/dspace-object-data.service';
import { RemoteData } from '../../../../../../app/core/data/remote-data';
import { getFirstSucceededRemoteData } from '../../../../../../app/core/shared/operators';
import {
  SelectorActionType
} from '../../../../../../app/shared/dso-selector/modal-wrappers/dso-selector-modal-wrapper.component';
import { hasValue } from '../../../../../../app/shared/empty.util';

/**
 * Component to add support to select a static page based on a selected scopeDSO or based on the currently
 * displayed static page
 * Used to edit an existing static page
 */

@Component({
  selector: 'ds-edit-static-page-wrapper',
  styleUrls: ['./edit-static-page-wrapper.component.scss'],
  templateUrl: './edit-static-page-wrapper.component.html',
})
export class EditStaticPageWrapperComponent extends StaticPageWrapperComponent {

  action = SelectorActionType.EDIT;

  scopeSelect = true;

  constructor(protected activeModal: NgbActiveModal, protected route: ActivatedRoute, protected dsoService: DSpaceObjectDataService) {
    super(activeModal, route, dsoService);
  }

  ngOnInit(): void {
    this.currentObjectRD = this.getCurrentObject();

    this.currentObjectRD = this.getCurrentObject();
    if (hasValue(this.currentObjectRD)) {
      const currentObject = this.currentObjectRD.payload;
      if (currentObject instanceof StaticPageContentObject) {
        this.currentStaticPage = currentObject as StaticPageContentObject;
        this.dsoService.findById(this.currentStaticPage.dspaceobjectUuid).pipe(getFirstSucceededRemoteData()).subscribe((remoteData: RemoteData<DSpaceObject>) => {
            this.currentDSpaceObject = remoteData.payload;
          }
        );
        this.scopeSelect = false;
      } else if (currentObject instanceof DSpaceObject) {
        this.currentDSpaceObject = currentObject as DSpaceObject;
      }
    }
  }

  /**
   * Select the static page scope
   * @param dso - DSO that represents the static page scope
   */
  selectScope(dso: DSpaceObject) {
    this.currentDSpaceObject = dso;
    this.scopeSelect = false;
  }

  /**
   * Navigate back to the scope select step
   */
  back() {
    this.scopeSelect = true;

    // reset static page when clicking back
    this.currentStaticPage = null;
  }
}
