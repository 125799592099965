<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-themed-thumbnail [limitWidth]="false"
                     [thumbnail]="dso.thumbnail | async">
</ds-themed-thumbnail>

<div>
  <ds-atmire-saved-item-list-control [item]="dso" class="float-right"></ds-atmire-saved-item-list-control>

  <div *ngIf="showLabel && dso.firstMetadataValue('dc.type') as type">
    <span class="item-list-type text-muted">{{ type }}</span>
  </div>

  <ds-selective-truncatable *ngIf="object !== undefined && object !== null" [id]="dso.id">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
       rel="noopener noreferrer"
       [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out text-primary"
       [innerHTML]="dsoTitle"></a>
    <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out text-primary"
          [innerHTML]="dsoTitle"></span>
    <h6 *ngIf="dso.firstMetadataValue('dc.title.subtitle') as subTitle" class="mt-1">
      {{ subTitle }}
    </h6>
    <span class="flex-group text-muted d-block mt-1">
      <span *ngIf="dso.firstMetadataValue('dc.date.issued') as dateIssued" class="item-list-date py-1"
            [innerHTML]="dateIssued | date:'y'">
      </span>
      <ds-language-availability [item]="dso" [pageSize]="5" [renderAsBadge]="true">
      </ds-language-availability>
    </span>
    <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract mt-4">
      <ds-truncatable-part [id]="dso.id" [minLines]="3" background="gray-100"><span
        [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
      </ds-truncatable-part>
    </div>
      <div class="pt-1">
        <ds-search-result-statlet [object]="object"
                                  [separator]="'; '">
        </ds-search-result-statlet>
      </div>
  </ds-selective-truncatable>
</div>
