/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { TypedObject } from '../../../../app/core/cache/object-cache.reducer';
import { NotificationType } from '../../../../app/shared/notifications/models/notification-type';
import {
  ListableNotificationObject
} from '../../../../app/shared/object-list/listable-notification-object/listable-notification-object.model';

/**
 * Object representing a notification message with a UUID inside a list of objects
 */
@typedObject
export class IdentifiableListableNotificationObject extends ListableNotificationObject implements TypedObject {

  constructor(
    public notificationType: NotificationType = NotificationType.Error,
    public message: string = 'identifiable-listable-notification-object.default-message',
    public uuid: string,
    ...renderTypes: string[]
  ) {
    super(notificationType, message, ...renderTypes);
  }

}
