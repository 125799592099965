/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PatchRequest } from '../../../app/core/data/request.models';
import { ApplyPatchObjectCacheAction } from '../../../app/core/cache/object-cache.actions';
import { isNotEmpty } from '../../../app/shared/empty.util';
import { ObjectCacheEntry } from '../../../app/core/cache/object-cache.reducer';
import { Operation } from 'fast-json-patch';
import { ServerSyncBufferEffects } from '../../../app/core/cache/server-sync-buffer.effects';

@Injectable()
export class AtmireServerSyncBufferEffects extends ServerSyncBufferEffects {
  /**
   * private method to create an ApplyPatchObjectCacheAction based on a cache entry
   * and to do the actual patch request to the server
   * @param {string} href The self link of the cache entry
   * @returns {Observable<Action>} ApplyPtchObjectCacheAction to be dispatched
   */
  protected applyPatch(href: string): Observable<Action> {
    const patchObject = this.objectCache.getByHref(href).pipe(
      take(1)
    );

    return patchObject.pipe(
      map((entry: ObjectCacheEntry) => {
        if (isNotEmpty(entry.patches)) {
          const flatPatch: Operation[] = [].concat(...entry.patches.map((patch) => patch.operations));
          if (isNotEmpty(flatPatch)) {
            this.requestService.send(new PatchRequest(this.requestService.generateRequestId(), href, flatPatch));
          }
        }
        return new ApplyPatchObjectCacheAction(href);
      })
    );
  }
}
