/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  AbstractListableElementComponent
} from '../../../../../../app/shared/object-collection/shared/object-collection-element/abstract-listable-element.component';
import { Item } from '../../../../../../app/core/shared/item.model';
import {
  listableObjectComponent
} from '../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../app/core/shared/view-mode.model';
import { Context } from '../../../../../../app/core/shared/context.model';
import { DSONameService } from '../../../../../../app/core/breadcrumbs/dso-name.service';
import { getItemPageRoute } from '../../../../../../app/item-page/item-page-routing-paths';

/**
 * Custom way to display the items language version
 */
@listableObjectComponent(Item, ViewMode.ListElement, Context.LanguageLink)
@Component({
  selector: 'ds-language-link-list-element-component',
  templateUrl: './language-link-list-element-component.component.html',
  styleUrls: ['./language-link-list-element-component.component.scss'],
})
export class LanguageLinkListElementComponentComponent extends AbstractListableElementComponent<Item> {

  getItemPageRoute = getItemPageRoute;

  constructor(
    public dsoNameService: DSONameService,
  ) {
    super();
  }

}
