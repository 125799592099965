/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommunityPageResolver } from '../../app/community-page/community-page.resolver';
import { CommunityBreadcrumbResolver } from '../../app/core/breadcrumbs/community-breadcrumb.resolver';
import { HomePageNewsResolver } from '../shared/news/home-page-news.resolver';
import { COMMUNITY_EDIT_PATH } from '../../app/community-page/community-page-routing-paths';
import { CommunityPageAdministratorGuard } from '../../app/community-page/community-page-administrator.guard';
import {
  DeleteCommunityPageComponent
} from '../../app/community-page/delete-community-page/delete-community-page.component';
import { AuthenticatedGuard } from '../../app/core/auth/authenticated.guard';
import { ThemedCommunityPageComponent } from '../../app/community-page/themed-community-page.component';
import { MenuItemType } from '../../app/shared/menu/initial-menus-state';
import { LinkMenuItemModel } from '../../app/shared/menu/menu-item/models/link.model';
import { ThemedHomePageComponent } from '../../app/home-page/themed-home-page.component';
import { HomePageResolver } from '../../app/home-page/home-page.resolver';

const routes: Routes = [
  {
    path: '',
    component: ThemedHomePageComponent,
    pathMatch: 'full',
    data: {
      title: 'home.title',
      menu: {
        public: [],
      },
    },
    resolve: {
      site: HomePageResolver,
      news: HomePageNewsResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[
    HomePageNewsResolver,
  ]
})
export class AtmireHomePageRoutingModule { }
