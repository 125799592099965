<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="inline-statlet-wrapper d-inline-block" [style.max-width.px]="width" [style.max-height.px]="height" [attr.graphtype]="statlet.graphtype.id" #wrapper [hidden]="!statlet.graphtype || !hasData">
  <ds-atmire-cua-single-statlet-graph-loader [statlet]="statlet"
                                             [view]="[wrapper.offsetWidth, wrapper.offsetHeight]"
                                             [context]="context"
                                             [palette]="palette"
                                             [size]="size"></ds-atmire-cua-single-statlet-graph-loader>
</div>
