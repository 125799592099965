/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { Item } from '../../../../../app/core/shared/item.model';
import { PaginationComponentOptions } from '../../../../../app/shared/pagination/pagination-component-options.model';
import { PaginatedSearchOptions } from '../../../../../app/shared/search/models/paginated-search-options.model';
import { SearchOptions } from '../../../../../app/shared/search/models/search-options.model';
import { Context } from '../../../../../app/core/shared/context.model';
import { AtmireSearchListableObjectSourceModel } from '../../../../../app-atmire/atmire-object-collection/listable-object-sources';
import {
  SortOptions,
  SortDirection
} from '../../../../../app/core/cache/models/sort-options.model';

export const JOB_NUMBER_FIELD = 'fao.identifier.jobnumber';

/**
 * This component renders a list of items that are each others other language version.
 */
@Component({
  selector: 'ds-language-availability',
  templateUrl: './language-availability.component.html',
  styleUrls: ['./language-availability.component.scss'],
  preserveWhitespaces: false,
})
export class LanguageAvailabilityComponent implements OnInit {

  @Input() item: Item;

  @Input() pageSize = 5;

  @Input() renderAsBadge: boolean;

  @HostBinding('class.d-none') hideComponent = false;

  paginationOptions: PaginationComponentOptions = new PaginationComponentOptions();

  context: Context = Context.LanguageLink;

  searchOptions: SearchOptions;

  itemSourceModel: AtmireSearchListableObjectSourceModel<Item>;

  ngOnInit(): void {
    this.paginationOptions.pageSize = this.pageSize;

    if (this.item.hasMetadata(JOB_NUMBER_FIELD)) {
      const jobNumber = this.item.firstMetadataValue(JOB_NUMBER_FIELD);
      const id: string = jobNumber.match(/(([A-Za-z]+)?\d+)/)[0];
      const idLowerCased = id.toLowerCase();
      const idUpperCased = id.toUpperCase();

      this.searchOptions = Object.assign(new PaginatedSearchOptions({}), {}, {
        query: `(${JOB_NUMBER_FIELD}_keyword:${idLowerCased}* OR ${JOB_NUMBER_FIELD}_keyword:${idUpperCased}*) -${JOB_NUMBER_FIELD}_keyword:${jobNumber} AND archived:true`,
        pagination: this.paginationOptions,
        sort: new SortOptions('dc.language.iso', SortDirection.ASC),
        configuration: 'item'
      });

      this.itemSourceModel = new AtmireSearchListableObjectSourceModel(
        this.searchOptions,
        false,
      );
    }
  }

}
