import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-my-dspace-view-button',
  styleUrls: ['./my-dspace-view-button.component.scss'],
  templateUrl: './my-dspace-view-button.component.html'
})
export class MyDSpaceViewButtonComponent {

  @Input() marginClasses: string;

  @Input() viewLink: string;

}
