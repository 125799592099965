<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="(homepageNewsRD$ |async)?.payload" class="jumbotron jumbotron-fluid">
    <div class="container">
        <div *ngIf="(homepageNewsRD$ |async)?.payload?.contentString !== undefined; else noContent" class="d-flex ck-content">
            <div [innerHTML]="(homepageNewsRD$ |async)?.payload?.contentString"></div>
        </div>
    </div>
</div>
<ng-template #noContent>
  <ds-error message="{{'home-page-news.static-page.error' | translate}}"></ds-error>
</ng-template>
