<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div>
    <h5 class="px-2">{{'static-pages.selector.edit.static-page' | translate}}</h5>
    <ds-static-page-selector [currentStaticPage]="currentStaticPage" [scopeDsoUuid]="(scopeDso)?.uuid"
                             (onSelect)="selectObject($event)"></ds-static-page-selector>
</div>