/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { FeaturedItemsService } from '../featured-items.service';
import { Observable } from 'rxjs/internal/Observable';
import { Item } from '../../../app/core/shared/item.model';
import { Context } from 'src/app/core/shared/context.model';
import { ViewMode } from '../../../app/core/shared/view-mode.model';
import { RemoteData } from '../../../app/core/data/remote-data';

@Component({
  selector: 'ds-featured-items',
  templateUrl: './featured-items.component.html',
  styleUrls: ['./featured-items.component.scss', '../../../app/shared/object-grid/object-grid.component.scss'],
})
export class FeaturedItemsComponent implements OnInit {
  public featuredItems$: Observable<Observable<RemoteData<Item>>[]>;
  public Context = Context;
  public ViewMode = ViewMode;
  public shouldRenderFeaturedItems$: Observable<boolean>;

  constructor(protected featuredItemsService: FeaturedItemsService) { }

  ngOnInit(): void {
    this.featuredItems$ = this.featuredItemsService.getFeaturedItems();
    this.shouldRenderFeaturedItems$ = this.featuredItemsService.shouldRenderFeaturedItems();
  }
}
