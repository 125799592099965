/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FooterComponent as BaseComponent } from '../../../../app/footer/footer.component';
import { LangConfig } from '../../../../config/lang-config.interface';
import { KlaroService } from '../../../../app/shared/cookies/klaro.service';
import { APP_CONFIG, AppConfig } from '../../../../config/app-config.interface';
import { LocaleService } from '../../../../app/core/locale/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, forkJoin } from 'rxjs';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { StaticPageContentObject } from '../../../../app-atmire/static-page/static-page-content-object.model';
import { MenuService } from '../../../../app/shared/menu/menu.service';
import { AuthService } from '../../../../app/core/auth/auth.service';
import { StaticPageDataService } from '../../../../app-atmire/core/data/static-page-data.service';
import { SiteDataService } from '../../../../app/core/data/site-data.service';
import { Site } from '../../../../app/core/shared/site.model';
import { filter, map, mergeMap } from 'rxjs/operators';
import { hasValue } from '../../../../app/shared/empty.util';
import { StaticPageSearchOptions } from '../../../../app-atmire/static-page/static-page-search-options.model';

@Component({
  selector: 'ds-footer',
  styleUrls: ['footer.component.scss'],
  // styleUrls: ['../../../../app/footer/footer.component.scss'],
  templateUrl: 'footer.component.html'
  // templateUrl: '../../../../app/footer/footer.component.html'
})
export class FooterComponent extends BaseComponent implements OnInit {
  showTopFooter = true;
  activeLanguages: string[] = [];
  currentLocale: string;
  logoRD$: Observable<RemoteData<StaticPageContentObject>>;
  followUsRD$: Observable<RemoteData<StaticPageContentObject>>;
  linksRD$: Observable<RemoteData<StaticPageContentObject>>;
  site$: Observable<Site>;

  constructor(cookies: KlaroService,
              @Inject(APP_CONFIG) protected appConfig: AppConfig,
              protected translateService: TranslateService,
              protected localeService: LocaleService,
              protected menuService: MenuService,
              protected authService: AuthService,
              protected staticPageDataService: StaticPageDataService,
              protected siteDataService: SiteDataService) {
    super(cookies);
  }

  ngOnInit(): void {
    this.activeLanguages = this.appConfig.languages
      .filter((langConfig: LangConfig) => langConfig.active === true)
      .map((langConfig: LangConfig) => langConfig.code);
    this.currentLocale = this.localeService.getCurrentLanguageCode();
    this.site$ = this.siteDataService.find();
    this.logoRD$ = this.initFooterPage('footer-logo');
    this.followUsRD$ = this.initFooterPage('footer-follow-us');
    this.linksRD$ = this.initFooterPage('footer-links');
  }

  initFooterPage(part: string): Observable<RemoteData<StaticPageContentObject>> {
    const staticPageObs = this.site$.pipe(
      filter((site) => hasValue(site)),
      map((site) => new StaticPageSearchOptions({
        uuid: site.uuid,
        name: part,
      })),
      mergeMap((staticPageSearchOptions: StaticPageSearchOptions) => this.staticPageDataService.searchStaticPages(staticPageSearchOptions)),
    );
    return this.staticPageDataService.retrieveContentFromPageListObs(staticPageObs);
  }
}
