/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { DspaceRestService } from '../../../app/core/dspace-rest/dspace-rest.service';
import { RestRequestMethod } from '../../../app/core/data/rest-request-method';
import { RawRestResponse } from '../../../app/core/dspace-rest/raw-rest-response.model';
import { hasNoValue, hasValue, isNotEmpty } from '../../../app/shared/empty.util';

export const DEFAULT_CONTENT_TYPE = 'application/json; charset=utf-8';

export interface HttpOptions {
  body?: any;
  headers?: HttpHeaders;
  params?: HttpParams;
  observe?: 'body' | 'events' | 'response';
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

/**
 * Service to access DSpace's REST API
 */
@Injectable()
export class AtmireDspaceRestService extends DspaceRestService {

  /**
   * Performs a request to the REST API.
   *
   * @param method
   *    the HTTP method for the request
   * @param url
   *    the URL for the request
   * @param body
   *    an optional body for the request
   * @param options
   *    the HttpOptions object
   * @param isMultiPart
   *    an optional parameter to indicate this is a multipart request
   * @return {Observable<string>}
   *      An Observable<string> containing the response from the server
   */
  request(method: RestRequestMethod, url: string, body?: any, options?: HttpOptions, isMultipart?: boolean): Observable<RawRestResponse> {
    const requestOptions: HttpOptions = {};
    requestOptions.body = body;
    requestOptions.observe = 'response';

    if (options && options.responseType) {
      requestOptions.responseType = options.responseType;
    }

    if (hasNoValue(options) || hasNoValue(options.headers)) {
      requestOptions.headers = new HttpHeaders();
    } else {
      requestOptions.headers = options.headers;
    }

    if (options && options.params) {
      requestOptions.params = options.params;
    }

    if (options && options.withCredentials) {
      requestOptions.withCredentials = options.withCredentials;
    }

    if (!requestOptions.headers.has('Content-Type') && !isMultipart) {
      // Because HttpHeaders is immutable, the set method returns a new object instead of updating the existing headers
      requestOptions.headers = requestOptions.headers.set('Content-Type', DEFAULT_CONTENT_TYPE);
    }
    return this.http.request(method, url, requestOptions).pipe(
      map((res) => ({
        payload: res.body,
        headers: res.headers,
        statusCode: res.status,
        statusText: res.statusText
      })),
      catchError((err) => {
        if (hasValue(err.status)) {
          return observableThrowError({
            statusCode: err.status,
            statusText: err.statusText,
            message: (hasValue(err.error) && isNotEmpty(err.error.message)) ? err.error.message : err.message
          });
        } else {
          return observableThrowError(err);
        }
      }));
  }
}
