/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * Model class for the CmsMenu object
 */
import { autoserialize, autoserializeAs, deserialize } from 'cerialize';
import { CmsMenuLink } from '../cms-menu/cms-menu-link.model';
import { CMS_MENU } from './cms-menu.resource-type';
import { typedObject } from '../../app/core/cache/builders/build-decorators';
import { HALLink } from '../../app/core/shared/hal-link.model';
import { excludeFromEquals } from '../../app/core/utilities/equals.decorators';
import { ResourceType } from '../../app/core/shared/resource-type';
import { CacheableObject } from '../../app/core/cache/object-cache.reducer';

@typedObject
export class CmsMenu implements CacheableObject {
  static type = CMS_MENU;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  @autoserialize
  id: string;

  @autoserialize
  title: string;

  @autoserialize
  language: string;

  @autoserialize
  links: CmsMenuLink[];

  @autoserializeAs(String, 'id')
  uuid: string;

  @deserialize
  _links: {
    self: HALLink,
  };

}
