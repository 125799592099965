/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SearchService } from '../../../../app/core/shared/search/search.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '../../../../app/core/services/route.service';
import { RequestService } from '../../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../../app/core/cache/builders/remote-data-build.service';
import { LinkService } from '../../../../app/core/cache/builders/link.service';
import { HALEndpointService } from '../../../../app/core/shared/hal-endpoint.service';
import { CommunityDataService } from '../../../../app/core/data/community-data.service';
import { DSpaceObjectDataService } from '../../../../app/core/data/dspace-object-data.service';
import { PaginationService } from '../../../../app/core/pagination/pagination.service';
import { AtmireSavedItemListStoreService } from '../../../atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { SearchConfigurationService } from '../../../../app/core/shared/search/search-configuration.service';
import { PaginatedSearchOptions } from '../../../../app/shared/search/models/paginated-search-options.model';
import { combineLatest as observableCombineLatest, Observable, of as observableOf } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { createSavedItemListsProjectionArgs } from '../../../atmire-saved-item-list/atmire-saved-item-list.util';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { SearchOptions } from '../../../../app/shared/search/models/search-options.model';
import { QuerySuggestionsObject } from '../../../shared/search/query-suggestion-object.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { FollowLinkConfig } from '../../../../app/shared/utils/follow-link-config.model';
import { environment } from '../../../../environments/environment';
import { URLCombiner } from '../../../../app/core/url-combiner/url-combiner';
import { GenericConstructor } from '../../../../app/core/shared/generic-constructor';
import { ResponseParsingService } from '../../../../app/core/data/parsing.service';
import { SuggestResponseParsingService } from '../../data/suggest-response-parsing.service';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AtmireSearchService extends SearchService {
  /**
   * Endpoint link path for retrieving suggestions
   */
  private suggestLinkPath = 'querySuggestions';

  constructor(protected router: Router,
              protected routeService: RouteService,
              protected requestService: RequestService,
              protected rdb: RemoteDataBuildService,
              protected linkService: LinkService,
              protected halService: HALEndpointService,
              protected communityService: CommunityDataService,
              protected dspaceObjectService: DSpaceObjectDataService,
              protected paginationService: PaginationService,
              protected savedItemListStoreService: AtmireSavedItemListStoreService,
              protected searchConfigurationService: SearchConfigurationService,
              protected angulartics2: Angulartics2,
  ) {
    super(router, routeService, requestService, rdb, linkService, halService, communityService, dspaceObjectService, paginationService, searchConfigurationService, angulartics2);
  }

  getEndpoint(searchOptions?: PaginatedSearchOptions): Observable<string> {
    return observableCombineLatest(this.halService.getEndpoint(this.searchLinkPath), this.savedItemListStoreService.getStoredListIdsObs()).pipe(
      map(([url, lists]: [string, string[]]) => {
        const options = Object.assign(new PaginatedSearchOptions({}), searchOptions);
        const args = createSavedItemListsProjectionArgs(...lists);
        if (hasValue(options) || isNotEmpty(args)) {
          return options.toRestUrl(url, args);
        } else {
          return url;
        }
      })
    );
  }

  /**
   * Method to retrieve a list of suggestion categories,
   * each containing a list of suggestions of that category
   * @param searchOptions The search options to find suggestions for
   */
  suggest(searchOptions: SearchOptions, ...linksToFollow: FollowLinkConfig<any>[]): Observable<RemoteData<QuerySuggestionsObject>> {
    // const href$ = this.halService.getEndpoint(this.suggestLinkPath).pipe(
    //   map((url: string) => {
    //     if (hasValue(searchOptions)) {
    //       url = searchOptions.toRestUrl(url);
    //     }
    //     return url;
    //   }),
    // );

    const href$ = observableOf(environment.rest.baseUrl + '/api/suggestion/querySuggestions/search/byQuery').pipe(
      map((url: string) => {
        const args = this.searchDataService.addEmbedParams(url, [], ...linksToFollow);
        if (hasValue(searchOptions)) {
          url = searchOptions.toRestUrl(url, args);
        } else if (isNotEmpty(args)) {
          url = new URLCombiner(url, `?${args.join('&')}`).toString();
        }
        return url;
      })
    );

    href$.pipe(take(1)).subscribe((url: string) => {
      const request = new this.request(this.requestService.generateRequestId(), url);
      const getResponseParserFn: () => GenericConstructor<ResponseParsingService> = () => {
        return SuggestResponseParsingService;
      };

      Object.assign(request, {
        getResponseParser: getResponseParserFn,
      });
      this.requestService.send(request, true);
    });

    return this.rdb.buildFromHref(href$);
  }
}
