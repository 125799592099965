<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<footer class="text-lg-start">
  <!-- Top Footer -->
  <div class="container px-3 py-4">
    <div *ngIf="showTopFooter" class="top-footer d-flex flex-wrap flex-column mb-3">
      <div class="my-auto" [innerHTML]="(logoRD$ | async)?.payload?.contentString"></div>
      <!--      <div class="my-auto">
              <img [alt]="'footer.fao-sustainable-development-goals.logo' | translate"
                   [src]="getLogo()">
            </div>-->
      <div class="my-auto footer-links-section">
        <div [innerHTML]="(followUsRD$ | async)?.payload?.contentString"></div>
        <!--<div>
          <h6 class="small font-weight-bold text-uppercase">{{ 'footer.follow-us-on' | translate }}</h6>
          <ul class="social-links list-unstyled justify-content-start mb-0">
            <li>
              <a href="https://www.facebook.com/UNFAO">
                <img [alt]="'footer.social.facebook.icon' | translate"
                     src="/assets/client/images/social/icon-facebook.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.flickr.com/photos/faonews/albums/">
                <img [alt]="'footer.social.flickr.icon' | translate"
                     src="/assets/client/images/social/icon-flickr.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/fao/">
                <img [alt]="'footer.social.instagram.icon' | translate"
                     src="/assets/client/images/social/icon-instagram.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/fao/">
                <img [alt]="'footer.social.linkedin.icon' | translate"
                     src="/assets/client/images/social/icon-linkedin.svg"/>
              </a>
            </li>
            <li>
              <a href="#">
                <img [alt]="'footer.social.rss.icon' | translate"
                     src="/assets/client/images/social/icon-rss.svg"/>
              </a>
            </li>
&lt;!&ndash;            <li>
              <a href="https://www.slideshare.net/FAOoftheUN">
                <img [alt]="'footer.social.slideshare.icon' | translate"
                     src="/assets/client/images/social/icon-slideshare.svg"/>
              </a>
            </li>&ndash;&gt;
            <li>
              <a href="https://soundcloud.com/unfao">
                <img [alt]="'footer.social.soundcloud.icon' | translate"
                     src="/assets/client/images/social/icon-soundcloud.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@fao">
                <img [alt]="'footer.social.tiktok.icon' | translate"
                     src="/assets/client/images/social/icon-tiktok.svg"/>
              </a>
            </li>
            <li>
              <a
                href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAddfBLLYL2GZzXAetR_paFVV1-M4fdAWpUxiROxuOpl7pDGuXLCGfYg_VOogU135J/?wid=1650826195609">
                <img [alt]="'footer.social.toutiao.icon' | translate"
                     src="/assets/client/images/social/icon-toutiao.svg"/>
              </a>
            </li>
            <li>
              <a href="#">
                <img [alt]="'footer.social.wechat.icon' | translate"
                     src="/assets/client/images/social/icon-wechat.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.weibo.com/unfao">
                <img [alt]="'footer.social.weibo.icon' | translate"
                     src="/assets/client/images/social/icon-weibo.svg"/>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/FAO">
                <img [alt]="'footer.social.twitter.icon' | translate"
                     src="/assets/client/images/social/icon-twitter.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/FAOoftheUN">
                <img [alt]="'footer.social.youtube.icon' | translate"
                     src="/assets/client/images/social/icon-youtube.svg"/>
              </a>
            </li>
          </ul>
        </div>-->
      </div>
    </div>
    <!-- Top Footer -->
    <!-- Bottom Footer -->
    <div class="bottom-footer d-flex">
      <div class="content-container d-flex flex-wrap flex-column">
        <div class="footer-links-section" dsFooterCms [innerHTML]="(linksRD$ | async)?.payload?.contentString"></div>
        <!--<ul>
    <li class="list-unstyled">
        <a href="https://www.fao.org/about/org-chart/en/">FAO Organizational Chart</a>
    </li>
    <li class="list-unstyled">
        <a class="worldwide-offices-toggle mb-1 " href="#">Worldwide Offices&nbsp;</a>
        <ul>
            <li class="list-unstyled">
                <a href="https://www.fao.org/africa/en/" target="_self"><span>Regional Office for Africa&nbsp;</span></a>
            </li>
            <li class="list-unstyled">
                <a href="https://www.fao.org/asiapacific/en/" target="_self"><span>Regional Office for Asia and the Pacific&nbsp;</span></a>
            </li>
            <li class="list-unstyled">
                <a href="https://www.fao.org/europe/en/" target="_self"><span>Regional Office for Europe and Central Asia&nbsp;</span></a>
            </li>
            <li class="list-unstyled">
                <a href="https://www.fao.org/americas/en/" target="_self"><span>Regional Office for Latin America and the Caribbean&nbsp;</span></a>
            </li>
            <li class="list-unstyled">
                <a href="https://www.fao.org/neareast/en/" target="_self"><span>Regional Office for the Near East and North Africa&nbsp;</span></a>
            </li>
            <li class="list-unstyled">
                <a href="https://www.fao.org/about/who-we-are/worldwide-offices/en/#c207632" target="_self"><span>Country Offices&nbsp;</span></a>
            </li>
        </ul>
    </li>
</ul>
<p class="border-bottom p-0 m-0">
    &nbsp;
</p>
<ul>
    <li class="list-unstyled list-with-separator small d-flex mb-0 border-right-links mt-1">
        <a href="https://www.fao.org/employment/home/en/">Jobs</a>
    </li>
    <li class="list-unstyled list-with-separator small d-flex mb-0 border-right-links mt-1">
        <a href="https://fao.org/contact-us/en/">Contact us</a>
    </li>
    <li class="list-unstyled list-with-separator small d-flex mb-0 border-right-links mt-1">
        <a href="https://fao.org/contact-us/terms/en/">Terms and Conditions</a>
    </li>
    <li class="list-unstyled list-with-separator small d-flex mb-0 border-right-links mt-1">
        <a href="https://www.fao.org/contact-us/data-protection-and-privacy/en">Data protection and privacy</a>
    </li>
    <li class="list-unstyled list-with-separator small d-flex mb-0 border-right-links mt-1">
        <a href="https://fao.org/contact-us/scam-alert/en/">Scam Alert</a>
    </li>
    <li class="list-unstyled list-with-separator small d-flex mb-0 border-right-links mt-1">
        <a href="https://fao.org/contact-us/report-misconduct/en/">Report Misconduct</a>
    </li>
</ul>-->
        <div>
<!--          <h6 class="small font-weight-bold text-uppercase">{{ 'footer.download-our-app' | translate }}</h6>
          <div class="mobile-stores d-flex flex-wrap mb-3">
            <a href="https://play.google.com/store/apps/details?id=org.fao.mobile">
              <img [alt]="'footer.google-play.logo' | translate" src="/assets/client/images/google-play-logo.svg">
            </a>
            <a href="https://apps.apple.com/us/app/faonow/id877618174">
              <img [alt]="'footer.app-store.logo' | translate" src="/assets/client/images/app-store-logo.svg">
            </a>
          </div>-->
          <a class="small" href="{{ 'footer.copyright.link' | translate }}">
            {{ 'footer.copyright' | translate:{ year: dateObj | date:'y' } }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Bottom Footer -->
</footer>
