<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="number-chart" #wrapper>
  <ds-ngx-custom-number-inline *ngIf="data$ | async"
                                 [view]="[wrapper.offsetWidth === 0 ? view[0] : wrapper.offsetWidth, wrapper.offsetHeight === 0 ? view[1] : wrapper.offsetHeight]"
                                 [results]="data$ | async"
                                 [textColor]="palette ? palette[0] : undefined"
                                 [scheme]="colors$ | async"
                                 [alignment]="alignment">
  </ds-ngx-custom-number-inline>
</div>
