/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { animate, state, style, transition, trigger } from '@angular/animations';

const rtlExpandedStyle = {transform: 'rotate(-90deg)'};
const rtlCollapsedStyle = {transform: 'rotate(0deg)'};

export const rotate = trigger('rotate',
  [
    state('expanded', style(rtlExpandedStyle)),
    state('collapsed', style(rtlCollapsedStyle)),
    transition('expanded <=> collapsed', [
      animate('200ms')
    ])
  ]);

