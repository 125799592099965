<div class="btn-group" data-toggle="buttons">
    <a *ngIf="isToShow(viewModeEnum.ListElement)"
       routerLink="."
       [queryParams]="{view: 'list'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.ListElement)"
       routerLinkActive="active"
       [ngClass]="currentMode === viewModeEnum.ListElement ? 'active btn-primary' : 'btn-outline-primary'"
       class="btn"
       [attr.data-test]="'list-view' | dsBrowserOnly">
          <i class="fas fa-list" title="{{'search.view-switch.show-list' | translate}}"></i>
    </a>
    <a *ngIf="isToShow(viewModeEnum.GridElement)"
       routerLink="."
       [queryParams]="{view: 'grid'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.GridElement)"
       routerLinkActive="active"
       [ngClass]="currentMode === viewModeEnum.GridElement ? 'active btn-primary' : 'btn-outline-primary'"
       class="btn"
       [attr.data-test]="'grid-view' | dsBrowserOnly">
          <i class="fas fa-th-large" title="{{'search.view-switch.show-grid' | translate}}"></i>
    </a>
    <a *ngIf="isToShow(viewModeEnum.DetailedListElement)"
       routerLink="."
       [queryParams]="{view: 'detailed'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.DetailedListElement)"
       routerLinkActive="active"
       [ngClass]="currentMode === viewModeEnum.DetailedListElement ? 'active btn-primary' : 'btn-outline-primary'"
       class="btn"
       [attr.data-test]="'detail-view' | dsBrowserOnly">
      <i class="far fa-square" title="{{'search.view-switch.show-detail' | translate}}"></i>
    </a>
</div>
