/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Input } from '@angular/core';
import { DSpaceObject } from '../../../../../../../app/core/shared/dspace-object.model';
import { ObjectSuggestion } from '../../../../../../core/search/models/object-suggestion.model';
import { rendersSuggestionPreview } from '../../suggestion.decorator';
import { SearchOptions } from '../../../../../../../app/shared/search/models/search-options.model';

/**
 * Abstract class to render a suggestion preview for a DSpaceObject
 */
@rendersSuggestionPreview(ObjectSuggestion)
export abstract class DSOSuggestionPreviewComponent<T extends DSpaceObject> {
  /**
   * The DSpaceObject to render the preview for
   */
  @Input() dso: T;
  @Input() searchOptions: SearchOptions;
}
