/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { StatletActions, StatletActionTypes } from './statlet.actions';

/**
 * Things to keep track of for statlets.
 * The statlets themselves are tracked in the request store.
 */
export interface StatletState {
  /**
   * When to display the statlets
   */
  show: boolean;
}

/**
 * Default state before any actions happen
 */
export const initialStatletState: StatletState = {
  show: false
};

/**
 * Updates the state according to the given action
 * @param state The statlet state
 * @param action The statlet action
 */
export function statletReducer(state: any = initialStatletState, action: StatletActions): StatletState {

  switch (action.type) {
    case StatletActionTypes.SHOW_STATLETS_ACTION:
      return Object.assign({},state,{show:true});

    case StatletActionTypes.HIDE_STATLETS_ACTION:
      return Object.assign({},state,{show:false});

    default:
      return state;
  }
}
