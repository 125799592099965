<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex w-100">
  <div class="thumbnail mr-2">
    <ds-themed-thumbnail [thumbnail]="thumbnailRD$ | async"></ds-themed-thumbnail>
  </div>
  <ds-item-search-result-list-element [showLabel]="showLabel" [object]="{ indexableObject: object, hitHighlights: {} }" [linkType]="linkType"></ds-item-search-result-list-element>
</div>
