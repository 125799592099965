/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { getFirstCompletedRemoteData } from '../../../../../app/core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { PaginatedSearchOptions } from '../../../../../app/shared/search/models/paginated-search-options.model';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { RSSComponent } from '../../../../../app/shared/rss-feed/rss.component';
import { SortDirection, SortOptions } from '../../../../../app/core/cache/models/sort-options.model';

/**
 * The Rss feed button componenet.
 */
@Component({
  exportAs: 'rssComponent',
  selector: 'ds-client-rss',
  styleUrls: ['../../../../../app/shared/rss-feed/rss.component.scss'],
  templateUrl: 'client-rss.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.Emulated
})
export class ClientRssComponent extends RSSComponent {

  route$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * Generates the link tags and the url to opensearch when the component is loaded.
   */
  ngOnInit(): void {
    this.configuration$ = this.searchConfigurationService.getCurrentConfiguration('default');

    this.subs.push(this.configurationService.findByPropertyName('websvc.opensearch.enable').pipe(
      getFirstCompletedRemoteData(),
    ).subscribe((result) => {
      if (result.hasSucceeded) {
        const enabled = (result.payload.values[0] === 'true');
        this.isEnabled$.next(enabled);
      }
    }));
    this.subs.push(this.configurationService.findByPropertyName('websvc.opensearch.svccontext').pipe(
      getFirstCompletedRemoteData(),
      map((result: RemoteData<any>) => {
        if (result.hasSucceeded) {
          return result.payload.values[0];
        }
        return null;
      }),
      switchMap((openSearchUri: string) =>
        this.searchConfigurationService.paginatedSearchOptions.pipe(
          map((searchOptions: PaginatedSearchOptions) => ({ openSearchUri,  searchOptions }))
        )
      ),
    ).subscribe(({ openSearchUri,  searchOptions }) => {
      if (!openSearchUri) {
        return null;
      }
      let sortOptions = searchOptions.sort;
      if (this.router.url.includes('browse/')) {
        sortOptions = new SortOptions(this.router.url.match(/browse\/(\w+)/)[1], SortDirection.ASC);
      }
      this.uuid = this.groupDataService.getUUIDFromString(this.router.url);
      const route = environment.rest.baseUrl + this.formulateRoute(this.uuid, openSearchUri, sortOptions, searchOptions.query);
      this.addLinks(route);
      this.linkHeadService.addTag({
        href: environment.rest.baseUrl + '/' + openSearchUri + '/service',
        type: 'application/atom+xml',
        rel: 'search',
        title: 'Dspace'
      });
      this.route$.next(route);
    }));
  }
}
