/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { SharingButtonsComponent } from './buttons/sharing-buttons.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

const DECLARATIONS = [
  SharingButtonsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class SocialSharingModule {

}
