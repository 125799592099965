<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="card">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/', object.id]" class="card-img-top">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </span>
  <div class="card-body">
    <h4 class="card-title">{{firstMetadataValue('dc.title')}}</h4>
    <p *ngIf="object.shortDescription" class="card-text">{{firstMetadataValue('dc.description.abstract')}}</p>
    <div *ngIf="linkType != linkTypes.None" class="text-center">
      <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/', object.id]" class="lead btn btn-primary viewButton">View</a>
    </div>
  </div>
</div>
