<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngIf="(isEnabled$ | async) && (hasRoute('home') || hasRoute('collections') || hasRoute('communities') || hasRoute('browse'))">
  <div *ngIf="route$ | async as route" class="d-inline-block float-right margin-right">
    <a [href]="route" class="btn btn-secondary"><i class="fas fa-rss-square"></i></a>
  </div>
</ng-container>
