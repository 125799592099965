/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

const collapsedStyle = style({ marginRight: '-{{ sidebarWidth }}' });
const expandedStyle = style({ marginRight: '0' });
const options = { params: { sidebarWidth: '*' } };

export const slideSidebar = trigger('slideSidebar', [

  transition('expanded => collapsed',
    group
    (
      [
        query('@*', animateChild()),
        query('.sidebar-collapsible', expandedStyle, options),
        query('.sidebar-collapsible', animate('300ms ease-in-out', collapsedStyle))
      ],
    )),

  transition('collapsed => expanded',
    group
    (
      [
        query('@*', animateChild()),
        query('.sidebar-collapsible', collapsedStyle),
        query('.sidebar-collapsible', animate('300ms ease-in-out', expandedStyle), options)
      ]
    ))
]);


export const slideSidebarPadding = trigger('slideSidebarPadding', [
  state('hidden', style({ paddingRight: 0 })),
  state('shown', style({ paddingRight: '{{ collapsedSidebarWidth }}' }), { params: { collapsedSidebarWidth: '*' } }),
  state('expanded', style({ paddingRight: '{{ totalSidebarWidth }}' }), { params: { totalSidebarWidth: '*' } }),
  transition('hidden <=> shown', [animate('200ms')]),
  transition('hidden <=> expanded', [animate('200ms')]),
  transition('shown <=> expanded', [animate('200ms')]),
]);
