/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import {
  FieldUpdate,
  FieldUpdates
} from '../../../../app/core/data/object-updates/object-updates.reducer';
import { Observable } from 'rxjs/internal/Observable';
import { ObjectUpdatesService } from '../../../../app/core/data/object-updates/object-updates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs/operators';
import { hasValue } from '../../../../app/shared/empty.util';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { AbstractTrackableComponent } from '../../../../app/shared/trackable/abstract-trackable.component';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { environment } from '../../../../environments/environment';
import { combineLatest as observableCombineLatest } from 'rxjs';

@Component({
  selector: 'ds-abstract-object-update',
  template: ''
})
/**
 * Abstract component for managing object updates of a DSpaceObject
 */
export class AbstractObjectUpdateComponent<T extends DSpaceObject> extends AbstractTrackableComponent implements OnInit {
  /**
   * The object to display the edit page for
   */
  object: T;
  /**
   * The current values and updates for all this object's fields
   * Should be initialized in the initializeUpdates method of the child component
   */
  updates$: Observable<FieldUpdates>;

  constructor(
    public objectUpdatesService: ObjectUpdatesService,
    public router: Router,
    public notificationsService: NotificationsService,
    public translateService: TranslateService,
    public route: ActivatedRoute
  ) {
    super(objectUpdatesService, notificationsService, translateService);
  }

  /**
   * Initialize common properties between object-update components
   */
  ngOnInit(): void {
    this.setObject();

    this.discardTimeOut = environment.item.edit.undoTimeout;
    this.url = this.router.url;
    if (this.url.indexOf('?') > 0) {
      this.url = this.url.substr(0, this.url.indexOf('?'));
    }
    this.hasChanges().pipe(first()).subscribe((hasChanges) => {
      if (!hasChanges) {
        this.initializeOriginalFields();
      } else {
        this.checkLastModified();
      }
    });

    this.initializeNotificationsPrefix();
    this.initializeUpdates();
  }

  /**
   * Set the value of the object by fetching it from the parent's route
   * Overwrite this method if the object needs to be set differently
   */
  setObject(): void {
    observableCombineLatest(this.route.data, this.route.parent.data).pipe(
      map(([data, parentData]) => Object.assign({}, data, parentData)),
      map((data) => data.item),
      first(),
      map((data: RemoteData<DSpaceObject>) => data.payload)
    ).subscribe((object: T) => {
      this.object = object;
      this.postItemInit();
    });
  }

  /**
   * Actions to perform after the item has been initialized
   * Abstract method: Should be overwritten in the sub class
   */
  postItemInit(): void {
    // Overwrite in subclasses
  }

  /**
   * Initialize the values and updates of the current item's fields
   * Abstract method: Should be overwritten in the sub class
   */
  initializeUpdates(): void {
    // Overwrite in subclasses
  }

  /**
   * Initialize the prefix for notification messages
   * Abstract method: Should be overwritten in the sub class
   */
  initializeNotificationsPrefix(): void {
    // Overwrite in parent
  }

  /**
   * Sends all initial values of this item to the object updates service
   * Abstract method: Should be overwritten in the sub class
   */
  initializeOriginalFields(): void {
    // Overwrite in subclasses
  }

  /**
   * Submit the current changes
   * Abstract method: Should be overwritten in the sub class
   */
  submit(): void {
    // Overwrite in subclasses
  }

  /**
   * Prevent unnecessary rerendering so fields don't lose focus
   */
  trackUpdate(index, update: FieldUpdate) {
    return update && update.field ? update.field.uuid : undefined;
  }

  /**
   * Check if the current page is entirely valid
   */
  protected isValid() {
    return this.objectUpdatesService.isValidPage(this.url);
  }

  /**
   * Checks if the current item is still in sync with the version in the store
   * If it's not, a notification is shown and the changes are removed
   */
  checkLastModified() {
    if (hasValue((this.object as any).lastModified)) {
      const currentVersion = (this.object as any).lastModified;
      this.objectUpdatesService.getLastModified(this.url).pipe(first()).subscribe(
        (updateVersion: Date) => {
          if (updateVersion.getDate() !== currentVersion.getDate()) {
            this.notificationsService.warning(this.getNotificationTitle('outdated'), this.getNotificationContent('outdated'));
            this.initializeOriginalFields();
          }
        }
      );
    }
  }
}
