/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { AdminSidebarComponent as BaseComponent} from '../../../../../app/admin/admin-sidebar/admin-sidebar.component';
import { slideSidebar } from '../../shared/animations/slide';

/**
 * Component representing the admin sidebar
 */
@Component({
  selector: 'ds-admin-sidebar',
  templateUrl: '../../../../../app/admin/admin-sidebar/admin-sidebar.component.html',
  // templateUrl: './admin-sidebar.component.html',
  // styleUrls: ['../../../../../app/admin/admin-sidebar/admin-sidebar.component.scss'],
  styleUrls: ['./admin-sidebar.component.scss'],
  animations: [slideSidebar]
})
export class AdminSidebarComponent extends BaseComponent {

}
