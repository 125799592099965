/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
} from '@angular/core';
import { Statlet } from '../data/models/statlet.model';
import { hasStatletData } from '../data/models/statlet.utils';
import { NotificationsService } from '../../../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { StatletPoint } from '../data/models/statlet-point.model';

@Component({
  selector: 'ds-search-result-single-statlet',
  templateUrl: './search-result-single-statlet.component.html',
})
/**
 * Component displaying a single statistics statlet in the list view
 */
export class SearchResultSingleStatletComponent implements OnInit {
  /**
   * The statlet to display
   */
  @Input() statlet: Statlet;

  /**
   * Separator string between multiple points of the statlet
   * @type {string}
   */
  @Input() separator: string;

  /**
   * Whether or not the statlet contains at least one point of data
   * Don't display the statlet if it doesn't contain any data
   */
  hasData = true;

  /**
   * Labels of each point of statlet
   */
  labels: string[];

  ngOnInit(): void {
    this.hasData = hasStatletData(this.statlet);
    this.labels = this.statlet.points.map((point: StatletPoint) => point.label);
  }

  containsPointByLabel(label: string): boolean {
    return this.labels.includes(label);
  }

  getPointByLabel(label: string): StatletPoint {
    return this.statlet.points.find((point: StatletPoint) => {
      return point.label === label;
    });
  }

}
