/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { slide } from '../../../../../../app/shared/animations/slide';
import {
  ExpandableAdminSidebarSectionComponent as BaseComponent
} from '../../../../../../app/admin/admin-sidebar/expandable-admin-sidebar-section/expandable-admin-sidebar-section.component';
import { bgColor } from '../../../../../../app/shared/animations/bgColor';
// import { slide } from '../../../../../../app/shared/animations/slide';
import { MenuID } from '../../../../../../app/shared/menu/initial-menus-state';
import { rendersSectionForMenu } from '../../../../../../app/shared/menu/menu-section.decorator';
import { rotate } from '../../../shared/animations/rotate';

/**
 * Represents a expandable section in the sidebar
 */
@Component({
  /* tslint:disable:component-selector */
  selector: 'li[ds-expandable-admin-sidebar-section]',
  templateUrl: '../../../../../../app/admin/admin-sidebar/expandable-admin-sidebar-section/expandable-admin-sidebar-section.component.html',
  // templateUrl: './expandable-admin-sidebar-section.component.html',
  styleUrls: ['../../../../../../app/admin/admin-sidebar/expandable-admin-sidebar-section/expandable-admin-sidebar-section.component.scss'],
  // styleUrls: ['./expandable-admin-sidebar-section.component.scss'],
  animations: [rotate, slide, bgColor]
})

@rendersSectionForMenu(MenuID.ADMIN, true, 'client-rtl')
export class ExpandableAdminSidebarSectionComponent extends BaseComponent {

}
