/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { NotificationsService } from '../../app/shared/notifications/notifications.service';
import { AbstractTrackableComponent } from '../../app/shared/trackable/abstract-trackable.component';
import { AtmireObjectUpdatesService } from '../core/data/object-updates/atmire-object-updates.service';

/**
 * Abstract Component that is able to track changes made in the inheriting component using the ObjectUpdateService
 */
@Component({
  selector: 'ds-atmire-abstract-trackable',
  template: ''
})
export class AtmireAbstractTrackableComponent extends AbstractTrackableComponent {

  constructor(
    public objectUpdatesService: AtmireObjectUpdatesService,
    public notificationsService: NotificationsService,
    public translateService: TranslateService,
  ) {
    super(objectUpdatesService, notificationsService, translateService);
  }

  /**
   * Request the object updates service to discard all current changes to this item
   * Shows a notification to remind the user that they can undo this
   */
  discard() {
    const undoNotification = this.notificationsService.info(this.getNotificationTitle('discarded'), this.getNotificationContent('discarded'), {timeOut: this.discardTimeOut});
    this.objectUpdatesService.discardListFieldUpdates(undoNotification, this.url);
  }
}
