/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable, OnInit } from '@angular/core';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { StaticPageContentObject } from '../../../../static-page/static-page-content-object.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DSpaceObjectDataService } from '../../../../../app/core/data/dspace-object-data.service';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { getFirstSucceededRemoteData } from '../../../../../app/core/shared/operators';
import { hasValue, isNotEmpty } from '../../../../../app/shared/empty.util';

/**
 * Abstract component to wrap the static page selector inside a modal
 * Used both to create and edit
 */
@Injectable()
export abstract class StaticPageWrapperComponent implements OnInit {

  currentObjectRD: RemoteData<any>;

  currentDSpaceObject: DSpaceObject;
  currentStaticPage: StaticPageContentObject;

  constructor(protected activeModal: NgbActiveModal, protected route: ActivatedRoute, protected dsoService: DSpaceObjectDataService) {
  }

  ngOnInit(): void {
    this.currentObjectRD = this.getCurrentObject();
    if (hasValue(this.currentObjectRD)) {
      const currentObject = this.currentObjectRD.payload;

      if (currentObject instanceof StaticPageContentObject) {
        this.currentStaticPage = currentObject as StaticPageContentObject;
        this.dsoService.findById(this.currentStaticPage.dspaceobjectUuid).pipe(getFirstSucceededRemoteData())
          .subscribe((remoteData: RemoteData<DSpaceObject>) => {
              this.currentDSpaceObject = remoteData.payload;
            }
          );
      } else if (currentObject instanceof DSpaceObject) {
        this.currentDSpaceObject = currentObject as DSpaceObject;
      }
    }
  }

  /**
   * Get the current object. This can either be a DSO object, a static page or null when the current page does not show
   * an item or static page
   */
  getCurrentObject(): RemoteData<DSpaceObject | StaticPageContentObject> {
    let matchingRoute;

    // Test staticPageContentObject
    matchingRoute = this.findRouteData(
      (route: ActivatedRouteSnapshot) => hasValue(route.data.staticPageContentObject),
      this.route.root.snapshot
    );
    if (hasValue(matchingRoute)) {
      return matchingRoute.data.staticPageContentObject;
    }

    // Test staticPageContentObject
    matchingRoute = this.findRouteData(
      (route: ActivatedRouteSnapshot) => hasValue(route.data.dso),
      this.route.root.snapshot
    );
    if (hasValue(matchingRoute)) {
      return matchingRoute.data.dso;
    }
    return null;
  }

  findRouteData(predicate: (value: ActivatedRouteSnapshot, index?: number, obj?: ActivatedRouteSnapshot[]) => unknown, ...routes: ActivatedRouteSnapshot[]) {
    const result = routes.find(predicate);
    if (hasValue(result)) {
      return result;
    } else {
      const nextLevelRoutes = routes
        .map((route: ActivatedRouteSnapshot) => route.children)
        .reduce((combined: ActivatedRouteSnapshot[], current: ActivatedRouteSnapshot[]) => [...combined, ...current]);
      if (isNotEmpty(nextLevelRoutes)) {
        return this.findRouteData(predicate, ...nextLevelRoutes);
      } else {
        return undefined;
      }
    }
  }


  /**
   * Close the modal
   */
  close() {
    this.activeModal.close();
  }

}
