/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtmireHomePageRoutingModule } from './atmire-home-page-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AtmireHomePageRoutingModule,
  ],
})
export class AtmireHomePageModule {

}
