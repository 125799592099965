<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="rotation-wrapper-outer">
  <div class="rotation-wrapper-inner">
    <ng-select [items]="options$ | async"
               [placeholder]="placeholder"
               [multiple]="true"
               [(ngModel)]="selectedIds"
               [typeahead]="query$"
               [bindLabel]="'name'"
               [bindValue]="'id'"
               (ngModelChange)="change()"
               (blur)="submit(selectedIds)"
               [loading]="loading">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
          <div class="selector-item d-flex">
            <div class="item-content flex-grow-1">
              <ds-listable-object-component-loader [object]="item.dso" [viewMode]="viewMode"
                                                   [linkType]=linkTypes.None
                                                   [context]="context"></ds-listable-object-component-loader>
            </div>
            <div class="item-fade align-self-stretch float-right flex-shrink-0">
            </div>
            <div class="selected-marker text-primary align-self-stretch flex-shrink-0 float-right my-auto ml-1" *ngIf="item$.selected">
              <i class="fas fa-check fa-fw"></i>
            </div>
          </div>
      </ng-template>
    </ng-select>
  </div>
</div>
