/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { FeaturedItemsComponent } from './view/featured-items.component';
import { FeaturedItemsService } from './featured-items.service';
import { FeaturedItemDetailedListElementComponent } from './view/featured-item-detailed-list-element/featured-item-detailed-list-element.component';
import { FileIconThumbnailComponent } from './view/file-icon-thumbnail/file-icon-thumbnail.component';
import { FeaturedItemLoadingElementComponent } from './view/featured-item-loading-element/featured-item-loading-element.component';
import { ManageFeaturedPublicationsListElementComponent } from './edit/manage-featured-publications-list-element/manage-featured-publications-list-element.component';
import { ManageFeaturedPublicationsPageComponent } from './edit/manage-featured-publications-page/manage-featured-publications-page.component';
import { AtmireSharedModule } from '../shared/atmire-shared.module';

const DECLARATIONS = [
  FeaturedItemsComponent,
  FeaturedItemDetailedListElementComponent,
  FileIconThumbnailComponent,
  FeaturedItemLoadingElementComponent,
  ManageFeaturedPublicationsPageComponent,
  ManageFeaturedPublicationsListElementComponent,
];

const ENTRY_COMPONENTS = [
  FeaturedItemDetailedListElementComponent,
  ManageFeaturedPublicationsListElementComponent,
];

const PROVIDERS = [
  FeaturedItemsService,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AtmireSharedModule,
  ],
  declarations: [
    ...DECLARATIONS,
  ],
  exports: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ]
})
export class AtmireFeaturedItemsSharedModule {
}
