/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { TruncatableComponent } from '../../../app/shared/truncatable/truncatable.component';
import { Component } from '@angular/core';
@Component({
  selector: 'ds-selective-truncatable',
  templateUrl: './selective-truncatable.component.html',
  styleUrls: ['../../../app/shared/truncatable/truncatable.component.scss'],
})
export class SelectiveTruncatableComponent extends TruncatableComponent {
  public selectiveToggle(event: Event) {
    if (!(event.target instanceof Element) || !(event.target as Element).classList.contains('btn')) {
      super.toggle();
    }
  }
}
