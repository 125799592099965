/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Action } from '@ngrx/store';
import { type } from '../../../app/shared/ngrx/type';

/**
 * The list of ObjectUpdatesAction type definitions
 */
export const DelayedActionsActionTypes = {
  ADD: type('dspace/core/cache/delayed-actions/ADD'),
  EXECUTE: type('dspace/core/cache/delayed-actions/EXECUTE'),
  CANCEL: type('dspace/core/cache/delayed-actions/CANCEL'),
  REMOVE: type('dspace/core/cache/delayed-actions/REMOVE'),
  RUSH: type('dspace/core/cache/delayed-actions/RUSH'),
};

/* tslint:disable:max-classes-per-file */

export class AddDelayedActionAction implements Action {
  type = DelayedActionsActionTypes.ADD;
  payload: {
    actionId: string,
    timeForExecution: number,
  };

  constructor(
    actionId: string,
    timeForExecution: number,
  ) {
    this.payload = {actionId, timeForExecution};
  }

}

export class ExecuteDelayedActionAction implements Action {
  type = DelayedActionsActionTypes.EXECUTE;
  payload: {
    actionId: string,
  };

  constructor(
    actionId: string,
  ) {
    this.payload = {actionId};
  }

}

export class CancelDelayedActionAction implements Action {
  type = DelayedActionsActionTypes.CANCEL;
  payload: {
    actionId: string,
  };

  constructor(
    actionId: string,
  ) {
    this.payload = {actionId};
  }
}

export class RemoveDelayedActionAction implements Action {
  type = DelayedActionsActionTypes.REMOVE;
  payload: {
    actionId: string,
  };

  constructor(
    actionId: string,
  ) {
    this.payload = {actionId};
  }
}

export class RushDelayedActionAction implements Action {
  type = DelayedActionsActionTypes.RUSH;
  payload: {
    actionId: string,
  };

  constructor(
    actionId: string,
  ) {
    this.payload = {actionId};
  }
}

/* tslint:enable:max-classes-per-file */

/**
 * A type to encompass all DelayedActionAction
 */
export type DelayedActionAction
  = AddDelayedActionAction
  | ExecuteDelayedActionAction
  | CancelDelayedActionAction
  | RemoveDelayedActionAction
  | RushDelayedActionAction;
