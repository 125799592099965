/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { FILE_ICON_MAPPING } from './file-icon-mapping.model';
import { BitstreamFormat } from '../../../../app/core/shared/bitstream-format.model';
import { hasNoValue, hasValue } from '../../../../app/shared/empty.util';

@Component({
  selector: 'ds-file-icon-thumbnail',
  templateUrl: './file-icon-thumbnail.component.html',
  styleUrls: ['./file-icon-thumbnail.component.scss']
})
export class FileIconThumbnailComponent implements OnInit {
  @Input() bitstreamFormat: BitstreamFormat;

  public faIconName;

  ngOnInit(): void {
    if (hasValue(this.bitstreamFormat) && hasValue(this.bitstreamFormat.mimetype)) {
      this.faIconName = FILE_ICON_MAPPING[this.bitstreamFormat.mimetype];
    }
    if (hasNoValue(this.faIconName)) {
      this.faIconName = FILE_ICON_MAPPING.default;
    }
  }
}
