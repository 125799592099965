/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject } from '@angular/core';
import { rendersMenuItemForType } from 'src/app/shared/menu/menu-item.decorator';
import { MenuItemType } from '../../../../app/shared/menu/initial-menus-state';
import { SectionHeaderModel } from './models/section-header.model';

/**
 * Component that renders a menu section of type TEXT
 */
@Component({
  selector: 'ds-section-header-menu-item',
  templateUrl: './section-header-menu-item.component.html',
  styleUrls: ['./section-header-menu-item.component.scss'],
})
@rendersMenuItemForType(MenuItemType.SECTION_HEADER)
export class SectionHeaderMenuItemComponent {
  item: SectionHeaderModel;

  constructor(@Inject('itemModelProvider') item: SectionHeaderModel) {
    this.item = item;
  }
}
