/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DSpaceObject } from '../../../../../../../app/core/shared/dspace-object.model';
import { StaticPageContentObject } from '../../../../../../static-page/static-page-content-object.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StaticPage } from '../../../../../static-page.model';
import { hasValue } from '../../../../../../../app/shared/empty.util';
import { Community } from '../../../../../../../app/core/shared/community.model';
import { Collection } from '../../../../../../../app/core/shared/collection.model';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { Site } from '../../../../../../../app/core/shared/site.model';
import { getCommunityModuleRoute } from '../../../../../../../app/community-page/community-page-routing-paths';
import { getCollectionModuleRoute } from '../../../../../../../app/collection-page/collection-page-routing-paths';
import { getItemModuleRoute } from '../../../../../../../app/item-page/item-page-routing-paths';
import { getStaticPageModuleRoute } from '../../../../../../static-page/static-page-routing-paths';
import {
  SelectorActionType
} from '../../../../../../../app/shared/dso-selector/modal-wrappers/dso-selector-modal-wrapper.component';

/**
 * Component to wrap the static page selector inside a modal
 * Used to edit an existing static page
 */
@Component({
  selector: 'ds-edit-static-page-selector',
  templateUrl: './edit-static-page-selector.component.html',
})
export class EditStaticPageSelectorComponent {

  action = SelectorActionType.EDIT;

  @Input() scopeDso: DSpaceObject;
  @Input() currentStaticPage: StaticPageContentObject;

  constructor(protected activeModal: NgbActiveModal, private router: Router) {
  }

  /**
   * Select the static page and navigate to it's edit page
   */
  selectObject(staticPage: StaticPage) {
    this.close();
    this.navigate(staticPage);
  }

  /**
   * Navigate to the edit page of the provided static page
   * @param staticPage
   */
  navigate(staticPage: StaticPage) {
    if (hasValue(this.scopeDso) && (this.scopeDso as any).type !== Site.type.value) {
      this.router.navigate([this.getPath(this.scopeDso), this.scopeDso.uuid, 'pages', staticPage.name, staticPage.language, 'edit']);
    } else {
      this.router.navigate([getStaticPageModuleRoute(), staticPage.name, staticPage.language, 'edit']);
    }
  }

  /**
   * Get the  path of the scope DSO
   * @param dso
   */
  getPath(dso: DSpaceObject) {
    if ((dso as any).type === Community.type.value) {
      return getCommunityModuleRoute();
    } else if ((dso as any).type === Collection.type.value) {
      return getCollectionModuleRoute();
    } else if ((dso as any).type === Item.type.value) {
      return getItemModuleRoute();
    }
    return null;
  }

  /**
   * Close the modal
   */
  close() {
    this.activeModal.close();
  }
}
