<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ul class="navbar-nav" [ngClass]="{'mr-auto': (isXsOrSm$ | async)}">
<!--    <li *ngIf="!(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)" class="nav-item"-->
<!--        (click)="$event.stopPropagation();">-->
<!--        <div ngbDropdown #loginDrop display="dynamic" placement="bottom-right" class="d-inline-block" @fadeInOut>-->
<!--            <a href="javascript:void(0);" class="dropdownLogin px-1 " [attr.aria-label]="'nav.login' |translate" (click)="$event.preventDefault()" [attr.data-test]="'login-menu' | dsBrowserOnly" ngbDropdownToggle>-->
<!--                {{ 'nav.login' | translate }}-->
<!--            </a>-->
<!--            <div class="loginDropdownMenu" [ngClass]="{'pl-3 pr-3': (loading | async)}" ngbDropdownMenu-->
<!--                 [attr.aria-label]="'nav.login' |translate">-->
<!--                <ds-themed-log-in-->
<!--                        [isStandalonePage]="false"></ds-themed-log-in>-->
<!--            </div>-->
<!--        </div>-->
<!--    </li>-->
<!--    <li *ngIf="!(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">-->
<!--        <a routerLink="/login" routerLinkActive="active" class="loginLink px-1">-->
<!--            {{ 'nav.login' | translate }}<span class="sr-only">(current)</span>-->
<!--        </a>-->
<!--    </li>-->
    <li *ngIf="(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)" class="nav-item">
        <div ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block" @fadeInOut>
            <a href="javascript:void(0);" role="button" [attr.aria-label]="'nav.logout' |translate" (click)="$event.preventDefault()" [title]="'nav.logout' | translate" class="px-1" [attr.data-test]="'user-menu' | dsBrowserOnly" ngbDropdownToggle>
                <i class="fas fa-user-circle fa-lg fa-fw"></i></a>
            <div class="logoutDropdownMenu" ngbDropdownMenu [attr.aria-label]="'nav.logout' |translate">
                <ds-user-menu></ds-user-menu>
            </div>
        </div>
    </li>
    <li *ngIf="(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
        <a id="logoutLink" role="button" [attr.aria-label]="'nav.logout' |translate" [title]="'nav.logout' | translate" routerLink="/logout" routerLinkActive="active" class="px-1">
            <i class="fas fa-user-circle fa-lg fa-fw"></i>
            <span class="sr-only">(current)</span>
        </a>
    </li>
</ul>
