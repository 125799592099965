/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ObjectCacheActionTypes } from '../../../../app/core/cache/object-cache.actions';
import { type } from '../../../../app/shared/ngrx/type';
import { Action } from '@ngrx/store';
import { INotification } from '../../../../app/shared/notifications/models/notification.model';
import { ObjectUpdatesAction } from '../../../../app/core/data/object-updates/object-updates.actions';
import { Identifiable } from '../../../../app/core/data/object-updates/object-updates.reducer';
import { PatchOperationService } from '../../../../app/core/data/object-updates/patch-operation-service/patch-operation.service';
import { GenericConstructor } from '../../../../app/core/shared/generic-constructor';

/**
 * The list of AtmireObjectUpdatesAction type definitions
 */
export const AtmireCustomOrderObjectUpdatesActionTypes = {
  ...ObjectCacheActionTypes,
  ATMIRE_INITIALIZE_FIELDS: type('dspace/core/cache/object-updates/ATMIRE_INITIALIZE_FIELDS'),
  ATMIRE_ADD_PAGE_TO_CUSTOM_ORDER: type('dspace/core/cache/object-updates/ATMIRE_ADD_PAGE_TO_CUSTOM_ORDER'),
  ATMIRE_MOVE: type('dspace/core/cache/object-updates/ATMIRE_MOVE'),
};

/**
 * The list type definitions for actions affecting a single object update
 */
export const AtmireSingleObjectUpdatesActionTypes = {
  ...ObjectCacheActionTypes,
  ATMIRE_DISCARD_ALL: type('dspace/core/cache/object-updates/ATMIRE_DISCARD_ALL'),
};

/**
 * The list type definitions for actions affecting multiple object updates
 */
export const AtmireMultiObjectUpdatesActionTypes = {
  ATMIRE_DISCARD: type('dspace/core/cache/object-updates/ATMIRE_DISCARD'),
};

/**
 * The list of AtmireObjectUpdatesAction type definitions
 */
export const AtmireObjectUpdatesActionTypes = {
  ...AtmireCustomOrderObjectUpdatesActionTypes,
  ...AtmireSingleObjectUpdatesActionTypes,
  ...AtmireMultiObjectUpdatesActionTypes
};

/* tslint:disable:max-classes-per-file */
/* Custom Atmire Actions */

/**
 * An ngrx action to discard all existing updates in the ObjectUpdates state for a certain page url
 */
export class AtmireDiscardListObjectUpdatesAction implements Action {
  type = AtmireObjectUpdatesActionTypes.ATMIRE_DISCARD;
  payload: {
    urls: string[],
    notification: INotification,
  };

  /**
   * Create a new DiscardObjectUpdatesAction
   *
   *    the unique url of the page for which the changes should be discarded
   * @param notification The notification that is raised when changes are discarded
   */
  constructor(
    notification: INotification,
    ...urls: string[]
  ) {
    this.payload = {urls, notification};
  }
}

/**
 * An ngrx action to discard ALL existing updates in the ObjectUpdates state for a certain page url
 */
export class AtmireDiscardObjectAllUpdatesAction implements Action {
  type = AtmireObjectUpdatesActionTypes.ATMIRE_DISCARD_ALL;
  payload: {
    url: string,
    notification: INotification,
  };

  /**
   * Create a new DiscardObjectUpdatesAction
   *
   *    the unique url of the page for which the changes should be discarded
   * @param notification The notification that is raised when changes are discarded
   */
  constructor(
    notification: INotification,
    url: string
  ) {
    this.payload = {url, notification};
  }
}

/**
 * Enum that represents the different types of updates that can be performed on a field in the ObjectUpdates store
 */
export enum FieldChangeType {
  UPDATE = 0,
  ADD = 1,
  REMOVE = 2,
  MOVE = 3
}

/**
 * An ngrx action to initialize a new page's fields in the ObjectUpdates state
 */
export class AtmireInitializeFieldsAction implements Action {
  type = AtmireCustomOrderObjectUpdatesActionTypes.ATMIRE_INITIALIZE_FIELDS;
  payload: {
    url: string,
    fields: Identifiable[],
    lastModified: Date,
    order: string[],
    pageSize: number,
    page: number,
    patchOperationService?: GenericConstructor<PatchOperationService>
  };

  /**
   * Create a new InitializeFieldsAction
   *
   * @param url
   *    the unique url of the page for which the fields are being initialized
   * @param fields The identifiable fields of which the updates are kept track of
   * @param lastModified The last modified date of the object that belongs to the page
   * @param patchOperationService A {@link PatchOperationService} used for creating a patch
   */
  constructor(
    url: string,
    fields: Identifiable[],
    lastModified: Date,
    order: string[] = [],
    pageSize: number = 9999,
    page: number = 0,
    patchOperationService?: GenericConstructor<PatchOperationService>
  ) {
    this.payload = { url, fields, lastModified, order, pageSize, page, patchOperationService };
  }
}

/**
 * An ngrx action to initialize a new page's fields in the ObjectUpdates state
 */
export class AtmireAddPageToCustomOrderAction implements Action {
  type = AtmireCustomOrderObjectUpdatesActionTypes.ATMIRE_ADD_PAGE_TO_CUSTOM_ORDER;
  payload: {
    url: string,
    fields: Identifiable[],
    order: string[],
    page: number
  };

  /**
   * Create a new AddPageToCustomOrderAction
   *
   * @param url The unique url of the page for which the fields are being added
   * @param fields The identifiable fields of which the updates are kept track of
   * @param order A custom order to keep track of objects moving around
   * @param page The page to populate in the custom order
   */
  constructor(
    url: string,
    fields: Identifiable[],
    order: string[] = [],
    page: number = 0
  ) {
    this.payload = { url, fields, order, page };
  }
}

/**
 * An ngrx action to remove a single field update in the ObjectUpdates state for a certain page url and field uuid
 */
export class AtmireMoveFieldUpdateAction implements Action {
  type = AtmireCustomOrderObjectUpdatesActionTypes.ATMIRE_MOVE;
  payload: {
    url: string,
    from: number,
    to: number,
    fromPage: number,
    toPage: number,
    field?: Identifiable
  };

  /**
   * Create a new MoveObjectUpdatesAction
   *
   * @param url
   *    the unique url of the page for which a field's change should be removed
   * @param from      The index of the object to move
   * @param to        The index to move the object to
   * @param fromPage  The page to move the object from
   * @param toPage    The page to move the object to
   * @param field     Optional field to add to the fieldUpdates list (useful when we want to track updates across multiple pages)
   */
  constructor(
    url: string,
    from: number,
    to: number,
    fromPage: number,
    toPage: number,
    field?: Identifiable
  ) {
    this.payload = { url, from, to, fromPage, toPage, field };
  }
}

/* tslint:enable:max-classes-per-file */

/**
 * A type to encompass all ObjectUpdatesActions affecting the custom order
 */
export type AtmireCustomOrderObjectUpdatesAction
  = ObjectUpdatesAction
  | AtmireInitializeFieldsAction
  | AtmireMoveFieldUpdateAction
  | AtmireAddPageToCustomOrderAction;

/**
 * A type to encompass all ObjectUpdatesActions affecting a single object update
 */
export type AtmireSingleObjectUpdatesAction
  = ObjectUpdatesAction
  | AtmireDiscardObjectAllUpdatesAction;

/**
 * A type to encompass all ObjectUpdatesActions affecting multiple object updates
 */
export type AtmireMultiObjectUpdatesAction
  = AtmireDiscardListObjectUpdatesAction;

/**
 * A type to encompass all ObjectUpdatesActions
 */
export type AtmireObjectUpdatesAction
  = AtmireSingleObjectUpdatesAction
  | AtmireMultiObjectUpdatesAction
  | AtmireCustomOrderObjectUpdatesAction;
