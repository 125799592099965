/**
 * Represents a search filter
 */

export class SearchFilter {
  key: string;
  values: string[];
  operator: string;

  constructor(key: string, values: string[], operator?: string) {
    this.key = key;
    this.values = values;
    this.operator = operator;
  }

  /**
   * Resolve the values of this filter to an array of (value),(operator) strings
   * Only appends the {@link operator} if the value doesn't already include one
   */
  public get parameterValues(): string[] {
    return this.values.map((value) =>
      value.includes(',') ? `${value}` : value + (this.operator ? ',' + this.operator : '')
    );
  }
}
