/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ThumbnailComponent as BaseComponent } from '../../../../app/thumbnail/thumbnail.component';

@Component({
  selector: 'ds-thumbnail',
  styleUrls: ['./thumbnail.component.scss', '../../../../app/thumbnail/thumbnail.component.scss'],
  // templateUrl: './thumbnail.component.html'
  templateUrl: '../../../../app/thumbnail/thumbnail.component.html',
  preserveWhitespaces: false,
})
export class ThumbnailComponent extends BaseComponent {
  ngOnInit() {
    super.ngOnChanges();
  }
}
