<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <ds-dso-edit-menu></ds-dso-edit-menu>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['publicationvolume.volumeNumber']"
      [label]="'journalvolume.page.volume'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['creativework.datePublished']"
      [label]="'journalvolume.page.issuedate'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalOfVolume'"
      [label]="'relationships.isSingleJournalOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isIssueOfJournalVolume'"
      [label]="'relationships.isIssueOf' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'journalvolume.page.description'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
