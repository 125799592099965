/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, autoserializeAs, deserialize } from 'cerialize';
import { CacheableObject } from '../../app/core/cache/object-cache.reducer';
import { ResourceType } from '../../app/core/shared/resource-type';
import { Observable } from 'rxjs';
import { RemoteData } from '../../app/core/data/remote-data';
import { BitstreamFormat } from '../../app/core/shared/bitstream-format.model';
import { link, typedObject } from '../../app/core/cache/builders/build-decorators';
import { BITSTREAM_FORMAT } from '../../app/core/shared/bitstream-format.resource-type';
import { excludeFromEquals } from '../../app/core/utilities/equals.decorators';
import { HALLink } from '../../app/core/shared/hal-link.model';
import { STATIC_PAGE } from './static-page.resource-type';

/**
 * Model class for a Static Page
 */
@typedObject
export class StaticPage implements CacheableObject {
  static type = STATIC_PAGE;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  /**
   * The human readable identifier for this Static Page
   */
  @autoserializeAs(String, 'id')
  uuid: string;

  /**
   * The universally unique identifier for this Static Page
   */
  @autoserialize
  id: string;

  /**
   * The name for this Static Page
   */
  @autoserialize
  name: string;

  /**
   * The title for this Static Page
   */
  @autoserialize
  title: string;

  /**
   * The size in bytes for this Static Page
   */
  @autoserialize
  sizeBytes: number;

  /**
   * The language for this Static Page
   */
  @autoserialize
  language: string;

  /**
   * The URL to retrieve this Static Page's content
   */
  content: string;

  /**
   * An array of Bitstream Format of this Static Page
   */
  @link(BITSTREAM_FORMAT)
  format: Observable<RemoteData<BitstreamFormat>>;

  @deserialize
  _links: {
    self: HALLink,
    dspaceobject: HALLink,
    content: HALLink,
    format: HALLink,
  };

}
