/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Inject, Injectable } from '@angular/core';
import * as Sharect from '@atmire-external/sharect';

import { APP_CONFIG, AppConfig } from '../../config/app-config.interface';
import { SelectionSharingService } from './selection-sharing.service';
import { hasValue } from '../../app/shared/empty.util';

/**
 * Show a popup with social media sharing buttons when selecting text
 * https://estevanmaito.github.io/sharect/
 *
 * Background/icon colors should be set via SCSS instead of `backgroundColor` / `iconColor`
 */
@Injectable()
export class BrowserSelectionSharingService implements SelectionSharingService {
  constructor(
    @Inject((APP_CONFIG)) protected appConfig: AppConfig,
  ) {
  }

  /**
   * Initialize the selection sharing feature
   */
  public init(): void {
    if (hasValue(this.appConfig.atmire?.socialSharing?.selection)) {
      this.sharect
          .config({
            selectableElements: this.appConfig.atmire.socialSharing.selection.selectableElements,
            twitter: this.appConfig.atmire.socialSharing.selection.twitter,
            facebook: this.appConfig.atmire.socialSharing.selection.facebook,
          })
          .appendCustomShareButtons(
            this.appConfig.atmire.socialSharing.selection.customButtons || []
          )
          .init();
    } else {
      console.warn('Selection sharing is disabled (missing from configuration: atmire.socialSharing.selection)');
    }
  }

  private get sharect(): any {
    // wrapped in a getter so it can be mocked
    return Sharect;
  }
}
