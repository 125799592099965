/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Directive, ElementRef, AfterViewChecked, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dsFooterCms]',
})
export class FooterCmsDirective implements AfterViewChecked {
  hooked = false;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngAfterViewChecked() {
    const link = this.elementRef.nativeElement.querySelector('.worldwide-offices-toggle');
    if (!this.hooked && link) {
      this.hooked = true;
      const caret = this.renderer.createElement('i');
      this.renderer.appendChild(link, caret);

      this.renderer.addClass(caret, 'fas');
      this.renderer.addClass(caret, 'fa-chevron-up');
      const dropdown = link.parentElement.querySelector('ul');
      link.closest('body').addEventListener('click', () => {
        if (dropdown.classList.contains('d-flex')) {
          this.renderer.removeClass(dropdown, 'd-flex');
          this.renderer.removeClass(caret, 'fa-chevron-down');
          this.renderer.addClass(caret, 'fa-chevron-up');
        }
      });
      link.addEventListener('click', (event) => {
        event.preventDefault();
        if (!dropdown.classList.contains('d-flex')) {
          event.stopPropagation();
          this.renderer.addClass(dropdown, 'd-flex');
          this.renderer.addClass(caret, 'fa-chevron-down');
          this.renderer.removeClass(caret, 'fa-chevron-up');
        }
        return false;
      });

    }
  }

}
