import { Component, Inject, Injector } from '@angular/core';
import { MenuID, MenuItemType } from 'src/app/shared/menu/initial-menus-state';
import { rendersSectionForMenu } from 'src/app/shared/menu/menu-section.decorator';
import { MenuSectionComponent } from 'src/app/shared/menu/menu-section/menu-section.component';
import { MenuService } from '../../../menu/menu.service';
import { MenuSection } from '../../../menu/menu.reducer';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { hasValue } from '../../../empty.util';
import { MenuItemModel } from '../../../menu/menu-item/models/menu-item.model';

/**
 * Represents an expandable section in the dso edit menus
 */
@Component({
  /* tslint:disable:component-selector */
  selector: 'ds-dso-edit-menu-expandable-section',
  templateUrl: './dso-edit-menu-expandable-section.component.html',
  styleUrls: ['./dso-edit-menu-expandable-section.component.scss'],
})
@rendersSectionForMenu(MenuID.DSO_EDIT, true)
export class DsoEditMenuExpandableSectionComponent extends MenuSectionComponent {

  menuID: MenuID = MenuID.DSO_EDIT;
  itemModel;

  renderIcons$: Observable<boolean>;

  constructor(
    @Inject('sectionDataProvider') menuSection: MenuSection,
    protected menuService: MenuService,
    protected injector: Injector,
    protected router: Router,
  ) {
    super(menuSection, menuService, injector);
    this.itemModel = menuSection.model;
  }

  ngOnInit(): void {
    this.menuService.activateSection(this.menuID, this.section.id);
    super.ngOnInit();

    this.renderIcons$ = this.subSections$.pipe(
      map((sections: MenuSection[]) => {
        return sections.some(section => hasValue(section.icon));
      }),
    );
  }

  getCustomClasses(model: MenuItemModel) {
    if (model?.type === MenuItemType.SECTION_HEADER) {
      if (model?.disabled) {
        return 'section-header section-header-disabled';
      }
      return 'section-header';
    }
    return '';
  }
}
