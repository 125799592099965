<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="form-group w-100 pr-2 pl-2">
    <input type="search"
           class="form-control"
           (click)="$event.stopPropagation();"
           placeholder="{{'static-pages.selector.page.placeholder' | translate }}"
           [formControl]="input" dsAutoFocus (keyup.enter)="selectSingleResult()">
</div>
<div class="dropdown-divider"></div>
<div class="scrollable-menu list-group">
    <button class="list-group-item list-group-item-action border-0 disabled"
            *ngIf="(listEntries$ | async)?.payload.page.length == 0">
        {{'static-pages.selector.no-results' | translate}}
    </button>                                     
    <button *ngFor="let listEntry of (listEntries$ | async)?.payload.page"
            class="list-group-item list-group-item-action border-0 list-entry"
            title="{{ listEntry.name }}"
            (click)="onSelect.emit(listEntry)" #listEntryElement>
        <span class="badge badge-secondary">{{ listEntry.language }}</span> {{ listEntry.name }}
    </button>
</div>
