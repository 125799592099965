<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="shouldRenderFeaturedItems$" class="jumbotron jumbotron-fluid featured-items">
    <div class="container">
        <h2 class="serif-header">{{'home.featured-items.head' | translate}}</h2>
        <div class="row">
            <ng-container *ngFor="let itemRD$ of featuredItems$ | async; let index = index">
                <ng-container *ngVar="itemRD$ | async as itemRD">
                    <ng-container *ngIf="!itemRD.hasFailed">
                        <div
                             class="col-12 col-sm-6 col-md-4 mb-3 fadeInStaggerPart">
                            <ng-container *ngIf="!itemRD.hasCompleted">
                                <ds-featured-item-loading-element
                                        [index]="index"></ds-featured-item-loading-element>
                            </ng-container>
                            <ng-container *ngIf="itemRD.hasSucceeded">
                                <ds-listable-object-component-loader
                                        [viewMode]="ViewMode.DetailedListElement"
                                        [context]="Context.HomePageFeatured"
                                        [object]="itemRD.payload"
                                        [index]="index">
                                </ds-listable-object-component-loader>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
