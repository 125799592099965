<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div>
    <div class="modal-header">{{'static-pages.selector.'+ action + '.head' | translate}}
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <button class="btn btn-outline-primary btn-lg btn-block" (click)="selectObject(undefined)">{{'static-pages.selector.create.top-level' | translate}}</button>
        <h3 class="position-relative py-1 my-3 font-weight-normal">
            <hr>
            <div id="create-scope-or-separator" class="text-center position-absolute w-100">
                <span class="px-4 bg-white">or</span>
            </div>
        </h3>

        <h5 class="px-2">{{'static-pages.selector.create.sub-level' | translate}}</h5>
        <ds-scope-selector [currentDSOId]="currentDSpaceObject?.uuid" (onSelect)="selectObject($event)"></ds-scope-selector>
    </div>
</div>