/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { hasValue, isNotEmpty } from '../../app/shared/empty.util';
import { URLCombiner } from '../../app/core/url-combiner/url-combiner';
import { FindListOptions } from '../../app/core/data/request.models';
import { RequestParam } from '../../app/core/cache/models/request-param.model';
import { SortOptions } from '../../app/core/cache/models/sort-options.model';

/**
 * This model class represents all parameters that can be used to search for a static page
 */
export class StaticPageSearchOptions {
  uuid: string;
  name?: string;
  format?: string[];
  language?: string;
  elementsPerPage?: number;
  currentPage?: number;

  constructor(options: { uuid: string, name?: string, format?: string[], language?: string, elementsPerPage?: number, currentPage?: number }) {
    this.uuid = options.uuid;
    this.name = options.name;
    this.format = options.format;
    this.language = options.language;
    this.elementsPerPage = options.elementsPerPage;
    this.currentPage = options.currentPage;
  }

  /**
   * Method to generate the URL that can be used request information about a search request
   * @param {string} url The URL to the REST endpoint
   * @param {string[]} args A list of query arguments that should be included in the URL
   * @returns {string} URL with all search options and passed arguments as query parameters
   */
  toRestUrl(url: string, args: string[] = []): string {

    if (isNotEmpty(this.name)) {
      args.push(`name=${this.name}`);
    }
    if (isNotEmpty(this.uuid)) {
      args.push(`uuid=${this.uuid}`);
    }
    if (isNotEmpty(this.format)) {
      this.format.forEach((format) => {
        args.push(`format=${format}`);
      });
    }
    if (isNotEmpty(this.language)) {
      args.push(`language=${this.language}`);
    }
    if (isNotEmpty(this.elementsPerPage)) {
      args.push(`size=${this.elementsPerPage}`);
    }
    if (isNotEmpty(args)) {
      url = new URLCombiner(url, `?${args.join('&')}`).toString();
    }
    return url;
  }

  toFindListOptions(): FindListOptions {
    const searchParams: RequestParam[] = [];
    if (isNotEmpty(this.name)) {
      searchParams.push(new RequestParam('name', this.name));
    }
    if (isNotEmpty(this.uuid)) {
      searchParams.push(new RequestParam('uuid', this.uuid));
    }
    if (isNotEmpty(this.format)) {
      this.format.forEach((format) => {
        searchParams.push(new RequestParam('format', format));
      });
    }
    if (isNotEmpty(this.language)) {
      searchParams.push(new RequestParam('language', this.language));
    }
    const findListOptions = new FindListOptions();
    if (hasValue(this.elementsPerPage)) {
      findListOptions.elementsPerPage = this.elementsPerPage;
    }
    if (hasValue(this.currentPage)) {
      findListOptions.currentPage = this.currentPage;
    }
    return Object.assign(findListOptions, {searchParams: searchParams});

  }

}
