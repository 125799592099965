/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractPaginatedDragAndDropListComponent } from '../abstract-paginated-drag-and-drop-list.component';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LinkService } from '../../../../app/core/cache/builders/link.service';
import { AtmireObjectUpdatesService } from '../../../core/data/object-updates/atmire-object-updates.service';
import { Observable } from 'rxjs/internal/Observable';
import { PageInfo } from '../../../../app/core/shared/page-info.model';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { hasValue, isEmpty } from '../../../../app/shared/empty.util';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { RequestService } from '../../../../app/core/data/request.service';
import { OrderType } from '../order-switch/order-type.enum';
import { CollectionElementLinkType } from '../../../../app/shared/object-collection/collection-element-link.type';
import { ObjectValuesPipe } from '../../../../app/shared/utils/object-values-pipe';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FieldUpdates } from '../../../../app/core/data/object-updates/object-updates.reducer';
import { getFirstSucceededRemoteData, paginatedListToArray } from '../../../../app/core/shared/operators';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';
import { PaginationService } from '../../../../app/core/pagination/pagination.service';

@Directive({
  selector: '[dsAbstractDragAndDropComColList]'
})
/**
 * Abstract component allowing drag-and-dropping of DSpaceObjects within a paginated list
 * Unlike AbstractPaginatedDragAndDropListComponent, this one allows for changes to be made until submit() is called,
 * instead of sending an update with each change.
 */
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractPaginatedDragAndDropDsoListComponent<T extends DSpaceObject> extends AbstractPaginatedDragAndDropListComponent<T> implements OnInit, OnChanges {
  @Input() orderable: boolean;
  pageInfo: Observable<PageInfo>;
  linkTypes = CollectionElementLinkType;

  /**
   * A list of pages that have been initialized in the field-update store
   */
  initializedPages: number[] = [];

  /**
   * An object storing information about an update that should be fired whenever fireToUpdate is called
   */
  toUpdate: {
    fromIndex: number,
    toIndex: number,
    fromPage: number,
    toPage: number,
    field?: T
  };

  constructor(protected objectUpdatesService: AtmireObjectUpdatesService,
              protected elRef: ElementRef,
              protected route: ActivatedRoute,
              protected linkService: LinkService,
              protected translateService: TranslateService,
              protected notificationService: NotificationsService,
              protected requestService: RequestService,
              protected objectValuesPipe: ObjectValuesPipe,
              protected paginationService: PaginationService) {
    super(objectUpdatesService, elRef, objectValuesPipe, paginationService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Initialize the field-updates in the store
   * This method ensures (new) pages displayed are automatically added to the field-update store when the objectsRD updates
   */
  initializeUpdates(): void {
    this.updates$ = this.objectsRD$.pipe(
      paginatedListToArray(),
      tap((objects: T[]) => {
        // Pages in the field-update store are indexed starting at 0 (because they're stored in an array of pages)
        const updatesPage = this.currentPage$.value.currentPage - 1;
        if (isEmpty(this.initializedPages)) {
          // No updates have been initialized yet for this list, initialize the first page
          this.objectUpdatesService.initializeWithCustomOrder(this.url, objects, new Date(), this.pageSize, updatesPage);
          this.initializedPages.push(updatesPage);
        } else if (this.initializedPages.indexOf(updatesPage) < 0) {
          // Updates were initialized for this list, but not the page we're on. Add the current page to the field-update store for this list
          this.objectUpdatesService.addPageToCustomOrder(this.url, objects, updatesPage);
          this.initializedPages.push(updatesPage);
        }

        // The new page is loaded into the store, check if there are any updates waiting and fire those as well
        this.fireToUpdate();
      }),
      switchMap((objects: T[]) => this.objectUpdatesService.getFieldUpdatesByCustomOrder(this.url, objects, this.currentPage$.value.currentPage - 1))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasValue(changes.orderable)) {
      if (changes.orderable.currentValue !== OrderType.Manual) {
        this.objectUpdatesService.discardAllFieldUpdates(this.url, undefined);
      }
    }
  }

  /**
   * Initialize the bitstreams observable depending on currentPage$
   */
  initializeObjectsRD(): void {
    this.objectsRD$ = this.getObjectListRD$();
    this.pageInfo = this.objectsRD$.pipe(
      getFirstSucceededRemoteData(),
      filter((objectsRD) => hasValue(objectsRD.payload)),
      map((objectsRD) => objectsRD.payload.pageInfo));
  }

  abstract getObjectListRD$(): Observable<RemoteData<PaginatedList<T>>>;

  abstract submit();

  /**
   * An object was moved, send updates to the store.
   * When the object is dropped on a page within the pagination of this component, the object moves to the top of that
   * page and the pagination automatically loads and switches the view to that page.
   * @param event
   */
  drop(event: CdkDragDrop<any>) {
    // Check if the user is hovering over any of the pagination's pages at the time of dropping the object
    const droppedOnElement = this.elRef.nativeElement.querySelector('.page-item:hover');
    if (hasValue(droppedOnElement) && hasValue(droppedOnElement.textContent)) {
      // The user is hovering over a page, fetch the page's number from the element
      const page = Number(droppedOnElement.textContent);
      if (hasValue(page) && !Number.isNaN(page)) {
        const id = event.item.element.nativeElement.id;
        this.updates$.pipe(take(1)).subscribe((updates: FieldUpdates) => {
          const field = hasValue(updates[id]) ? updates[id].field : undefined;
          this.toUpdate = Object.assign({
            fromIndex: event.previousIndex,
            toIndex: 0,
            fromPage: this.currentPage$.value.currentPage - 1,
            toPage: page - 1,
            field
          });
          // Switch to the dropped-on page and force a page update for the pagination component
          this.paginationService.updateRoute(this.options.id, { page });
          this.paginationComponent.doPageChange(page);
          if (this.initializedPages.indexOf(page - 1) >= 0) {
            // The page the object is being dropped to has already been loaded before, directly fire an update to the store.
            // For pages that haven't been loaded before, the updates$ observable will call fireToUpdate after the new page
            // has loaded
            this.fireToUpdate();
          }
        });
      }
    } else {
      this.objectUpdatesService.saveMoveFieldUpdate(this.url, event.previousIndex, event.currentIndex, this.currentPage$.value.currentPage - 1, this.currentPage$.value.currentPage - 1);
    }
  }

  /**
   * Method checking if there's an update ready to be fired. Send out a MoveFieldUpdate to the store if there's an
   * update present and clear the update afterwards.
   */
  fireToUpdate() {
    if (hasValue(this.toUpdate)) {
      this.objectUpdatesService.saveMoveFieldUpdate(this.url, this.toUpdate.fromIndex, this.toUpdate.toIndex, this.toUpdate.fromPage, this.toUpdate.toPage, this.toUpdate.field);
      this.toUpdate = undefined;
    }
  }
}
