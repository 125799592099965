/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * Model class for a Static Page Content Object
 * This object acts like a Static Page Object with the content loaded
 */
import { StaticPage } from '../shared/static-page.model';
import { ResourceType } from '../../app/core/shared/resource-type';
import { UrlToUuidSerializer } from '../core/cache/url-to-uuid-serializer';

export class StaticPageContentObject extends StaticPage {

  static type = new ResourceType('staticpagecontentobject');

  /**
   * The content string of this Static Page Content Object
   */
  contentString: string;
  sanitzedContent;

  /**
   * Indicates whether the content has been updated and needs to be pushed to the server
   */
  hasContentUpdate: boolean;

  content: string;

  dspaceobject: string;

  dspaceobjectUuid: string;

  /**
   * Initializes a Static Page Content Object based on a static page object
   * @param staticPage
   */
  initFromStaticPage(staticPage: StaticPage) {
    this.id = staticPage.id;
    this.uuid = staticPage.uuid;
    this.name = staticPage.name;
    this.title = staticPage.title;
    this.sizeBytes = staticPage.sizeBytes;
    this.language = staticPage.language;
    this.content = staticPage._links.content.href;
    this.format = staticPage.format;
    this.dspaceobject = staticPage._links.dspaceobject.href;
    this.dspaceobjectUuid = new UrlToUuidSerializer().Deserialize(this.dspaceobject);
  }

}
