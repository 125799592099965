<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="card shadow rounded-0 border-left-0 border-bottom-0 border-right-0 featured-items-border featured-items-border-{{index%3}}">
    <div *ngIf="largeThumbnail" class="card-img-top full-width">
        <div>
            <ds-thumbnail [limitWidth]="false">
            </ds-thumbnail>
        </div>
    </div>
    <div class="card-body text-dark bg-light">
        <div class="row">
            <div class="col-auto pr-0">
                <div class="placeholder-thumbnail"></div>
            </div>
            <div class="col title-card-section mb-1">
                <div class="placeholder-section">
                    <div class="placeholder-line placeholder-line-base"></div>
                    <div class="placeholder-line placeholder-line-base"></div>
                    <div class="placeholder-line placeholder-line-base"></div>
                </div>
            </div>
        </div>
        <div class="card-section author-card-section mb-1">
            <div class="placeholder-section">
                <div class="placeholder-line placeholder-line-base"></div>
                <div class="placeholder-line placeholder-line-base"></div>
            </div>
        </div>
        <div class="card-text abstract-card-section mb-1">
            <div class="placeholder-section">
                <div class="placeholder-line placeholder-line-sm"></div>
                <div class="placeholder-line placeholder-line-sm"></div>
                <div class="placeholder-line placeholder-line-sm"></div>
                <div class="placeholder-line placeholder-line-sm"></div>
            </div>
        </div>

        <div class="card-text date-card-section">
            <div class="placeholder-date float-right">
                <span class="placeholder-line placeholder-line-base"></span>
            </div>
        </div>
    </div>
</div>
