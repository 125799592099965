<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<a *ngIf="!isAbsoluteLink" class="nav-item nav-link"
   [ngClass]="{ 'disabled': !hasLink }"
   [attr.aria-disabled]="!hasLink"
   [title]="item.text | translate"
   [routerLink]="getRouterLink()"
   (click)="$event.stopPropagation()"
   (keyup.space)="navigate($event)"
   (keyup.enter)="navigate($event)"
   href="javascript:void(0);"
>{{item.text | translate}}</a>

<a *ngIf="isAbsoluteLink" class="nav-item nav-link"
   [ngClass]="{'disabled': !hasLink}"
   [attr.aria-disabled]="!hasLink"
   [title]="item.text | translate"
   href="{{item.link}}"
   target="_blank"
   (click)="navigateToAbsoluteUrl()"
   (keyup.space)="navigateToAbsoluteUrl()"
   (keyup.enter)="navigateToAbsoluteUrl()"
>{{item.text | translate}}</a>
