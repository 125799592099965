/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Action, StoreConfig, StoreModule } from '@ngrx/store';
import { isNotEmpty } from '../../app/shared/empty.util';
import { coreEffects } from '../../app/core/core.effects';
import { coreReducers, CoreState } from '../../app/core/core.reducers';
import { storeModuleConfig } from '../../app/app.reducer';
import { CoreModule } from '../../app/core/core.module';
import { AtmireBrowseService } from './browse/atmire-browse.service';

const IMPORTS = [
  CommonModule,
  StoreModule.forFeature('core', coreReducers, storeModuleConfig as StoreConfig<CoreState, Action>),
  EffectsModule.forFeature(coreEffects),
  CoreModule,
];

const DECLARATIONS = [];

const EXPORTS = [];

const PROVIDERS = [
  AtmireBrowseService,
];

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const models = [];

@NgModule({
  imports: [
    ...IMPORTS
  ],
  declarations: [
    ...DECLARATIONS
  ],
  exports: [
    ...EXPORTS
  ],
  providers: [
    ...PROVIDERS
  ]
})

export class AtmireCoreModule {
  static forRoot(): ModuleWithProviders<AtmireCoreModule> {
    return {
      ngModule: AtmireCoreModule,
      providers: [
        ...PROVIDERS
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AtmireCoreModule) {
    if (isNotEmpty(parentModule)) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
