/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DSpaceObjectDataService } from '../../../app/core/data/dspace-object-data.service';
import { MenuService } from '../../../app/shared/menu/menu.service';
import { AuthorizationDataService } from '../../../app/core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DsoVersioningModalService
} from '../../../app/shared/dso-page/dso-versioning-modal-service/dso-versioning-modal.service';
import { DSOEditMenuResolver } from '../../../app/shared/dso-page/dso-edit-menu.resolver';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { Item } from '../../../app/core/shared/item.model';
import { FeatureID } from '../../../app/core/data/feature-authorization/feature-id';
import { map } from 'rxjs/operators';
import { MenuItemType } from '../../../app/shared/menu/initial-menus-state';
import { OnClickMenuItemModel } from '../../../app/shared/menu/menu-item/models/onclick.model';
import { Community } from '../../../app/core/shared/community.model';
import { Collection } from '../../../app/core/shared/collection.model';
import { URLCombiner } from '../../../app/core/url-combiner/url-combiner';
import { LinkMenuItemModel } from '../../../app/shared/menu/menu-item/models/link.model';
import { TextMenuItemModel } from '../../../app/shared/menu/menu-item/models/text.model';
import { MenuSection } from '../../../app/shared/menu/menu.reducer';
import { getDSORoute } from '../../../app/app-routing-paths';

@Injectable()
export class AtmireDsoEditMenuResolver extends DSOEditMenuResolver {

  constructor(
    protected dSpaceObjectDataService: DSpaceObjectDataService,
    protected menuService: MenuService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected dsoVersioningModalService: DsoVersioningModalService,
  ) {
    super(dSpaceObjectDataService, menuService, authorizationService, modalService, dsoVersioningModalService);
  }

  /**
   * Return all the menus for a dso based on the route and state
   */
  getDsoMenus(dso, route, state) {
    return [
      this.getItemEditMenu(dso, state),
      this.getComColEditMenu(dso, state)
    ];
  }

  /**
   * Get item specific menus
   */
  protected getItemEditMenu(dso, state): Observable<MenuSection[]> {
    if (dso instanceof Item) {
      return combineLatest([
        this.authorizationService.isAuthorized(FeatureID.CanEditMetadata, dso.self),
        this.authorizationService.isAuthorized(FeatureID.CanCreateVersion, dso.self),
        this.dsoVersioningModalService.isNewVersionButtonDisabled(dso),
        this.dsoVersioningModalService.getVersioningTooltipMessage(dso, 'item.page.version.hasDraft', 'item.page.version.create')
      ]).pipe(
        map(([canEdit, canCreateVersion, disableVersioning, versionTooltip]) => {
          return [
            {
              id: 'edit-dso',
              active: false,
              visible: canEdit,
              parentID: 'item-menu',
              model: {
                type: MenuItemType.LINK,
                text: this.getDsoType(dso) + '.page.edit',
                link: new URLCombiner(getDSORoute(dso), 'edit', 'metadata').toString()
              } as LinkMenuItemModel,
              icon: 'pencil-alt',
            },
            {
              id: 'version-dso',
              active: false,
              visible: canCreateVersion,
              parentID: 'item-menu',
              model: {
                type: MenuItemType.ONCLICK,
                text: versionTooltip,
                disabled: disableVersioning,
                function: () => {
                  this.dsoVersioningModalService.openCreateVersionModal(dso);
                }
              } as OnClickMenuItemModel,
              icon: 'code-branch',
            },
            {
              id: 'item-menu',
              active: false,
              visible: canEdit,
              model: {
                type: MenuItemType.TEXT,
                text: 'item.page.options',
              } as TextMenuItemModel,
              icon: 'ellipsis-v',
              index: 0
            }
          ];
        }),
      );
    } else {
      return observableOf([]);
    }
  }
  /**
   * Get com/col specific menus
   */
  protected getComColEditMenu(dso, state): Observable<MenuSection[]> {
    if (dso instanceof Community || dso instanceof Collection) {
      return combineLatest([
        this.authorizationService.isAuthorized(FeatureID.CanEditMetadata, dso.self),
      ]).pipe(
        map(([canEdit]) => {
          return [
            {
              id: 'edit-dso',
              active: false,
              visible: canEdit,
              model: {
                type: MenuItemType.LINK,
                text: this.getDsoType(dso) + '.page.edit',
                link: new URLCombiner(getDSORoute(dso), 'edit', 'metadata').toString()
              } as LinkMenuItemModel,
              icon: 'pencil-alt',
              index: 0
            },
          ];

        })
      );
    } else {
      return observableOf([]);
    }
  }

}
