/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, OnInit } from '@angular/core';
import { HeaderComponent as BaseComponent } from '../../../client-rtl/app/header/header.component';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { StaticPageContentObject } from '../../../../app-atmire/static-page/static-page-content-object.model';
import { MenuService } from '../../../../app/shared/menu/menu.service';
import { AuthService } from '../../../../app/core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from '../../../../config/app-config.interface';
import { LocaleService } from '../../../../app/core/locale/locale.service';
import { Site } from 'src/app/core/shared/site.model';
import { StaticPageDataService } from '../../../../app-atmire/core/data/static-page-data.service';
import { SiteDataService } from '../../../../app/core/data/site-data.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { hasValue } from '../../../../app/shared/empty.util';
import { StaticPageSearchOptions } from '../../../../app-atmire/static-page/static-page-search-options.model';

/**
 * Represents the header with the logo and simple navigation
 */
@Component({
  selector: 'ds-header',
  styleUrls: ['header.component.scss'],
  // styleUrls: ['../../../../app/header/header.component.scss'],
  templateUrl: 'header.component.html',
  // templateUrl: '../../../../app/header/header.component.html',
})
export class HeaderComponent extends BaseComponent implements OnInit {
  headerRD$: Observable<RemoteData<StaticPageContentObject>>;
  site$: Observable<Site>;

  constructor(
    menuService: MenuService,
    protected authService: AuthService,
    protected translateService: TranslateService,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    protected localeService: LocaleService,
    protected staticPageDataService: StaticPageDataService,
    protected siteDataService: SiteDataService
  ) {
    super(menuService, authService, translateService, appConfig, localeService, staticPageDataService, siteDataService);
  }

  ngOnInit(): void {
    this.site$ = this.siteDataService.find();
    this.initHeaderPage();
  }

  initHeaderPage(): void {
    const staticPageObs = this.site$.pipe(
      filter((site) => hasValue(site)),
      map((site) => new StaticPageSearchOptions({
        uuid: site.uuid,
        name: 'header'
      })),
      mergeMap((staticPageSearchOptions: StaticPageSearchOptions) => this.staticPageDataService.searchStaticPages(staticPageSearchOptions)),
    );

    this.headerRD$ = this.staticPageDataService.retrieveContentFromPageListObs(staticPageObs);
  }
}
