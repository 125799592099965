/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { DataService } from '../../../app/core/data/data.service';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { FindListOptions, PostRequest, PutRequest } from '../../../app/core/data/request.models';
import { Observable } from 'rxjs';
import { map, take, distinctUntilChanged } from 'rxjs/operators';
import { CmsMenu } from '../../shared/cms-menu.model';
import { RemoteData } from '../../../app/core/data/remote-data';
import { isEmpty } from '../../../app/shared/empty.util';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { CMS_MENU } from '../../shared/cms-menu.resource-type';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { getFirstSucceededRemoteData, sendRequest } from '../../../app/core/shared/operators';
import { RequestParam } from '../../../app/core/cache/models/request-param.model';

/**
 * A service responsible for fetching/sending data from/to the REST API on the bitstreamformats endpoint
 */
@Injectable({
  providedIn: 'root'
})@dataService(CMS_MENU)
export class CmsMenuDataService extends DataService<CmsMenu> {

  protected linkPath = 'cmsmenus';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<CmsMenu>) {
    super();
  }

  /**
   * Get the endpoint for browsing bitstream formats
   * @param {FindAllOptions} options
   * @returns {Observable<string>}
   */
  getBrowseEndpoint(options: FindListOptions = {}, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }

  /**
   * Get the endpoint to update an existing bitstream format
   * @param formatId
   */
  public getUpdateEndpoint(formatId: string): Observable<string> {
    return this.getBrowseEndpoint().pipe(
      map((endpoint: string) => this.getIDHref(endpoint, formatId))
    );
  }

  /**
   * Get the endpoint to create a new bitstream format
   */
  public getCreateEndpoint(): Observable<string> {
    return this.getBrowseEndpoint();
  }

  updateCmsMenu(object: CmsMenu): Observable<RemoteData<CmsMenu>> {
    const requestId = this.requestService.generateRequestId();

    this.getUpdateEndpoint(object.id).pipe(
      distinctUntilChanged(),
      map((endpointURL: string) =>
        new PutRequest(requestId, endpointURL, object)),
      sendRequest(this.requestService)).subscribe();

    return this.rdbService.buildFromRequestUUID(requestId);
  }

  createCmsMenu(object: CmsMenu): Observable<RemoteData<CmsMenu>> {
    const requestId = this.requestService.generateRequestId();

    this.getCreateEndpoint().pipe(
      map((endpointURL: string) => {
        return new PostRequest(requestId, endpointURL, object);
      }),
      sendRequest(this.requestService)
    ).subscribe();

    return this.rdbService.buildFromRequestUUID(requestId);
  }

  public isUniqueLanguage(language: string, id: string): Observable<boolean> {
    return this.searchMenuByLanguage(language).pipe(
      getFirstSucceededRemoteData(),
      map((remoteData: RemoteData<PaginatedList<CmsMenu>>) => remoteData.payload),
      map((list: PaginatedList<CmsMenu>) => {
        if (!isEmpty(list.page) && list.page[0].language === language) {
          if (list.page[0].id === id) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
      take(1),
    );
  }

  public clearMenuList() {
    this.getBrowseEndpoint().pipe(
      take(1)
    ).subscribe(((href: string) => this.requestService.setStaleByHrefSubstring(href))
    );
    this.getSearchEndpoint('language').pipe(
      take(1)
    ).subscribe(((href: string) => this.requestService.setStaleByHrefSubstring(href))
    );
  }

  public searchMenuByLanguage(language: string): Observable<RemoteData<PaginatedList<CmsMenu>>> {
    const options = Object.assign(new FindListOptions(), {searchParams: [new RequestParam('language', language)]});
    return this.searchBy('language', options);
  }

  public isEnabled(): Observable<boolean> {
    return this.halService.isEnabledOnRestApi(this.linkPath);
  }
}
