/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable, Injector } from '@angular/core';
import { ObjectUpdatesService } from '../../../../app/core/data/object-updates/object-updates.service';
import { INotification } from '../../../../app/shared/notifications/models/notification.model';
import { AtmireDiscardListObjectUpdatesAction, AtmireDiscardObjectAllUpdatesAction } from './atmire-object-updates.actions';
import { Store, select, MemoizedSelector, createSelector } from '@ngrx/store';
import { CoreState } from '../../../../app/core/core.reducers';
import { ArrayMoveChangeAnalyzer } from '../../../../app/core/data/array-move-change-analyzer.service';
import { ObjectUpdatesEntry, OrderPage } from './atmire-object-updates.reducer';
import { FieldUpdates, Identifiable, ObjectUpdatesState } from '../../../../app/core/data/object-updates/object-updates.reducer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { hasValue, isEmpty, isNotEmpty } from '../../../../app/shared/empty.util';
import { AtmireAddPageToCustomOrderAction, AtmireInitializeFieldsAction, AtmireMoveFieldUpdateAction } from './atmire-object-updates.actions';
import { flatten } from '@angular/compiler';
import { GenericConstructor } from '../../../../app/core/shared/generic-constructor';
import { PatchOperationService } from '../../../../app/core/data/object-updates/patch-operation-service/patch-operation.service';
import { MoveOperation } from 'fast-json-patch/commonjs/core';
import { coreSelector } from '../../../../app/core/core.selectors';

function objectUpdatesStateSelector(): MemoizedSelector<CoreState, ObjectUpdatesState> {
  return createSelector(coreSelector, (state: CoreState) => state['cache/object-updates']);
}

function filterByUrlObjectUpdatesStateSelector(url: string): MemoizedSelector<CoreState, ObjectUpdatesEntry> {
  return createSelector(objectUpdatesStateSelector(), (state: any) => state[url]);
}

/**
 * Service that dispatches and reads from the ObjectUpdates' state in the store
 */
@Injectable(  {
  providedIn: 'root',
})
export class AtmireObjectUpdatesService extends ObjectUpdatesService {
  constructor(
    store: Store<CoreState>,
    injector: Injector,
    private comparator: ArrayMoveChangeAnalyzer<string>,
  ) {
    super(store, injector);
  }

  /**
   * Method to dispatch an DiscardObjectUpdatesAction to the store
   * @param undoNotification The notification which is should possibly be canceled
   * @param url The page's URL for which the changes should be discarded
   */
  discardListFieldUpdates(undoNotification: INotification, ...urls: string[]) {
    this.store.dispatch(new AtmireDiscardListObjectUpdatesAction(undoNotification, ...urls));
  }

  discardAllFieldUpdates(url: string, undoNotification: INotification) {
    this.store.dispatch(new AtmireDiscardObjectAllUpdatesAction(undoNotification, url));

  }

  initialize(url, fields: Identifiable[], lastModified: Date, patchOperationService?: GenericConstructor<PatchOperationService>): void {
    this.store.dispatch(new AtmireInitializeFieldsAction(url, fields, lastModified, undefined, 9999, 0, patchOperationService));
  }

  /**
   * Method to dispatch an InitializeFieldsAction to the store and keeping track of the order objects are stored
   * @param url The page's URL for which the changes are being mapped
   * @param fields The initial fields for the page's object
   * @param lastModified The date the object was last modified
   * @param pageSize The page size to use for adding pages to the custom order
   * @param page The first page to populate the custom order with
   */
  initializeWithCustomOrder(url, fields: Identifiable[], lastModified: Date, pageSize = 9999, page = 0): void {
    this.store.dispatch(new AtmireInitializeFieldsAction(url, fields, lastModified, fields.map((field) => field.uuid), pageSize, page));
  }

  /**
   * Method to dispatch an AddPageToCustomOrderAction, adding a new page to an already existing custom order tracking
   * @param url     The URL for which the changes are being mapped
   * @param fields  The fields to add a new page for
   * @param page    The page number (starting from index 0)
   */
  addPageToCustomOrder(url, fields: Identifiable[], page: number): void {
    this.store.dispatch(new AtmireAddPageToCustomOrderAction(url, fields, fields.map((field) => field.uuid), page));
  }

  /**
   * Request the ObjectUpdatesEntry state for a specific URL
   * @param url The URL to filter by
   */
  protected getObjectEntry(url: string): Observable<ObjectUpdatesEntry> {
    return this.store.pipe(select(filterByUrlObjectUpdatesStateSelector(url)));
  }
  /**
   * Method that combines the state's updates with the initial values (when there's no update),
   * sorted by their custom order to create a FieldUpdates object
   * @param url The URL of the page for which the FieldUpdates should be requested
   * @param initialFields The initial values of the fields
   * @param page The page to retrieve
   */
  getFieldUpdatesByCustomOrder(url: string, initialFields: Identifiable[], page = 0): Observable<FieldUpdates> {
    const objectUpdates = this.getObjectEntry(url);
    return objectUpdates.pipe(map((objectEntry) => {
      const fieldUpdates: FieldUpdates = {};
      if (hasValue(objectEntry) && hasValue(objectEntry.customOrder) && isNotEmpty(objectEntry.customOrder.newOrderPages) && page < objectEntry.customOrder.newOrderPages.length) {
        for (const uuid of objectEntry.customOrder.newOrderPages[page].order) {
          let fieldUpdate = objectEntry.fieldUpdates[uuid];
          if (isEmpty(fieldUpdate)) {
            const identifiable = initialFields.find((object: Identifiable) => object.uuid === uuid);
            fieldUpdate = {field: identifiable, changeType: undefined};
          }
          fieldUpdates[uuid] = fieldUpdate;
        }
      }
      return fieldUpdates;
    }));
  }

  /**
   * Dispatches a MoveFieldUpdateAction
   * @param url       The page's URL for which the changes are saved
   * @param from      The index of the object to move
   * @param to        The index to move the object to
   * @param fromPage  The page to move the object from
   * @param toPage    The page to move the object to
   * @param field     Optional field to add to the fieldUpdates list (useful if we want to track updates across multiple pages)
   */
  saveMoveFieldUpdate(url: string, from: number, to: number, fromPage = 0, toPage = 0, field?: Identifiable) {
    this.store.dispatch(new AtmireMoveFieldUpdateAction(url, from, to, fromPage, toPage, field));
  }

  /**
   * Checks if the page currently has updates in the store or not
   * @param url The page's url to check for in the store
   */
  hasUpdates(url: string): Observable<boolean> {
    return this.getObjectEntry(url).pipe(map((objectEntry) => hasValue(objectEntry) && (isNotEmpty(objectEntry.fieldUpdates) || objectEntry.customOrder.changed)));
  }

  /**
   * Get move operations based on the custom order
   * @param url The page's url
   */
  getMoveOperations(url: string): Observable<MoveOperation[]> {
    return this.getObjectEntry(url).pipe(
      map((objectEntry) => objectEntry.customOrder),
      map((customOrder) => this.comparator.diff(
        flatten(customOrder.initialOrderPages.map((orderPage: OrderPage) => orderPage.order)),
        flatten(customOrder.newOrderPages.map((orderPage: OrderPage) => orderPage.order)))
      )
    );
  }
}
