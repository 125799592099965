<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="map-chart position-relative">
  <ng-container *ngIf="(data$ | async) && (labels$ | async)">
    <ds-atmire-cua-world-heat-map
      [id]="id" [minColour]="minColour" [maxColour]="maxColour"
      [labels]="labels$ | async"
      [data]="data$ | async">
    </ds-atmire-cua-world-heat-map>
    <div class="disclaimer position-absolute">
      <button *ngIf="!disclaimerShown" class="btn btn-dark m-2 float-right" (click)="disclaimerShown = true"><i class="fas fa-info"></i></button>
      <div class="content p-2" *ngIf="disclaimerShown">
        <span (click)="disclaimerShown = false" [innerHTML]="'statlets.map.disclaimer' | translate"></span>
      </div>
    </div>
  </ng-container>
</div>
