/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { DSpaceObject } from '../../../../../../../app/core/shared/dspace-object.model';
import { SiteDataService } from '../../../../../../../app/core/data/site-data.service';
import { hasValue } from '../../../../../../../app/shared/empty.util';
import { Site } from '../../../../../../../app/core/shared/site.model';
import { SelectorActionType } from '../../../../../../../app/shared/dso-selector/modal-wrappers/dso-selector-modal-wrapper.component';

/**
 * Component to wrap a button - to edit a top level static page -
 * and a list of DSO's - to edit a sub level static page
 * inside a modal
 */

@Component({
  selector: 'ds-edit-static-page-parent-selector',
  styleUrls: ['./edit-static-page-parent-selector.component.scss'],
  templateUrl: './edit-static-page-parent-selector.component.html',
})
export class EditStaticPageParentSelectorComponent implements OnInit {

  action = SelectorActionType.EDIT;
  currentDsoUuid: string;

  @Input() currentDSpaceObject: DSpaceObject;
  @Output() selectedDSpaceObject: EventEmitter<DSpaceObject> = new EventEmitter();

  constructor(private siteDataService: SiteDataService) {
  }

  /**
   * Select a DSO when provided, otherwise select the Site object
   * @param dso
   */
  selectObject(dso: DSpaceObject) {
    if (dso !== undefined) {
      this.selectedDSpaceObject.emit(dso);
    } else {
      this.siteDataService.find().pipe(take(1)).subscribe((site) => {
          this.selectedDSpaceObject.emit(site);
        }
      );
    }
  }

  ngOnInit(): void {
    if (hasValue(this.currentDSpaceObject) && !(this.currentDSpaceObject instanceof Site)) {
      this.currentDsoUuid = this.currentDSpaceObject.uuid;
    }
  }
}
