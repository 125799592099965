/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { StaticPageContentObject } from '../../static-page/static-page-content-object.model';
import { StaticPage } from '../../shared/static-page.model';

/**
 */
@Injectable({
  providedIn: 'root'
})export class StaticPageDataFormBuilder {

  /**
   *
   * @param page
   */
  buildForm(page: StaticPageContentObject): FormData {

    const form: FormData = new FormData();

    const properties = {
      id: page.id,
      name: page.name,
      title: page.title,
      sizeBytes: page.sizeBytes,
      language: page.language,
      type: StaticPage.type.value
    };

    form.set('properties', JSON.stringify(properties));

    if (page.hasContentUpdate) {
      form.set('file', new Blob([page.contentString], {type: 'text/html'}));
    }
    form.set('dspaceobject', page.dspaceobjectUuid);

    return form;
  }

}
