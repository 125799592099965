<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="container">
    <ng-container *ngVar="(parent$ | async) as parent">
    <ng-container *ngIf="parent?.payload as parentContext">
    <header class="comcol-header border-bottom mb-4 pb-4">
        <!-- Parent Name -->
        <ds-multilingual-comcol-page-header [dso]="parentContext">
        </ds-multilingual-comcol-page-header>
        <!-- Handle -->
        <ds-comcol-page-handle
            [content]="parentContext.handle"
            [title]="parentContext.type+'.page.handle'" >
        </ds-comcol-page-handle>
        <!-- Introductory text -->
        <ds-multilingual-comcol-page-content [dso]="parentContext" [field]="'dc.description'" [hasInnerHtml]="true">
        </ds-multilingual-comcol-page-content>
        <!-- News -->
        <ds-multilingual-comcol-page-content [dso]="parentContext" [field]="'dc.description.tableofcontents'" [hasInnerHtml]="true" [title]="'community.page.news'">
        </ds-multilingual-comcol-page-content>
      </header>
      <!-- Browse-By Links -->
      <ds-themed-comcol-page-browse-by [id]="parentContext.id" [contentType]="parentContext.type"></ds-themed-comcol-page-browse-by>
      </ng-container></ng-container>

    <section class="comcol-page-browse-section">
    <div class="browse-by-metadata w-100">
      <ds-client-browse-by *ngIf="startsWithOptions" class="col-xs-12 w-100"
      title="{{'browse.title' | translate:
      {
      collection: (parent$ | async)?.payload?.name || '',
      field: 'browse.metadata.' + browseId | translate,
      startsWith: (startsWith)? ('browse.startsWith' | translate: { startsWith: '&quot;' + startsWith + '&quot;' }) : '',
      value: (value)? '&quot;' + value + '&quot;': ''}
          }}"
      parentname="{{(parent$ | async)?.payload?.name || ''}}"
      [objects$]="(items$ !== undefined)? items$ : browseEntries$"
      [paginationConfig]="(currentPagination$ |async)"
      [sortConfig]="(currentSort$ |async)"
      [type]="startsWithType"
      [startsWithOptions]="startsWithOptions"
      [enableArrows]="false"
                    [showRss]="showRss"
      (prev)="goPrev()"
      (next)="goNext()">
      </ds-client-browse-by>
    <ds-loading *ngIf="!startsWithOptions" message="{{'loading.browse-by-page' | translate}}"></ds-loading>
  </div>
  </section>
  <ng-container *ngVar="(parent$ | async) as parent">
    <ng-container *ngIf="parent?.payload as parentContext">
  <footer *ngIf="parentContext.copyrightText" class="border-top my-5 pt-4">
    <div >

      <!-- Copyright -->
      <ds-multilingual-comcol-page-content [dso]="parentContext" [field]="['dc.rights', 'dc.rights.license']" [hasInnerHtml]="true">
      </ds-multilingual-comcol-page-content>
    </div>
  </footer>
</ng-container>
</ng-container>
</div>
