import { InjectionToken } from '@angular/core';
// import mockSubmissionResponse from './mock-submission-response.json';
// import mockUsageStatisticsDSOConfigResponse from './mock-usage-statistics-dso-config-response.json';
// import mockUsageStatisticsMetadataConfigResponse from './mock-usage-statistics-metadata-config-response.json';
// import mockUsageStatisticsVisitorsConfigResponse from './mock-usage-statistics-visitors-config-response.json';
// import mockUsageStatisticsRelatedItemsConfigResponse from './mock-usage-statistics-related-items-config-response.json';
// import mockUsageStatisticsItemByRelationConfigResponse from './mock-usage-statistics-item-by-relation-config-response.json';
// import mockPublicationResponse from './mock-publication-response.json';
// import mockUntypedItemResponse from './mock-untyped-item-response.json';
import mockFeatureQuerySuggestionAuthorResponse from './mock-feature-query-suggestions-author-response.json';
import mockStatisticallyRelatedUsageStatsItemList from './mock-statistically-related-usagestats-itemlist.json';
import mockItemWithStatisticallyRelatedItems from './mock-item-with-statistically-related-items.json';
import mockTrendingStatlet from './mock-trending-statlet.json';

export class ResponseMapMock extends Map<string, any> {}

export const MOCK_RESPONSE_MAP: InjectionToken<ResponseMapMock> = new InjectionToken<ResponseMapMock>('mockResponseMap');

/**
 * List of endpoints with their matching mock response
 * Note that this list is only used in development mode
 * In production the actual endpoints on the REST server will be called
 */
export const mockResponseMap: ResponseMapMock = new Map([
  // [ '/config/submissionforms/traditionalpageone', mockSubmissionResponse ]
  // [ '/statistics/usagestatisticstablesearchlists/config/dso', mockUsageStatisticsDSOConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/metadata', mockUsageStatisticsMetadataConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/visitors', mockUsageStatisticsVisitorsConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/itembyrelation', mockUsageStatisticsItemByRelationConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/relateditems', mockUsageStatisticsRelatedItemsConfigResponse ],
  // [ '/api/pid/find', mockPublicationResponse ],
  // [ '/api/pid/find', mockUntypedItemResponse ],
  // [ 'https://worldbank7.dev2.atmire.com/server/api/suggestion/querySuggestions/search/byQuery?page=0&size=3&query=Venables', mockFeatureQuerySuggestionAuthorResponse ],
  // [ '/api/pid/find', mockUntypedItemResponse ],
  // [ '/api/core/items/cb7a9d42-a4e9-483c-b6b8-6fa4218da922', mockItemWithStatisticallyRelatedItems ],
  // [ '/api/item/relateditemlistconfigs/cb7a9d42-a4e9-483c-b6b8-6fa4218da922_usagestats/itemlist', mockStatisticallyRelatedUsageStatsItemList ],
  [ '/api/statistics/statlets/f0e7f879-bde9-4e18-a0bc-c012dc1355b3_TopItemsOfThePast6MonthsBasedOnItemViews', mockTrendingStatlet ],
]);
