<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
<a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/' + dso.id]" class="lead" [innerHTML]="dsoTitle"></a>
<span *ngIf="linkType == linkTypes.None" class="lead" [innerHTML]="dsoTitle"></span>
<div *ngIf="dso.shortDescription" class="text-muted abstract-text" [innerHTML]="description"></div>
