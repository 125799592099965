<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-column h-100">
    <a *ngIf="dso" class="item-preview text-reset text-decoration-none row flex-grow-1 align-content-start"
   [routerLink]="getDSOPageRoute(dso)">
    <div class="mb-2 col-12 text-center">
        <h5 *ngIf="dso.firstMetadataValue('dc.title')">
            {{dso.firstMetadataValue('dc.title')}}</h5>
    </div>
    <ds-truncatable class="border-bottom py-4 border-top col-12">
        <ds-themed-thumbnail class="float-left mr-2"
                      [limitWidth]="false"
                      [thumbnail]="dso?.thumbnail | async"></ds-themed-thumbnail>
        <ds-truncatable-part [minLines]="11" [maxLines]="11">
            <span [innerHTML]="dso.firstMetadataValue('dc.description')"></span>
        </ds-truncatable-part>
    </ds-truncatable>
</a>

    <div class="row">
        <a class="col-12 pt-3 pb-2" routerLink="/search"
           [queryParams]="{query: 'relation.isJournalOfPublication:' + dso.uuid}">
            <span class="btn btn-lg btn-primary d-block mx-auto">{{'search.suggestions.preview.object.journal.searchLink' | translate}}</span>
        </a>
    </div>
</div>
