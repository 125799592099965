/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { SearchConfigurationService } from '../../../../app/core/shared/search/search-configuration.service';
import { Observable, Subscription, merge as observableMerge } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedSearchOptions } from '../../../../app/shared/search/models/paginated-search-options.model';
import { SearchOptions } from '../../../../app/shared/search/models/search-options.model';

@Injectable({
  providedIn: 'root'
  }
)
export class AtmireSearchConfigurationService extends SearchConfigurationService {

  /**
   * @returns {Observable<boolean>} Emits the current advancedConfiguration setting (return null, i.e. don't show as
   * query
   * param if it is not defined and true)
   */
  getCurrentAdvancedConfiguration(): Observable<boolean> {
    return this.routeService.getQueryParameterValue('configuration').pipe(map((configuration: string) => {
      return configuration === 'advancedConfiguration' ? true : null ;
    }));
  }

  /**
   * @returns {Observable<boolean>} Emits the current showStatistics setting (return null, i.e. don't show as query
   * param if it is not defined and true)
   */
  getCurrentShowStatistics(): Observable<boolean> {
    return this.routeService.getQueryParameterValue('showStatistics').pipe(map((showStatistics: string) => {
      return showStatistics === 'true' ? true : null ;
    }));
  }

  /**
   * @returns {Observable<string>} Emits the current advancedConfiguration setting as a partial SearchOptions object
   */
  protected getAdvancedConfigurationPart(): Observable<any> {
    return this.getCurrentAdvancedConfiguration().pipe(map((advancedConfiguration: boolean) => {
      return { advancedConfiguration };
    }));
  }

  /**
   * @returns {Observable<string>} Emits the current showStatistics setting as a partial SearchOptions object
   */
  private getShowStatisticsPart(): Observable<any> {
    return this.getCurrentShowStatistics().pipe(map((showStatistics: boolean) => {
      return { showStatistics };
    }));
  }

  /**
   * Whether or not to show statistics in the search results
   */
  public shouldShowStatistics(): Observable<boolean> {
    return this.getCurrentShowStatistics();
  }

  /**
   * Whether to use the advanced search configuration
   */
  public shouldUseAdvancedConfiguration(): Observable<boolean> {
    return this.getCurrentAdvancedConfiguration();
  }

  /**
   * Sets up a subscription to all necessary parameters to make sure the searchOptions emits a new value every time they update
   * @param {SearchOptions} defaults Default values for when no parameters are available
   * @returns {Subscription} The subscription to unsubscribe from
   */
  protected subscribeToSearchOptions(defaults: SearchOptions): Subscription {
    return observableMerge(
      this.getConfigurationPart(defaults.configuration),
      this.getScopePart(defaults.scope),
      this.getQueryPart(defaults.query),
      this.getDSOTypePart(),
      this.getFiltersPart(),
      this.getFixedFilterPart(),
      this.getViewModePart(defaults.view),
      this.getShowStatisticsPart(),
      this.getAdvancedConfigurationPart()
    ).subscribe((update) => {
      const currentValue: SearchOptions = this.searchOptions.getValue();
      const updatedValue: SearchOptions = Object.assign(new PaginatedSearchOptions({}), currentValue, update);
      this.searchOptions.next(updatedValue);
    });
  }

  /**
   * Sets up a subscription to all necessary parameters to make sure the paginatedSearchOptions emits a new value every time they update
   * @param {string} paginationId The pagination ID
   * @param {PaginatedSearchOptions} defaults Default values for when no parameters are available
   * @returns {Subscription} The subscription to unsubscribe from
   */
  protected subscribeToPaginatedSearchOptions(paginationId: string, defaults: PaginatedSearchOptions): Subscription {
    return observableMerge(
      this.getConfigurationPart(defaults.configuration),
      this.getPaginationPart(paginationId, defaults.pagination),
      this.getSortPart(paginationId, defaults.sort),
      this.getScopePart(defaults.scope),
      this.getQueryPart(defaults.query),
      this.getDSOTypePart(),
      this.getFiltersPart(),
      this.getFixedFilterPart(),
      this.getViewModePart(defaults.view),
      this.getShowStatisticsPart(),
      this.getAdvancedConfigurationPart()
    ).subscribe((update) => {
      const currentValue: PaginatedSearchOptions = this.paginatedSearchOptions.getValue();
      const updatedValue: PaginatedSearchOptions = Object.assign(new PaginatedSearchOptions({}), currentValue, update);
      this.paginatedSearchOptions.next(updatedValue);
    });
  }
}
