/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { LangSwitchComponent } from '../../../../../app/shared/lang-switch/lang-switch.component';
import { Component } from '@angular/core';

@Component({
  selector: 'ds-atmire-lang-switch',
  styleUrls: ['../../../../../app/shared/lang-switch/lang-switch.component.scss', './atmire-lang-switch-component.scss'],
  templateUrl: './atmire-lang-switch-component.html',
})
export class AtmireLangSwitchComponent extends LangSwitchComponent {

}
