import { deserialize, inheritSerialization } from 'cerialize';
import { typedObject } from '../cache/builders/build-decorators';
import { DSpaceObject } from './dspace-object.model';
import { SITE } from './site.resource-type';
import { HALLink } from './hal-link.model';
import { OrderType } from '../../../app-atmire/shared/pagination-drag-and-drop/order-switch/order-type.enum';

/**
 * Model class for the Site object
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class Site extends DSpaceObject {

  static type = SITE;

  @deserialize
  _links: {
    self: HALLink;
    communities: HALLink;
  };

  @deserialize
  order: OrderType;
}
