<button type="button" class="btn btn-info mt-1 mb-3"
  ngbTooltip="{{'submission.workflow.tasks.pool.claim_help' | translate}}" [disabled]="(processing$ | async)"
  (click)="claim()">
  <span *ngIf="(processing$ | async)"><i class='fas fa-circle-notch fa-spin'></i>
    {{'submission.workflow.tasks.generic.processing' | translate}}</span>
  <span *ngIf="!(processing$ | async)"><i class="fas fa-hand-paper"></i> {{'submission.workflow.tasks.pool.claim' |
    translate}}</span>
</button>
<ds-themed-my-dspace-view-button [viewLink]="getWorkflowItemViewRoute((workflowitem$ | async))" [marginClasses]="'ml-1 mt-1 mb-3'">
</ds-themed-my-dspace-view-button>
