/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of as observableOf, Subscription } from 'rxjs';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { debounceTime, filter, isEmpty, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { SuggestionCategory } from '../../../core/search/models/suggestion-category.model';
import { SuggestionResultWrapperComponent } from './suggestions/suggestion-result/suggestion-result-wrapper.component';
import { Suggestion } from '../../../core/search/models/suggestion.model';
import { from } from 'rxjs/internal/observable/from';
import { SearchOptions } from '../../../../app/shared/search/models/search-options.model';
import { SearchConfigurationService } from '../../../../app/core/shared/search/search-configuration.service';
import { getFirstSucceededRemoteData } from '../../../../app/core/shared/operators';
import { QuerySuggestionsObject } from '../../search/query-suggestion-object.model';
import { PaginatedSearchOptions } from '../../../../app/shared/search/models/paginated-search-options.model';
import { PaginationComponentOptions } from '../../../../app/shared/pagination/pagination-component-options.model';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AtmireSearchService } from '../../../core/shared/search/atmire-search.service';

const SUGGESTION_PAGE_SIZE = 3;

/**
 * This component renders an input field with dropdown for search suggestions
 */
@Component({
  selector: 'ds-search-input-field',
  styleUrls: ['./search-input-field.component.scss'],
  templateUrl: './search-input-field.component.html'
})

export class SearchInputFieldComponent implements OnInit, OnDestroy {
  /**
   * The query
   */
  @Input() query: string;
  @Input() scope: string;
  @Input() useSearchOptions: boolean;
  @Input() showButton = true;

  @Output() queryChange: EventEmitter<string> = new EventEmitter();
  searchControl = new FormControl();
  sub: Subscription;
  resultCategories: Observable<SuggestionCategory[]>;
  selectedCategory: SuggestionCategory;
  selected: Suggestion;
  show = new BehaviorSubject<boolean>(false);
  selectedIndex;
  suggestedQuery: string;
  searchOptions: SearchOptions;
  lastIgnoredSearch: string;
  @ViewChild('queryInput', { static: false }) queryInput: ElementRef;
  @ViewChildren(SuggestionResultWrapperComponent) resultViews: QueryList<SuggestionResultWrapperComponent>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private searchService: AtmireSearchService,
              private searchConfigService: SearchConfigurationService,
              private router: Router,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.lastIgnoredSearch = this.route.snapshot.queryParams.query;
    this.resultCategories = this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((value: string) => {
            // Comparing the route query with the given query allows us to avoid a suggestion search when the route was
            // externally updated by another feature or component
            // additionally, to avoid it being ignored more than once, the ignored query is stored in a variable
            const routeQuery = this.route.snapshot.queryParams.query;
            if (routeQuery === value && value !== this.lastIgnoredSearch) {
              this.lastIgnoredSearch = value;
            } else if (isNotEmpty(value)) {
              return this.search();
            }

            this.close();
            return [[]];
          }
        )
      );
    this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
          this.close();
        });
    this.searchControl.registerOnChange((value) => {
      this.emitQuery(value);
    });
  }

  /**
   * This method searches for suggestions and displays them in the dropdown
   */
  search(): Observable<SuggestionCategory[]> {
    this.loading$.next(true);
    this.show.next(true);

    const pagination = new PaginationComponentOptions();
    pagination.pageSize = SUGGESTION_PAGE_SIZE;

    let searchOptions$: Observable<PaginatedSearchOptions> = observableOf({} as PaginatedSearchOptions);
    if (this.useSearchOptions) {
      searchOptions$ = this.searchConfigService.searchOptions.pipe(take(1));
    }
    return searchOptions$.pipe(
      switchMap((searchOptions: PaginatedSearchOptions) => {
        this.searchOptions = Object.assign(new PaginatedSearchOptions({}), searchOptions, { query: this.searchControl.value, pagination, scope: this.scope });
        return this.searchService.suggest(this.searchOptions).pipe(
          getFirstSucceededRemoteData(),
          map((resultRD: RemoteData<QuerySuggestionsObject>) => resultRD.payload.results),
          tap((categories: SuggestionCategory[]) => {
            this.loading$.next(false);

            this.selected = undefined;
            // Check if there are any actual results in one of the categories
            const hasNoValues$: Observable<boolean> = from(categories).pipe(
              map((cat: SuggestionCategory) => cat.values),
              filter((suggestionsRD) => suggestionsRD.totalElements > 0),
              isEmpty()
            );
            hasNoValues$.subscribe((noValue: boolean) => {
              this.show.next(!noValue);
            });
          })
        );
      })
    );
  }

  emitQuery(data) {
    this.query = data;
    this.queryChange.emit(data);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  selectItem(result: Suggestion, index: number, category: SuggestionCategory) {
    this.selected = result;
    this.selectedIndex = index;
    this.suggestedQuery = this.selected.label;
    this.selectedCategory = category;
    return false;
  }

  shiftFocusUp(event: KeyboardEvent) {
    event.preventDefault();
    if (this.show.getValue()) {
      if (hasValue(this.selected)) {
        this.selectedIndex--;
        this.selectedIndex = (this.selectedIndex + this.resultViews.length) % this.resultViews.length; // Prevent negative modulo outcome
      } else {
        this.selectedIndex = this.resultViews.length - 1;
      }
      this.changeFocus();
    }
  }

  shiftFocusDown(event: KeyboardEvent) {
    event.preventDefault();
    if (this.show.getValue()) {
      if (hasValue(this.selected)) {
        this.selectedIndex++;
        this.selectedIndex %= this.resultViews.length;
      } else {
        this.selectedIndex = 0;
      }
      this.changeFocus();
    }
  }

  private changeFocus() {
    if (this.resultViews.length > 0) {
      const selectedResultView = this.resultViews.toArray()[this.selectedIndex];
      selectedResultView.focus();
      this.selectItem(selectedResultView.suggestion, this.selectedIndex, selectedResultView.category);
    }
  }

  focusInput(event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      this.queryInput.nativeElement.focus();
    } else {
      this.close();
    }
  }

  onFocus() {
    this.suggestedQuery = '';
  }

  isNotEmpty(data) {
    return isNotEmpty(data);
  }

  close() {
    this.show.next(false);
  }

  getSuggestionTitle() {
    return (this.query + ' -- ' + this.suggestedQuery);
  }

  submit() {
    if (isNotEmpty(this.suggestedQuery)) {
      this.query = this.suggestedQuery;
      this.suggestedQuery = '';
    }
    this.close();
  }

  blur() {
    this.queryInput.nativeElement.blur();
  }

  focus() {
    this.queryInput.nativeElement.focus();
  }

  get value() {
    return this.searchControl.value;
  }
}
