/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { ClientSharedModule } from './shared/client-shared.module';
import { ClientAppRoutingModule } from './client-app-routing.module';
import { AtmireAppModule } from '../app-atmire/atmire-app.module';
import { ClientBrowseByComponent } from './shared/client-browse-by/client-browse-by.component';
import { ClientRssComponent } from '../themes/client/app/shared/client-rss-feed/client-rss.component';
import { ClientFeaturedItemsComponent } from './client-featured-items/view/client-featured-items.component';
import { SwiperModule } from 'swiper/angular';
import { MenuResolver } from '../app/menu.resolver';
import { ClientMenuResolver } from './client-menu.resolver';
import { DSOEditMenuResolver } from '../app/shared/dso-page/dso-edit-menu.resolver';
import { ClientDsoEditMenuResolver } from './shared/dso-page/client-dso-edit-menu.resolver';
import {
  LanguageAvailabilityComponent
} from './item-page/simple/field-components/language-availability/language-availability.component';
import {
  LanguageLinkListElementComponentComponent
} from './item-page/simple/field-components/language-availability/language-link-list-element-component/language-link-list-element-component.component';
import { AtmireObjectCollectionModule } from '../app-atmire/atmire-object-collection/atmire-object-collection.module';
import { CcLicenceIconsComponent } from '../app/cc-licence-icons/cc-licence-icons.component';
import { SelectiveTruncatableComponent } from './shared/selective-truncatable/selective-truncatable.component';
import { FooterCmsDirective } from './shared/footer-cms.directive';

const DECLARATIONS = [
  ClientBrowseByComponent,
  ClientRssComponent,
  ClientFeaturedItemsComponent,
  CcLicenceIconsComponent,
  LanguageAvailabilityComponent,
  SelectiveTruncatableComponent,
  FooterCmsDirective
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  LanguageLinkListElementComponentComponent,
];

const PROVIDERS = [
  {
    provide: DSOEditMenuResolver,
    useClass: ClientDsoEditMenuResolver,
  },
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
    ...ENTRY_COMPONENTS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component })),
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClientSharedModule,
    ClientAppRoutingModule,
    AtmireAppModule,
    SwiperModule,
    AtmireObjectCollectionModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class ClientAppModule {
}
