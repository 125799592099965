/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import {
  FileSectionComponent
} from '../../../../../app/item-page/simple/field-components/file-section/file-section.component';
import { Bitstream } from '../../../../../app/core/shared/bitstream.model';
import { BehaviorSubject } from 'rxjs';
import { getFirstCompletedRemoteData } from '../../../../../app/core/shared/operators';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { PaginatedList } from '../../../../../app/core/data/paginated-list.model';
import { hasValue } from '../../../../../app/shared/empty.util';

/**
 * This component renders the file section of the item
 */
@Component({
  selector: 'ds-client-item-page-file-section',
  templateUrl: './client-file-section.component.html'
  // templateUrl: '../../../../../app/item-page/simple/field-components/file-section/file-section.component.html'
})
export class ClientFileSectionComponent extends FileSectionComponent {
  @Input() icon: string;
  @Input() text: string;

  extensionsToPlaceFirst: string[] = ['html', 'htm'];
  extensionBitstreamPairs$: BehaviorSubject<[string, Bitstream][]> = new BehaviorSubject([]);

  getNextPage(): void {
    this.isLoading = true;
    if (this.currentPage === undefined) {
      this.currentPage = 1;
      this.bitstreams$ = new BehaviorSubject([]);
    } else {
      this.currentPage++;
    }
    this.bitstreamDataService.findAllByItemAndBundleName(this.item, 'ORIGINAL', {
      currentPage: this.currentPage,
      elementsPerPage: this.pageSize
    }).pipe(
      getFirstCompletedRemoteData(),
    ).subscribe((bitstreamsRD: RemoteData<PaginatedList<Bitstream>>) => {
      if (bitstreamsRD.errorMessage) {
        this.notificationsService.error(this.translateService.get('file-section.error.header'), `${bitstreamsRD.statusCode} ${bitstreamsRD.errorMessage}`);
      } else if (hasValue(bitstreamsRD.payload)) {
        const current: Bitstream[] = this.bitstreams$.getValue();
        this.bitstreams$.next([...current, ...bitstreamsRD.payload.page]);
        // Atmire modifications START
        this.extensionBitstreamPairs$.next(this.sortHtmlFirst([...current, ...bitstreamsRD.payload.page].map((bit: Bitstream) => [this.getFileExtension(bit), bit])));
        // Atmire modifications END
        this.isLoading = false;
        this.isLastPage = this.currentPage === bitstreamsRD.payload.totalPages;
      }
    });
  }

  getTranslation(prefix: string, extension: string): string {
    const key = prefix + '.' + extension;
    const translation = this.translateService.instant(key);
    if (translation !== key) {
      return translation;
    } else {
      return this.translateService.instant(prefix,{ 'file-type': extension.toUpperCase() });
    }
  }

  getFileExtension(file: Bitstream): string {
    return file?.name.substring(file?.name.lastIndexOf('.') + 1);
  }

  private sortHtmlFirst(map: [string, Bitstream][]): [string, Bitstream][] {
    map.sort(([stringA, _], [stringB, __]) => {
      if (this.extensionsToPlaceFirst.includes(stringA) && !this.extensionsToPlaceFirst.includes(stringB)) {
        return -1; // "html"/"htm" comes before other strings
      } else if (!this.extensionsToPlaceFirst.includes(stringA) && this.extensionsToPlaceFirst.includes(stringB)) {
        return 1; // "html"/"htm" comes after other strings
      } else {
        return 0; // Maintain the relative order of other elements
      }
    });
    return map;
  }
}
