<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="home-page">
  <div>
    <ds-themed-home-news></ds-themed-home-news>

    <div class="container pb-4">
      <ng-container *ngIf="(site$ | async) as site">
        <ds-view-tracker [object]="site"></ds-view-tracker>
      </ng-container>

      <hr class="mt-4">
      <h4>{{ 'home.search-form.placeholder' | translate }}</h4>
      <ds-themed-search-form [inPlaceSearch]="false" [searchPlaceholder]="''"></ds-themed-search-form>
      <div class="search-hint text-secondary">{{ 'home.search-form.hint' | translate }}</div>
    </div>
  </div>

  <ng-container *ngVar="(site$ | async).metadata['atmire.home.publications'] as publications">
    <div *ngIf="publications && publications.length !== 0" class="pt-3 pb-5">
      <div class="container">
        <hr>
        <h2>{{ 'home-page.featured-publications' | translate }}</h2>
          <ds-client-featured-items [titleLines]="5"></ds-client-featured-items>
      </div>
    </div>
  </ng-container>

  <ng-container *ngVar="(trendingStatletRD$ | async) as trendingStatletRD">
    <div class="pt-4 pb-5" *ngIf="trendingStatletRD?.hasSucceeded">
      <div class="container">
        <hr>
        <h2>{{ 'home-page.trending' | translate }}</h2>
        <ds-atmire-cua-single-statlet-search-results [statlet]="trendingStatletRD?.payload">
        </ds-atmire-cua-single-statlet-search-results>
      </div>
    </div>
    <div class="py-5" *ngIf="trendingStatletRD?.isLoading">
      <ds-loading></ds-loading>
    </div>
  </ng-container>

  <div class="recently-added py-5">
    <div class="container">
      <hr>
      <h2 class="pb-2">{{ 'home-page.recently-added' | translate }} <ds-rss></ds-rss> </h2>
      <ds-atmire-show-more [sourceModel]="publicationsSourceModel"
                           [paginationOptions]="publicationsPagination"
                           [sortOptions]="sortOptions"
                           [viewMode]="ViewMode.ListElement">
      </ds-atmire-show-more>

      <div class="mt-4" *ngIf="(site$ | async) as site">
        <ds-atmire-cua-statlets-section [dso]="site"></ds-atmire-cua-statlets-section>
      </div>
    </div>
  </div>
</div>
