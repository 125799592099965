/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteDataService } from '../../../../app/core/data/site-data.service';
import { Observable } from 'rxjs/internal/Observable';
import { Site } from '../../../../app/core/shared/site.model';
import { Context } from '../../../../app/core/shared/context.model';
import {
  PaginatedDragAndDropItemParentMetadataListComponent
} from '../../../shared/pagination-drag-and-drop/paginated-drag-and-drop-item-parent-metadata-list/paginated-drag-and-drop-item-parent-metadata-list.component';

@Component({
  selector: 'ds-manage-featured-publications-page',
  templateUrl: './manage-featured-publications-page.component.html',
})
/**
 * Page displaying a list of drag-and-droppable featured publications and a save button to submit the changes
 */
export class ManageFeaturedPublicationsPageComponent implements OnInit {
  /**
   * The current Site
   */
  site$: Observable<Site>;

  /**
   * The metadata field which stores featured publication UUIDs within the Site object
   */
  featuredPublicationsMetadataField = 'atmire.home.publications';

  /**
   * The Context we're currently in
   * Determines how the featured publications are displayed within the list
   */
  context = Context.FeaturedPublications;

  /**
   * A unique URL for our Store to save the drag-and-drop changes
   */
  listUrl = 'featured-publications-list';

  @ViewChild(PaginatedDragAndDropItemParentMetadataListComponent, { static: false }) featuredPublicationsDragAndDrop;

  constructor(public siteService: SiteDataService) {
  }

  ngOnInit(): void {
    this.site$ = this.siteService.find();
  }

  /**
   * Submit the changes to the featured publications
   */
  submit() {
    this.featuredPublicationsDragAndDrop.submit();
  }
}
