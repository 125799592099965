/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  listableObjectComponent
} from '../../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../../app/core/shared/view-mode.model';
import {
  ItemSearchResult
} from '../../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import {
  ItemSearchResultListElementComponent as BaseComponent
} from '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { Item } from '../../../../../../../../../app/core/shared/item.model';
import { Context } from '../../../../../../../../../app/core/shared/context.model';
import { TruncatableService } from '../../../../../../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { TranslateService } from '@ngx-translate/core';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, Context.Any, 'client')
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, Context.Any, 'client')
@listableObjectComponent('Publication', ViewMode.ListElement, Context.Any, 'client')
@listableObjectComponent(Item, ViewMode.ListElement, Context.Any, 'client')
@Component({
  selector: 'ds-item-search-result-list-element',
  styleUrls: ['./item-search-result-list-element.component.scss'],
  // styleUrls: ['../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html'
  // templateUrl: '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item//item-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class ItemSearchResultListElementComponent extends BaseComponent {

  public constructor(
    protected truncatableService: TruncatableService,
    protected dsoNameService: DSONameService,
    protected translateService: TranslateService,
  ) {
    super(truncatableService, dsoNameService);
  }

  getLang(langCode: string): string {
    const langTranslation: string = this.translateService.instant(`language.${langCode}.label`);
    return langTranslation === `language.${langCode}.label` ? langCode : langTranslation;
  }

}
