<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="show-more" [class.d-flex]="renderAsBadge && currentPage === 1">
  <ds-atmire-object-collection [objects$]="results$"
                               [error$]="error$"
                               [isLoading$]="isLoading$"
                               [viewMode]="viewMode"
                               [context]="context"
                               [linkType]="linkType"
                               [noResultsMsg]="noResultsMsg"
                               (onObjectClick)="onObjectClick.emit($event)"
  ></ds-atmire-object-collection>
  <ng-container *ngIf="!renderAsBadge">
    <div *ngIf="!(isLoading$ | async) && !(error$ | async) && (results$ | async).length"
         class="row show-more-buttons ml-0"
    >
      <div *ngIf="!isLastPage"
           class="mt-1 mr-1">
        <a class="btn btn-outline-secondary btn-sm aoc-show-more"
           href="javascript:void(0);"
           (click)="showMore()">{{'atmire.object-collection.button.show-more' | translate}}</a>
      </div>
      <div *ngIf="currentPage != 1"
           class="mt-1 mr-1">
        <a class="btn btn-outline-secondary btn-sm aoc-collapse"
           href="javascript:void(0);"
           (click)="collapse()">{{'atmire.object-collection.button.collapse' | translate}}</a>
      </div>
    </div>
  </ng-container>
  <span *ngIf="renderAsBadge">
    <ng-container *ngIf="!(isLoading$ | async) && !(error$ | async) && (results$ | async).length"
                  [class.row]="!renderAsBadge"
    >
      <ng-container *ngIf="!isLastPage">
        <a class="badge badge-button bg-white text-primary aoc-show-more"
           [class.ml-2]="currentPage === 1"
           href="javascript:void(0);"
           (click)="showMore()">{{'atmire.object-collection.button.show-more' | translate}}</a><!-- Remove spacing
      --></ng-container><!-- Remove spacing
      --><ng-container *ngIf="currentPage != 1"><!-- Remove spacing
      --><a class="badge badge-button bg-white text-primary aoc-collapse"
           [class.ml-2]="!isLastPage"
           href="javascript:void(0);"
           (click)="collapse()">{{'atmire.object-collection.button.collapse' | translate}}</a>
      </ng-container>
    </ng-container>
  </span>
</div>
