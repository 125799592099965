<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="home-news">
  <div class="rounded">
    <img *ngIf="homePageBgImage$ | async" class="absolute-background-image" [src]="homePageBgImage$ | async"/>
    <div class="container position-relative" *ngVar="(homepageNewsRD$ |async)?.payload?.contentString as contentString">
      <div class="d-flex flex-wrap">
        <div class="px-3 py-2.5 m-4 mt-auto news-banner rounded" *ngIf="contentString">
          <div class="d-flex ck-content">
            <div [innerHTML]="contentString"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
