/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { StatisticallyRelatedItemsComponent } from './statistically-related-items/statistically-related-items.component';
import { RelatedItemsDataService } from './related-items-data.service';
import { AtmireObjectCollectionModule } from '../atmire-object-collection/atmire-object-collection.module';

const DECLARATIONS = [
  StatisticallyRelatedItemsComponent,
];

const PROVIDERS = [
  RelatedItemsDataService,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AtmireObjectCollectionModule,
  ],
  declarations: [
    ...DECLARATIONS,
  ],
  exports: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
  ]
})
export class AtmireRelatedItemsModule {
}
