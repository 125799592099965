<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div>
    <div class="modal-header">
        <button *ngIf="!scopeSelect" type="button" class="back" (click)="back()" aria-label="Back">
            <i class="fas fa-arrow-left"></i>
        </button>
        {{'static-pages.selector.' + action + '.head' | translate}}
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="scopeSelect">
            <ds-edit-static-page-parent-selector [currentDSpaceObject]="currentDSpaceObject"
                                                 (selectedDSpaceObject)="selectScope($event)">
            </ds-edit-static-page-parent-selector>
        </div>

        <div *ngIf="!scopeSelect">
            <ds-edit-static-page-selector [currentStaticPage]="currentStaticPage"
                                          [scopeDso]="currentDSpaceObject"></ds-edit-static-page-selector>
        </div>
    </div>

</div>

