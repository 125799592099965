<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-truncatable-part [maxLines]="1" [background]="isCurrent() ? 'primary' : 'default'">
  <div class="font-weight-bold"
       [ngClass]="isCurrent() ? 'text-light' : 'text-primary'"
       [innerHTML]="dsoTitle"></div>
</ds-truncatable-part>
<ds-truncatable-part *ngIf="description" [maxLines]="1" [background]="isCurrent() ? 'primary' : 'default'">
  <div class="text-secondary"
       [ngClass]="isCurrent() ? 'text-light' : 'text-secondary'"
       [innerHTML]="description"></div>
</ds-truncatable-part>
