/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Item } from '../../../app/core/shared/item.model';
import { ViewMode } from '../../../app/core/shared/view-mode.model';
import {
  getFirstSucceededRemoteDataPayload,
  getPaginatedListPayload
} from '../../../app/core/shared/operators';
import { RelatedItemListConfig } from '../related-item-list-config.model';
import { isNotEmpty } from 'src/app/shared/empty.util';
import {
  AtmireHrefListableObjectSourceModel,
  ListableObjectSourceModel
} from '../../atmire-object-collection/listable-object-sources';
import { PaginationComponentOptions } from '../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions, SortDirection } from '../../../app/core/cache/models/sort-options.model';
import { tap } from 'rxjs/operators';
import { followLink } from '../../../app/shared/utils/follow-link-config.model';
import { AbstractSourceKwargs } from '../../atmire-object-collection/sources/abstract-listable-object-source';

@Component({
  selector: 'ds-statistically-related-items',
  styleUrls: ['./statistically-related-items.component.scss'],
  templateUrl: './statistically-related-items.component.html'
})
/**
 * This component is used for displaying relations between items
 * It expects a parent item and relationship type, as well as a label to display on top
 */
export class StatisticallyRelatedItemsComponent {
  /**
   * The parent of the list of related items to display
   */
  @Input() item: Item;

  /**
   * The amount to increment the list by when clicking "view more"
   * Defaults to 5
   * The default can optionally be overridden by providing the limit as input to the component
   */
  @Input() incrementBy = 3;

  /**
   * The default pagination for the show-more components
   */
  defaultPagination =  Object.assign(new PaginationComponentOptions(), {
    pageSize: this.incrementBy,
  });

  /**
   * Map containing a list of configuration names linking to their object source model used by a show-more component
   */
  configSourceModelMap: Map<string, ListableObjectSourceModel> = new Map<string, ListableObjectSourceModel>();

  /**
   * The view-mode we're currently on
   * @type {ViewMode}
   */
  viewMode = ViewMode.ListElement;

  /**
   * Defaylt sort options for show-more component
   */
  sortOptions = new SortOptions('dc.date.accessioned', SortDirection.DESC);

  /**
   * Emits the relatedItemListConfigs of this item so they can be used to rendered on the item page
   */
  relatedItemListConfigs$: Observable<RelatedItemListConfig[]>;

  /**
   * Set this to true when the statistically related items include a thumbnail image in their list view
   */
  embedThumbnails = true;

  /**
   * Arguments for the itemlist request that adds a followLink for thumbnails if thumbnails are included
   */
  kwargs: AbstractSourceKwargs<any> = { linksToFollow: [followLink('thumbnail')] };

  /**
   * Populates the relatedItemListConfigs$ and the configSourceModelMap so they can be rendered as show-more components
   */
  ngOnInit() {
    this.relatedItemListConfigs$ = this.item.relatedItemListConfigs.pipe(
      getFirstSucceededRemoteDataPayload(),
      getPaginatedListPayload(),
      tap((configs: RelatedItemListConfig[]) =>
        configs.forEach((config: RelatedItemListConfig) => {
          this.configSourceModelMap.set(config.name, new AtmireHrefListableObjectSourceModel<Item>(config._links.itemlist.href, this.embedThumbnails ? this.kwargs : undefined));
        })));

  }

  isNotEmpty(list: any): boolean {
    return isNotEmpty(list);
  }
}
