/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { RestRequestMethod } from '../../../app/core/data/rest-request-method';
import { HttpOptions } from '../../../app/core/dspace-rest/dspace-rest.service';
import { GenericConstructor } from '../../../app/core/shared/generic-constructor';
import { ResponseParsingService } from '../../../app/core/data/parsing.service';
import { DspaceRestResponseParsingService } from '../../../app/core/data/dspace-rest-response-parsing.service';
import { RestRequest } from '../../../app/core/data/request.models';

/* tslint:disable:max-classes-per-file */

export abstract class AtmireRestRequest extends RestRequest {
  public responseMsToLive;
  public isMultipart = false;
  public shouldSerialize = true;

  constructor(
    public uuid: string,
    public href: string,
    public method: RestRequestMethod = RestRequestMethod.GET,
    public body?: any,
    public options?: HttpOptions,
  ) {
    super(uuid, href, method, body, options);
  }

  getResponseParser(): GenericConstructor<ResponseParsingService> {
    return DspaceRestResponseParsingService;
  }
}

/**
 * Request representing a multipart post request
 */
export class AtmireMultipartPostRequest extends RestRequest {
  public shouldSerialize = false;
  public isMultipart = true;

  constructor(
    public uuid: string,
    public href: string,
    public body?: any,
    public options?: HttpOptions
  ) {
    super(uuid, href, RestRequestMethod.POST, body);
  }
}

/**
 * Multipart request
 * The body of this request should not be serialized as it is a FormData object
 */
export class AtmireMultiPartRequest extends RestRequest {
  public shouldSerialize = false;
  public isMultipart = true;

  constructor(
    public uuid: string,
    public href: string,
    public method: RestRequestMethod,
    public body?: any,
    public options?: HttpOptions
  ) {
    super(uuid, href, method, body, options);
  }
}
