/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteDataService } from '../../../app/core/data/site-data.service';
import { map, switchMap } from 'rxjs/operators';
import { RemoteData } from '../../../app/core/data/remote-data';
import { hasValue, hasValueOperator } from '../../../app/shared/empty.util';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteDataPayload } from '../../../app/core/shared/operators';
import { StaticPageDataService } from '../../core/data/static-page-data.service';
import { DSpaceObjectDataService } from '../../../app/core/data/dspace-object-data.service';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { StaticPageContentObject } from 'src/app-atmire/static-page/static-page-content-object.model';
import { StaticPageSearchOptions } from '../../static-page/static-page-search-options.model';

@Injectable({
  providedIn: 'root'
})
export class HomePageNewsResolver implements Resolve<RemoteData<StaticPageContentObject>> {
  constructor(
    protected siteService: SiteDataService,
    protected dSpaceObjectDataService: DSpaceObjectDataService,
    protected staticPageDataService: StaticPageDataService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RemoteData<StaticPageContentObject>> {
    let dataObject$: Observable<DSpaceObject>;
    if (hasValue(route.params) && hasValue(route.params.id)) {
      dataObject$ = this.dSpaceObjectDataService.findById(route.params.id).pipe(
        getFirstSucceededRemoteDataPayload()
      );
    } else {
      dataObject$ = this.siteService.find();
    }
    const staticPage$ = dataObject$.pipe(
      hasValueOperator(),
      map((dso) => new StaticPageSearchOptions({
        uuid: dso.uuid,
        name: 'news'
      })),
      switchMap((staticPageSearchOptions: StaticPageSearchOptions) => this.staticPageDataService.searchStaticPages(staticPageSearchOptions)),
    );
    return this.staticPageDataService.retrieveContentFromPageListObs(staticPage$).pipe(getFirstCompletedRemoteData());
  }
}
