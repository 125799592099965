/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { typedObject, link } from '../../app/core/cache/builders/build-decorators';
import { excludeFromEquals } from '../../app/core/utilities/equals.decorators';
import { autoserialize, deserialize } from 'cerialize';
import { ResourceType } from '../../app/core/shared/resource-type';
import { HALLink } from '../../app/core/shared/hal-link.model';
import { RELATED_ITEM_LIST_CONFIG } from './related-item-list-config.resource-type';
import { CacheableObject } from '../../app/core/cache/object-cache.reducer';
import { Observable } from 'rxjs';
import { RemoteData } from '../../app/core/data/remote-data';
import { ITEM } from '../../app/core/shared/item.resource-type';
import { PaginatedList } from '../../app/core/data/paginated-list.model';
import { Item } from '../../app/core/shared/item.model';

@typedObject
export class RelatedItemListConfig extends CacheableObject {
  static type = RELATED_ITEM_LIST_CONFIG;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  @autoserialize
  id: string;

  @autoserialize
  name: string;

  get self(): string {
    return this._links.self.href;
  }
  /**
   * The itemList for this RelatedItemListConfig
   * Will be undefined unless the itemlist {@link HALLink} has been resolved.
   */
  @link(ITEM, true, 'itemlist')
  itemList?: Observable<RemoteData<PaginatedList<Item>>>;

  @deserialize
  _links: {
    self: HALLink;
    itemlist: HALLink;
  };
}
